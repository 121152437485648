import axios from 'axios';

const BASE_URL = 'https://apitest.inframantra.com';

const TestimonialsServices = {
    getTestimonials: async () => {
        try {
          const response = await axios.get(`${BASE_URL}/api/v1/testimonials/get`);
          // console.log('response',response.data);
          return response.data;
        } catch (error) {
          console.error('Error fetching developers:', error);
          throw error;
        }
      },
}

export default TestimonialsServices;