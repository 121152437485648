import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import PropertyWrapper from './propertyWrapper';
import useMediaQuery from '../../../utils/useMediaQuery';
import './propertySectionStyles.css';

import CircularIndeterminate from '../../../components/UI/loader';

function PropertyBrochure({ brochure }) {
  const pdfUrl = brochure && brochure[0];
  const isDesktop = useMediaQuery(768);

  const [pdfDoc, setPdfDoc] = useState(null);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;

        setPdfDoc(pdf);

        const loadedPages = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };

          await page.render(renderContext).promise;

          loadedPages.push(canvas.toDataURL());
          setPages([...loadedPages]);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error loading PDF', err);
        setError(err.message);
      }
    };

    if (pdfUrl) {
      loadPdf();
    }
  }, [pdfUrl]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 2, pages.length - 2));
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 2, 0));
  };

  return (
    <PropertyWrapper id="brochure">
      <div className="aboutProjectWrapper">
        <h4 className="aboutProjectHeader">Brochure</h4>
        <div className="pdf-book-viewer">
          {isDesktop && (
            <>
              {error ? (
                <p>Error loading PDF: {error}</p>
              ) : (
                <div className="pdf-container">
                  <button className="nav-button" onClick={handlePreviousPage}>
                    &lt;
                  </button>
                  <div className="pdf-pages">
                    {loading ? (
                      <CircularIndeterminate/>
                    ) : (
                      <>
                        <img
                          src={pages[currentPage]}
                          alt={`Page ${currentPage + 1}`}
                          className="pdf-page"
                        />
                        {currentPage + 1 < pages.length && (
                          <img
                            src={pages[currentPage + 1]}
                            alt={`Page ${currentPage + 2}`}
                            className="pdf-page"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <button className="nav-button" onClick={handleNextPage}>
                    &gt;
                  </button>
                </div>
              )}
            </>
          )}
          {!isDesktop && (
            <>
              {error ? (
                <p>Error loading PDF: {error}</p>
              ) : (
                <div className="pdf-container">
                  <div className="pdf-pages">
                    {loading ? (
                      <p><CircularIndeterminate/></p>
                    ) : (
                      <>
                        <img
                          src={pages[currentPage]}
                          alt={`Page ${currentPage + 1}`}
                          className="pdf-page"
                        />
                        {currentPage + 1 < pages.length && (
                          <img
                            src={pages[currentPage + 1]}
                            alt={`Page ${currentPage + 2}`}
                            className="pdf-page"
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="brochureNavBtnWrapper">
                    <button className="nav-button" onClick={handlePreviousPage}>
                      &lt;
                    </button>
                    <button className="nav-button" onClick={handleNextPage}>
                      &gt;
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </PropertyWrapper>
  );
}

export default React.memo(PropertyBrochure);
