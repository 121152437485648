import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postProjectEnquiry } from '../services/enquiry.service'; // Adjust the import path accordingly
import { postContactEnquiry } from '../services/enquiry.service';
import Alert from '@mui/material/Alert';


// Create an async thunk for posting project enquiry
export const sendProjectEnquiry = createAsyncThunk(
  'enquiries/sendProjectEnquiry',
  async (enquiryData, { rejectWithValue }) => {
    try {
      const response = await postProjectEnquiry(enquiryData);
      <Alert severity="success">This is a success Alert.</Alert>
      return response;
    } catch (error) {
      <Alert severity="error">This is an error Alert.</Alert>
      return rejectWithValue(error.message);
    }
  }
);

export const sendContactEnquiry = createAsyncThunk(
  'enquiries/sendProjectEnquiry',
  async (enquiryData, { rejectWithValue }) => {
    try {
      const response = await postContactEnquiry(enquiryData);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const enquirySlice = createSlice({
  name: 'enquiries',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendProjectEnquiry.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendProjectEnquiry.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(sendProjectEnquiry.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default enquirySlice.reducer;
