import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    borderStyle: 'none',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#E7B554',
      border: 'none',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#E7B554',
      border: 'none',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    borderRadius: 1,
    backgroundImage:
      'linear-gradient(to right, #E7B554 20%, rgba(0, 0, 0, 0) 20%)',
    backgroundSize: '7px 3px',
    transition:
      'background-size 0.3s ease-in-out, background-image 0.3s ease-in-out',
  },
}));

const QontoStepIconRoot = styled('div')(({ ownerState }) => ({
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#E7B554',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#E7B554',
    zIndex: 1,
    fontSize: 25,
  },
  '& .QontoStepIcon-circle': {
    width: 15,
    height: 15,
    borderRadius: '50%',
    backgroundColor: '#fff',
  },
}));

const stepLabelClasses = {
  '& .Mui-active': {
    color: '#DCAA4C !important',
    fontSize: '18px',
  },
  '& .Mui-completed': {
    color: '#DCAA4C !important',
    fontSize: '18px',
  },
  '& .Mui-disabled	': {
    color: '#fff !important',
    fontSize: '18px',
  },
};

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        <div className="QontoStepIcon-circle" />
      </QontoStepIconRoot>
    </>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const steps = ['2017', '2019', '2022', '2023', '2024'];

export default function CustomizedSteppers({ currentStep }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  React.useEffect(() => {
    currentStep(activeStep);
  }, [activeStep, currentStep]);

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel sx={stepLabelClasses} StepIconComponent={QontoStepIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <Stack direction="row" spacing={2} justifyContent="center">
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ color: '#ffff' }}
        >
          Previous
        </Button>
        <Button
          disabled={activeStep === steps.length - 1}
          onClick={handleNext}
          sx={{ color: '#ffff' }}
        >
          Next
        </Button>
      </Stack>
    </Stack>
  );
}
