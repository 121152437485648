import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { projectData } from '../services/searchData.service';

export const fetchprojectData = createAsyncThunk('/project/searchProjects',async()=>{
    try {
        const response = await projectData();
        return response.data;
    } catch (error) {
        throw error;
    }
})

const projectSearchSlice = createSlice({
    name: 'projectSearch',
    initialState: {
        results: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
          .addCase(fetchprojectData.pending, (state) => {
            state.status = 'loading';
          })
          .addCase(fetchprojectData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.results = action.payload;
          })
          .addCase(fetchprojectData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
          });
      },
})

export default projectSearchSlice.reducer;