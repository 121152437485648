import React, { useState, useEffect, useRef } from 'react';
import Paper from '@mui/material/Paper';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CloseIcon from '@mui/icons-material/Close';
import ReactFlipCard from 'reactjs-flip-card';
import { useNavigate } from 'react-router-dom';
import './featuredPropertiesMobile.css';

const featuredPropertiesMobileStyles = {
  paper: {
    height: '90%',
    width: '100%',
    borderRadius: '10px',
    background: '#FFF',
    position: 'relative',
  },
  icon: {
    color: '#DCAA4C',
    fontSize: '25px',
    marginRight: '12px',
    '@media (max-width:550px)': {
      fontSize: '1rem',
      marginRight: '2px',
    },
  },
  arrowIcon: {
    color: '#E7B554',
    position: 'absolute',
    left: '88%',
    bottom: '25%',
    transform: 'translateY(-50%)',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    cursor: 'pointer',
    '@media (max-width:550px)': {
      width: '25px',
      height: '25px',
      left: '90%',
    },
  },
  crossIcon: {
    color: '#fff',
    position: 'absolute',
    left: '77%',
    bottom: '77%',
    width: '50px',
    height: '50px',
    cursor: 'pointer',
    '@media (max-width:550px)': {
      width: '25px',
      height: '25px',
      left: '90%',
      top: '2%',
    },
  },
};

function FeaturedPropertiesMobile({ property }) {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = (flip) => {
    setIsFlipped(flip);
  };

  return (
    <>
      <ReactFlipCard
        frontComponent={
          <CardFrontComponent
            property={property}
            handleFlip={() => handleFlip(true)}
          />
        }
        backComponent={
          <CardBackComponent
            property={property}
            handleFlip={() => handleFlip(false)}
          />
        }
        direction="horizontal"
        flipTrigger="manual"
        flipByProp={isFlipped}
      />
    </>
  );
}

export default FeaturedPropertiesMobile;

const CardFrontComponent = ({ property, handleFlip }) => {
  const navigate = useNavigate();
  const handleArrowClick = (event) => {
    event.stopPropagation();
    handleFlip();
  };
  const handlePaperClick = (slug) => {
    navigate(`/property/${slug}`);
  };

  return (
    <Paper
      elevation={12}
      sx={featuredPropertiesMobileStyles.paper}
      onClick={() => handlePaperClick(property.slug)}
    >
      <div className="featuredPropertiesMobileImageContainer">
        <img
          className="featuredPropertiesMobileImage"
          src={property.images[0].url}
          alt="featured"
        />
        <KeyboardArrowRightIcon
          sx={featuredPropertiesMobileStyles.arrowIcon}
          onClick={handleArrowClick}
        />
      </div>
      {/* {property.exclusive && (
        <img
          src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/exclusivePropertyHome.png"
          className="exclusiveIconMobile"
          alt="infra-exclusive"
        />
      )} */}
      <div className="featuredPropertiesMobileContent">
        <div className="featuredPropertiesMobileContentFirstSection">
          <div className="featuredPropertiesMobileContentFirstSectionContent">
            <CurrencyRupeeIcon sx={featuredPropertiesMobileStyles.icon} />
            <p>
              {
                property.numericInsights.find(
                  (insight) => insight.title === 'Starting Price'
                ).value
              }
            </p>
          </div>
          <div className="featuredPropertiesMobileContentFirstSectionContent">
            <LocationOnIcon sx={featuredPropertiesMobileStyles.icon} />
            <p>{property.location}</p>
          </div>
        </div>
        <div className="featuredPropertiesMobileContentSecondSection">
          <p>{property.title}</p>
        </div>
        <div className="featuredPropertiesMobileContentThirdSection">
          <hr />
          <div className="featuredPropertiesMobileContentThirdSectionContentFlex">
            <div className="featuredPropertiesMobileContentThirdSectionContent">
              <SquareFootIcon sx={featuredPropertiesMobileStyles.icon} />
              <p>
                {
                  property.numericInsights.find(
                    (insight) => insight.title === 'Sq feet'
                  ).value
                }
              </p>
            </div>
            <div className="vl"></div>
            <div className="featuredPropertiesMobileContentThirdSectionContent">
              <HomeIcon sx={featuredPropertiesMobileStyles.icon} />
              <p>
                {
                  property.numericInsights.find(
                    (insight) => insight.title === 'Configurations'
                  ).value
                }
              </p>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </Paper>
  );
};

const CardBackComponent = ({ property, handleFlip }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselTrackRef = useRef(null);
  const autoplayIntervalRef = useRef(null);

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const track = carouselTrackRef.current;
    if (track) {
      track.style.transform = `translateX(-${
        currentImageIndex * track.offsetWidth
      }px)`;
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const autoplay = () => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % property.images.slice(0, 3).length
      );
    };

    autoplayIntervalRef.current = setInterval(autoplay, 2000);

    return () => clearInterval(autoplayIntervalRef.current);
  }, [property.images]);

  return (
    <Paper elevation={12} sx={featuredPropertiesMobileStyles.paper}>
      <div className="featuredPropertiesMobileImageContainerBack">
        <div className="carouselFeatured">
          <div className="carousel-track" ref={carouselTrackRef}>
            {property.images.slice(0, 3).map((img, index) => (
              <img
                key={index}
                className="carousel-slide"
                src={img.url}
                alt={`Featured Property ${index + 1}`}
              />
            ))}
          </div>
          <div className="carousel-nav">
            {property.images.slice(0, 3).map((_, index) => (
              <button
                key={index}
                className={`carousel-nav-dot ${
                  index === currentImageIndex ? 'active' : ''
                }`}
                onClick={() => handleDotClick(index)}
              ></button>
            ))}
          </div>
        </div>
      </div>
      <CloseIcon
        sx={featuredPropertiesMobileStyles.crossIcon}
        onClick={handleFlip}
      />
    </Paper>
  );
};
