import React, {useEffect, useRef} from "react";
import AboutDeveloper from "../propertyPageSections/aboutDeveloper";
import AboutProject from "../propertyPageSections/aboutProject";
import Amenities from "../propertyPageSections/amenities";
import FloorPlan from "../propertyPageSections/floorPlan";
import LocalityGuide from "../propertyPageSections/localityGuide";
import PropertyImageGallery from "../propertyPageSections/propertyImageGallery";
import PropertyBrochure from "../propertyPageSections/propertyBrochure";
import ProjectHighlights from "../propertyPageSections/projectHighlights";
import SimilarProperties from "../propertyPageSections/similarProperties";
import "../propertyPage.css";

function PropertyPageLeftDesktop({
  description,
  propertyLogo,
  keyHighlights,
  exclusiveAmenities,
  amenities,
  floorPlan,
  localityGuide,
  brochure,
  imageGallery,
  developer,
  leftSection
}) {

  return (
    <div className='propertyPageLeftSectionContainer'>
      <AboutProject description={description} propertyLogo={propertyLogo} />
      <ProjectHighlights keyHighlights={keyHighlights} />
      <Amenities
        exclusiveAmenities={exclusiveAmenities}
        amenities={amenities}
      />
      <FloorPlan floorPlan={floorPlan} />
      <LocalityGuide localityGuide={localityGuide} />
      <PropertyBrochure brochure={brochure} />
      <PropertyImageGallery imageGallery={imageGallery} leftSection={leftSection}/>
      <AboutDeveloper developer={developer}  leftSection={leftSection}/>
      {/* <SimilarProperties /> needs API created */}
    </div>
  );
}

export default PropertyPageLeftDesktop;
