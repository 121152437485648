export const propertyData = {
  name: "Vatika Seven Elements",
  locality: "Dwarka Expressway",
  subLocality: "Sector 89A",
  city: "Gurgaon",
  state: "Haryana",
  exclusiveAmenities: [
    {
      _id: "6672aedcca0f3ab4f30aa537",
      title: "24X7 Security",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/24x7Security.svg",
      createdAt: "2024-06-19T10:11:40.737Z",
      updatedAt: "2024-06-19T10:11:40.737Z",
    },
    {
      _id: "6672af11ca0f3ab4f30aa53a",
      title: "24X7 Water",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/24x7WaterSupply.svg",
      createdAt: "2024-06-19T10:12:33.501Z",
      updatedAt: "2024-06-19T10:12:33.501Z",
    },
    {
      _id: "6672af43ca0f3ab4f30aa53d",
      title: "Aerobics Room",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/AEROBICSROOM.svg",
      createdAt: "2024-06-19T10:13:23.211Z",
      updatedAt: "2024-06-19T10:13:23.211Z",
    },
    {
      _id: "6672afa5ca0f3ab4f30aa540",
      title: "Amphitheatre Seating",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Amphitheatre%20Seating.svg",
      createdAt: "2024-06-19T10:15:01.151Z",
      updatedAt: "2024-06-19T10:15:01.151Z",
    },
    {
      _id: "6672afc4ca0f3ab4f30aa543",
      title: "Badminton Court",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Badminton%20Court.svg",
      createdAt: "2024-06-19T10:15:32.703Z",
      updatedAt: "2024-06-19T10:15:32.703Z",
    },
    {
      _id: "6672b4002a9c2e9fcd99a5c7",
      title: "Club House",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Clubhouse.svg",
      createdAt: "2024-06-19T10:33:36.861Z",
      updatedAt: "2024-06-19T10:33:36.861Z",
    },
  ],
  amenities: [
    {
      _id: "6672b054ca0f3ab4f30aa549",
      title: "Bar Lounge",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Bar%20-%20%20Longue.svg",
      createdAt: "2024-06-19T10:17:56.764Z",
      updatedAt: "2024-06-19T10:17:56.764Z",
    },
    {
      _id: "6672b071ca0f3ab4f30aa54c",
      title: "Basketball Court",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Basketball%20Court.svg",
      createdAt: "2024-06-19T10:18:25.475Z",
      updatedAt: "2024-06-19T10:18:25.475Z",
    },
    {
      _id: "6672b0a7ca0f3ab4f30aa54f",
      title: "Billiards Room",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Billiards-Room.svg",
      createdAt: "2024-06-19T10:19:19.715Z",
      updatedAt: "2024-06-19T10:19:19.715Z",
    },
    {
      _id: "6672b0d1ca0f3ab4f30aa552",
      title: "Building Wi-Fi",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Building%20Wi-Fi.svg",
      createdAt: "2024-06-19T10:20:01.536Z",
      updatedAt: "2024-06-19T10:20:01.536Z",
    },
    {
      _id: "6672b0fbca0f3ab4f30aa555",
      title: "CCTV",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/CCTV.svg",
      createdAt: "2024-06-19T10:20:43.163Z",
      updatedAt: "2024-06-19T10:20:43.163Z",
    },
    {
      _id: "6672b142ca0f3ab4f30aa559",
      title: "Cabana",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Cabanas.svg",
      createdAt: "2024-06-19T10:21:54.021Z",
      updatedAt: "2024-06-19T10:21:54.021Z",
    },
    {
      _id: "6672b15dca0f3ab4f30aa55c",
      title: "Camping Ground",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Camping%20Ground.svg",
      createdAt: "2024-06-19T10:22:21.248Z",
      updatedAt: "2024-06-19T10:22:21.248Z",
    },
    {
      _id: "6672b180ca0f3ab4f30aa55f",
      title: "Car Parking",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Car%20Parking.svg",
      createdAt: "2024-06-19T10:22:56.556Z",
      updatedAt: "2024-06-19T10:22:56.556Z",
    },
    {
      _id: "6672b1f0ca0f3ab4f30aa562",
      title: "Chess",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Chess.svg",
      createdAt: "2024-06-19T10:24:48.014Z",
      updatedAt: "2024-06-19T10:24:48.014Z",
    },
    {
      _id: "6672b211ca0f3ab4f30aa565",
      title: "Wall Climbing",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Climbing%20Wall.svg",
      createdAt: "2024-06-19T10:25:21.745Z",
      updatedAt: "2024-06-19T10:25:21.745Z",
    },
    {
      _id: "6672b4002a9c2e9fcd99a5c8",
      title: "Community Hall",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Community%20Hall.svg",
      createdAt: "2024-06-19T10:33:36.861Z",
      updatedAt: "2024-06-19T10:33:36.861Z",
    },
    {
      _id: "6672b4002a9c2e9fcd99a5c9",
      title: "Cricket Field",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Cricket%20Field.svg",
      createdAt: "2024-06-19T10:33:36.861Z",
      updatedAt: "2024-06-19T10:33:36.861Z",
    },
    {
      _id: "6672b4002a9c2e9fcd99a5ca",
      title: "Fire Fighting System",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Fire%20Fighting%20Systems.svg",
      createdAt: "2024-06-19T10:33:36.861Z",
      updatedAt: "2024-06-19T10:33:36.861Z",
    },
    {
      _id: "6672b4002a9c2e9fcd99a5c7",
      title: "Club House",
      iconUrl:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/amenities/Clubhouse.svg",
      createdAt: "2024-06-19T10:33:36.861Z",
      updatedAt: "2024-06-19T10:33:36.861Z",
    },
  ],
  startingPrice: "1.5 Cr.",
  featured: false,
  exclusive: true,
  priority: "HIGH",
  configuration: "2/3/4 BHK",
  description: [
    "Vatika Seven Elements Sector 89A, Gurugram is a luxurious under-construction residential property that is expected to be ready to move in by 2024. The lavish residence is wonderful in many aspects and is comprised of many characteristics that make this property stand out. This luxurious project is being developed by a top real estate developer i.e., Vatika Group or Vatika Collections, so you can be sure of trust, transparency, and good quality.",
    "Vatika Seven Elements is comprised of beautifully designed flats along with a long list of amazing amenities. Some of the amenities included in the property are a swimming pool, a gymnasium, a clubhouse, a cycling track, a party room, a table tennis icon, indoor games, a sewage treatment plant, a kid’s play area, a jogging track, a rainwater harvesting system, a billiards room, a power backup system, a badminton court, and many more.",
    "Vatika Seven Elements floor plan offers ultra-spacious 2, 3, and 4 BHK apartments which are aesthetically designed. These apartments come in different configurations and the configurations for these apartments are – 2 BHK comes with a size range of 1605 sq. ft., whereas 3 BHK comes with a size range of 1950-2085 sq. ft, whereas 4 BHK comes with a total size range of 2505 sq. ft.",
  ],
  keyHighlights: [
    "The project offers low-density living with just 45 families per acre.",
    "Seamlessly connected by 3 major national highways.",
    "The project features 172,400 sq. ft. Of the total area of the club, amenities, and recreational spaces.",
  ],
  area: "1605 Sq. Ft. - 2505 Sq. Ft.",
  squarePrice: "10000",
  status: "Under Construction",
  displayLocality: true,
  localityGuide: [
    {
      title: "Schools",
      guideList: [
        {
          name: "Matri Kiran High School",
          distance: "5.5 Km",
        },
        {
          name: "Delhi Public School",
          distance: "5 Km",
        },
      ],
    },
    {
      title: "Office Spaces",
      guideList: [
        {
          name: "Inframantra",
          distance: "9.5 Km",
        },
        {
          name: "Aashram Tech Park",
          distance: "15 Km",
        },
      ],
    },
    {
      title: "Malls",
      guideList: [
        {
          name: "Ambience Mall",
          distance: "9.5 Km",
        },
        {
          name: "MGF Metropolitian",
          distance: "3 Km",
        },
        {
          name: "Worldmark",
          distance: "25 Km",
        },
        {
          name: "Aria Mall",
          distance: "95 Km",
        },
      ],
    },
    {
      title: "Hospitals",
      guideList: [
        {
          name: "Manipal Hospital",
          distance: "5.5 Km",
        },
        {
          name: "Fortis",
          distance: "9 Km",
        },
        {
          name: "AIIMS Gurgaon",
          distance: "Nearby",
        },
      ],
    },
    {
      title: "Entertainment",
      guideList: [
        {
          name: "Cricket Stadium",
          distance: "7.8 Km",
        },
        {
          name: "Running Ground",
          distance: "12 Km",
        },
      ],
    },
    {
      title: "Connectivity",
      guideList: [
        {
          name: "Site for ISBT and Metro Hub",
          distance: "6 KM",
        },
        {
          name: "IGI Airport",
          distance: "Nearby",
        },
      ],
    },
  ],
  floorPlan: [
    {
      price: "1.5 Cr.",
      superArea: "92.71 Sq. m",
      carpetArea: "",
      configuration: "2BHK",
      floorImg:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/floorPlan/vatikasevenelements/2bhk.avif",
      value: 1,
    },
    {
      price: "1.5 Cr.",
      superArea: "92.71 Sq. m",
      carpetArea: "",
      configuration: "3 BHK + SR",
      floorImg:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/floorPlan/vatikasevenelements/3bhk.avif",
      value: 2,
    },
    {
      price: "1.5 Cr.",
      superArea: "92.71 Sq. m",
      carpetArea: "110.55 Sq. ft",
      configuration: "4 B HK",
      floorImg:
        "https://inframantra.blr1.cdn.digitaloceanspaces.com/floorPlan/vatikasevenelements/4bhk.avif",
      value: 3,
    },
  ],
  brochure:
    "https://inframantra.blr1.cdn.digitaloceanspaces.com/brochure/vatikasevenelements/vatikasevenelements.pdf",
  propertyLogo:
    "https://inframantra.blr1.cdn.digitaloceanspaces.com/propertyLogo/vatikasevenelements/logo.avif",
  developer: {
    name: "Vatika Group",
    developerImg:
      "https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/vatikagroup/vatikagroup.avif",
    totalProperties: "20",
    experienceYears: "15",
    description:
      "Vatika Group is a well-known developer in the real estate industry. They have built a name for developing residential and commercial spaces of excellent quality. Their products hold unparalleled quality and timelessness. The group runs on the vision to be the most respected real estate company. The group believes in delivering value to all of its stakeholders by creating services and products that enhance the value of life.",
  },
  rera: "281 of 2017",
  posession: "Oct, 2024",
  imageGallery: [
    {
      key: "properties/vatikasevenelements/1.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/1.avif",
    },
    {
      key: "properties/vatikasevenelements/10.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/10.avif",
    },
    {
      key: "properties/vatikasevenelements/2.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/2.avif",
    },
    {
      key: "properties/vatikasevenelements/3.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/3.avif",
    },
    {
      key: "properties/vatikasevenelements/4.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/4.avif",
    },
    {
      key: "properties/vatikasevenelements/5.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/5.avif",
    },
    {
      key: "properties/vatikasevenelements/6.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/6.avif",
    },
    {
      key: "properties/vatikasevenelements/7.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/7.avif",
    },
    {
      key: "properties/vatikasevenelements/8.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/8.avif",
    },
    {
      key: "properties/vatikasevenelements/9.avif",
      url: "https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/9.avif",
    },
  ],
  coordinates: {
    lat: 28.448769,
    lng: 77.041311,
  },
  tagLine: "Redifining Luxury Living In Gurgaon",
  priceInFigure: 15000000,
};
