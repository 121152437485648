import React, { useEffect } from 'react';
import HeaderSection from './headerSection/headerSection';
import StatisticalInsightsSection from './statisticalInsightsSection/statisticalInsightsSection';
import FeaturedPropertiesSection from './featuredPropertiesSection/featuredPropertiesSection';
import ServicesSection from './servicesSection/servicesSection';
import ClientTestimonySection from './clientTestimonySection/clientTestimonySection';
import ImageGallerySection from './imageGallerySection/imageGallerySection';
import PropertyPage from '../propertyPage/propertyPage';
import PopularLocalities from './PopularLocalitiesSection/popularLocalities';
import ContactUsSection from './contactUsSection/contactUsSection';
import { useDispatch } from 'react-redux';
import { getFeaturedProperties } from '../../reduxSlices/propertySlice';
import './homePage.css';
import Wrapper from '../../components/UI/Wrapper';

function HomePage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeaturedProperties());
  }, [dispatch]);

  return (
    <Wrapper>
      <section>
      <HeaderSection />
      <FeaturedPropertiesSection />
      <PopularLocalities />
      <ServicesSection />
      <StatisticalInsightsSection />
      <section className="imageGallerySectionContainer">
        <div className="imageGalleryHeaderFlex">
          <h2 className="imageGalleryContainerHeader">
            Our Legacy & Your Future:{' '}
            <span style={{ color: '#E7B554' }}>A Visual Experience</span>
          </h2>
        </div>
        <ImageGallerySection />
      </section>
      <ClientTestimonySection />
      <ContactUsSection />
    </section>
    </Wrapper>
  );
}

export default HomePage;
