import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import Wishlist from '../../common/wishlist/wishlist';
import featured from '../../assets/featured.jpg';
import useMediaQuery from '../../utils/useMediaQuery';
import { useSelector, useDispatch } from 'react-redux';
import { getPropertiesByIds } from '../../reduxSlices/propertySlice';
import { useNavigate } from 'react-router-dom';
import './wishlistDrawer.css';
import { Close } from '@mui/icons-material';

function WishlistDrawer({ open, toggleDrawer }) {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [wishlistProperties, setWishlistProperties] = useState(null);
  const wishlistedPropertiesIds = useSelector((state) => state.wishlist);
  const wishlistPropertyData = useSelector(
    (state) => state.property.wishListProperties
  );

  useEffect(() => {
    if (wishlistedPropertiesIds.length > 0) {
      dispatch(getPropertiesByIds(wishlistedPropertiesIds));
    }
  }, [dispatch, wishlistedPropertiesIds, wishlistedPropertiesIds.length]);

  useEffect(() => {
    if (wishlistedPropertiesIds.length > 0) {
      setWishlistProperties(wishlistPropertyData);
    } else {
      setWishlistProperties([]);
    }
  }, [wishlistedPropertiesIds.length, wishlistPropertyData]);

  const wishlistMuiStyles = {
    paper: {
      height: isDesktop ? '26vh' : '22vh',
      width: '90%',
      marginBottom: isDesktop ? '30px' : '25px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
    },
    box: {
      width: isDesktop ? '35vw' : '90vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
  };


  const list = (
    <Box
      sx={wishlistMuiStyles.box}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <h4 className="wishlistPropertyHeader">Your Shorlisted Properties</h4>
      <p>{wishlistProperties?.length >= 1 ? "" : "No properties to show"}</p>
      <div className='wishListCloseButton'><Close/></div>
      {wishlistProperties &&
        wishlistProperties.map((property) => {
          return (
            <Paper
              elevation={8}
              sx={wishlistMuiStyles.paper}
              key={property._id}
              onClick={() => navigate(`/property/${property.slug}`)}
            >
              <div className="wishlistPropertyHeaderFlex">
                <p>{property.name}</p>
              </div>
              <div className="wishlistPropertySubHeaderFlex">
                <p>
                  {property.subLocality.name}, {property.city.name}
                </p>
              </div>
              <div className="wishlistPropertyDetailsFlex">
                <div className="wishlistPropertyDetailsSectionLeft">
                  <p>Price</p>
                  <p>Config.</p>
                  <p>Area</p>
                </div>
                <div className="wishlistPropertyDetailsSectionRight">
                  <p style={{ color: '#DCAA4C' }}>{property.startingPrice}</p>
                  <p style={{ color: '#DCAA4C' }}>{property.configuration}</p>
                  <p style={{ color: '#DCAA4C' }}>
                    {property.area.slice(0, 4)} Sq.ft
                  </p>
                </div>
                <div className="wishlistPropertyImgSection">
                  <img src={property.imageGallery[0].url} alt={property.name} />
                </div>
              </div>
            </Paper>
          );
        })}
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      transitionDuration={{ enter: 700, exit: 500 }}
    >
      {list}
    </SwipeableDrawer>
  );
}

export default WishlistDrawer;
