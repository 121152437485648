import React from 'react'
// import Image from 'next/image'
import Infra_logo from '../../../../../assets/images/infra-logo.png'
import './noImages.css'
export default function NoImage() {
  return (
    <div className='noimage-block'>
        <div className='noimage-logo'>
           <span>
           <img
                src={Infra_logo}
                layout="fill"
                objectFit='fill'
                objectPosition='center'
                alt="Inframantra Logo"
            />
           </span>
        </div>
    </div>
  )
}
