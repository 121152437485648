export const popularLocalities = [
  {
    localityName: "Golf Course Ext Road",
    localityList: [
      "M3M Altitude",
      "DLF The Arbour",
      "Anant Raj Ashok Estate",
      "M3M Golf Estate"
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/golf%20course%20extension.avif',
  },
   {
    localityName: "Southern Peripheral Road",
    localityList: [
      "Whiteland The Aspen",
      "DLF Privana",
      "DLF Alameda",
      "DLF Privana West"
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/spr.avif',
  },
  {
    localityName: "Dwarka Expressway",
    localityList: [
      "Vatika Sovereign Park",
      "BPTP Terra",
      "Godrej Meridien",
      "BPTP The Amaario"
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/dwarka.avif',
  },
   {
    localityName: "New Gurgaon",
    localityList: [
      "Vatika Seven Elements",
      "Tulip Ace",
     "Krisumi Waterfall Residences",
     "Godrej Zenith"
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/newggn.avif',
  },
  {
    localityName: "Pune East",
    localityList: [
      "Lodha Estilo",
      "Gera Island of Joy",
      "Pristine O2 World",
      "Majestique Evolvus",
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/kharadi.avif',
  },
  {
    localityName: "Hinjewadi, Pune",
    localityList: [
      "Lodha Magnus",
      "Lodha Panache",
      "Godrej Woodsville",
      "Kolte Patil ",
    ],
    localityImg: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/Hinjewadi.avif',
  },
 
 
];
