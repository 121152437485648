import React, { useState, useEffect } from 'react';
import PropertyWrapper from './propertyWrapper';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Button from '../../../common/button/button';
import FormControlLabel from '@mui/material/FormControlLabel';
import useMediaQuery from '../../../utils/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { sendProjectEnquiry } from '../../../reduxSlices/enquirySlice.js';
import Alert from '@mui/material/Alert';
import { useMuiSnackbar } from '../../../components/UI/useMuiSnackbar.jsx';


const contactFormStyles = {
  textField: {
    marginBottom: '10px',
  },
  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '30px',
  },
};

function PropertyPageFloatingContact({ name }) {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const { showSnackbar } = useMuiSnackbar();
  const { data, loading, error } = useSelector(state => state.enquiries);
  const [formData, setFormData] = useState({
    projectName: name || '',
    name: '',
    email: '',
    phoneNumber: '',
    configuration: '',
  });
  const [selectedConfigurations, setSelectedConfigurations] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setSelectedConfigurations((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        sendProjectEnquiry({
          ...formData,
          configuration: selectedConfigurations.join(', '),
        })
      )
      showSnackbar('Enquiry sent successfully', 'success');
    } catch (error) {
      showSnackbar(`Failed to send enquiry ${error}`, 'error');
    }
    setFormData({
      projectName: name || '',
      name: '',
      email: '',
      phoneNumber: '',
      configuration: '',
    });
    setSelectedConfigurations([]);
  };
  

  return (
    <PropertyWrapper
      children={
        <form className="aboutProjectWrapper" onSubmit={handleSubmit}>
          <div className="propertyPageFloatingContactHeaderWrapper">
            <p>Get Expert Advice and Information for</p>
            <p
              style={{
                color: '#e7b554',
                fontWeight: '700',
                fontSize: '20px',
                marginBottom: '10px',
              }}
            >
              {name}
            </p>
          </div>
          <div className="propertyPageFloatingContactWrapper">
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <TextField
              id="email"
              name="email"
              label="Email Address"
              variant="standard"
              fullWidth={true}
              sx={contactFormStyles.textField}
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="propertyPageFloatingContactWrapper">
            <p>I’m looking into</p>
            <FormGroup sx={contactFormStyles.checkBox}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="2BHK"
                    checked={selectedConfigurations.includes('2BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="2BHK"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="3BHK"
                    checked={selectedConfigurations.includes('3BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="3BHK"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="success"
                    value="4BHK"
                    checked={selectedConfigurations.includes('4BHK')}
                    onChange={handleCheckboxChange}
                  />
                }
                label="4BHK"
                sx={{ marginLeft: '0px', marginRight: '0px' }}
              />
            </FormGroup>
            <Button
              type="submit"
              width={isDesktop ? '15vw' : '60vw'}
              fontWeight="700"
              otherStyles={{ marginBottom: '30px' }}
              btnText="Contact Now"
            />
          </div>
        </form>
      }
    />
  );
}

export default PropertyPageFloatingContact;
