import React from "react";
import AboutDeveloper from "../propertyPageSections/aboutDeveloper";
import AboutProject from "../propertyPageSections/aboutProject";
import ProjectHighlights from "../propertyPageSections/projectHighlights";
import PropertyEMICalculator from "../propertyPageSections/propertyEMICalculator";
import PropertyVideo from "../propertyPageSections/propertyVideo";
import Amenities from "../propertyPageSections/amenities";
import FloorPlan from "../propertyPageSections/floorPlan";
import expressway from "../../../assets/expressway.jpeg";
import gurgaon from "../../../assets/gurgaon.jpeg";
import LocalityGuide from "../propertyPageSections/localityGuide";
import PropertyImageGallery from "../propertyPageSections/propertyImageGallery";
import PropertyBrochure from "../propertyPageSections/propertyBrochure";
import SuggestedLocationProperties from "../propertyPageSections/suggestedLocationProperties";
import PropertyPageFloatingContact from "../propertyPageSections/propertyPageFloatingContact";
import SimilarProperties from "../propertyPageSections/similarProperties";
import "../propertyPage.css";


function PropertyPageMobile({
  description,
  propertyLogo,
  keyHighlights,
  amenities,
  exclusiveAmenities,
  floorPlan,
  priceInFigure,
  localityGuide,
  imageGallery,
  developer,
  name,
  brochure,
  leftSection
}) {
  return (
    <>
      <div className='propertyPageLeftSectionContainer'>
        <AboutProject description={description} propertyLogo={propertyLogo} />
        <ProjectHighlights keyHighlights={keyHighlights} />
        <Amenities
          amenities={amenities}
          exclusiveAmenities={exclusiveAmenities}
        />
        <PropertyVideo />
        <FloorPlan floorPlan={floorPlan} />
        <PropertyEMICalculator initialPrincipal={priceInFigure} />
        <LocalityGuide localityGuide={localityGuide} />
        <PropertyBrochure brochure={brochure}/>
        <PropertyImageGallery imageGallery={imageGallery} leftSection={leftSection}/>
        <AboutDeveloper developer={developer} leftSection={leftSection} />
        <SuggestedLocationProperties
          bgdImg={expressway}
          bannerTxt='Explore other Properties on Dwarka Expressway'
        />
        <SuggestedLocationProperties
          bgdImg={gurgaon}
          bannerTxt='Find Out What else Gurgaon Is Offering'
        />
        <PropertyPageFloatingContact name={name} />
        {/* <SimilarProperties /> needs API created */}
      </div>
    </>
  );
}

export default PropertyPageMobile;
