import Section from "../blogComponents/featuredBlogs/Section"
import NoImage from "../blogComponents/featuredBlogs/noImage/NoImage"
import { useState, useEffect } from "react";
import './blogContent.css';
import Share from "./Share.jsx"
import { blogType } from "../blogComponents/featuredBlogs/blogType.js";

export default function BlogContent({detailContent, recent, data, name}) {
  // console.log(data);
  const [blogtype,setBlogType] = useState(null);
  useEffect(() => {
    setBlogType(blogType.result)
})

console.log("Recent Blog Type",  data.title, name);


  return (
    <Section classes="blog-content sec-p" pageWidth="container">
        <div className="blog-content-wrap">
          <div className="text-wrap">
           <div className="page-banner-content blog-banner-content">
                <div className="page-width">
                    <h1>{data.title}</h1>
                    <p className="date">{data.date}</p>
                </div>
            </div>
              <div className="content" dangerouslySetInnerHTML={{ __html: detailContent}} >
              </div> 
              <Share content={detailContent}/>
              <div className="written-by">
                  <h4>Written By: </h4><span>{name}</span>
              </div>
          </div>
          <div className="sidebar1">
              <div className="sd-card">
              <div className="sd-card-head">
                <h3>Popular Types</h3>
              </div>
              <div className="sd-categories">
                <ul className="sd-cat-list">
                  { blogtype !== null && blogtype.map((data)=>(
                    <li key={data.id}>
                      <a href={`/blog/category/${data.slug}`}><p>{data.name}</p></a>
                    </li>
                  ))}
                </ul>
              </div>
               </div>
            <div className="sd-card">
              <div className="sd-card-head">
                <h3>Recent Blogs</h3>
              </div>
              <div className="sd-latest-blogs">
                {
                  recent.map((blog) => (
                    <a href={`/blog/${blog.slug}`} key={blog.id}>
                      <div className="sd-blg-item">
                        <div className="icon">
                          {blog.file ?
                          <img src={blog.file.path} layout="fill" alt={blog.name}/>
                          : <NoImage/> }
                        </div>
                        <div className="info">
                          <p className="date">{blog.publish}</p>
                          <h4>{blog.name}</h4>
                        </div>
                      </div>
                    </a>
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
    </Section>
  )
}
