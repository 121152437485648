
// import NoImage from "./NoImage";
// import Section from "./Section";
import React from 'react';
import './pageHeader.css'

const PageHeader = (props) => {
const item = props.data;

//  console.log(props);

    return(
        <section className="page-header" id="" pageWidth="fluid">
            {item.image ?
              <picture>
                 <img src={item.image} alt="Banner Image" layout="fill" objectFit="cover" />
              </picture>
            :
            <div>No Image Found</div>
            }
            <div className="overlay"></div>
            <div className="page-banner-content">
                <div className="page-width">
                    <h1>{item.title}</h1>
                    <p className="date">{item.date}</p>
                </div>
            </div>
        </section>
    )
}

export default PageHeader;