import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./paginationSlider.css";

const PaginationSlider = ({ totalItems, currentIndex, setCurrentIndex }) => {
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, totalItems - 1));
  };

  const individualWidth = 100 / totalItems;

  return (
    <div className='paginationSliderWrapper'>
      <button
        className='paginationSliderArrowBtn'
        onClick={handlePrevious}
        disabled={currentIndex === 0}
      >
        <ArrowBackIosIcon
          sx={{
            fontSize: "1.5em",
            width: "1em",
            height: "1.5em",
            color: "inherit",
            margin: "0",
          }}
        />
      </button>
      <button
        className='paginationSliderArrowBtn'
        onClick={handleNext}
        disabled={currentIndex === totalItems - 1}
      >
        <ArrowForwardIosIcon
          sx={{
            fontSize: "1.5em",
            width: "1em",
            height: "1.5em",
            color: "inherit",
          }}
        />
      </button>
      <div
        style={{
          flexGrow: 1,
          height: "2px",
          backgroundColor: "#D9D9D9",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: `${currentIndex * individualWidth}%`,
            top: "0",
            bottom: "0",
            width: `${individualWidth}%`,
            backgroundColor: "#e7b554",
            transition: "left 0.3s ease-out",
          }}
        />
      </div>

      <span className='paginationSliderPageNumbers'>
        {String(currentIndex + 1).padStart(2, "0")} /{" "}
        <span className='totalItems'>
          {String(totalItems).padStart(2, "0")}
        </span>
      </span>
    </div>
  );
};

export default PaginationSlider;
