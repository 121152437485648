export const propertyPageNavigtionValues = [
  {
    label: "Overview",
    section: "overview",
  },
  {
    label: "About Project",
    section: "aboutProject",
  },
  {
    label: "Highlights",
    section: "highlights",
  },
  {
    label: "Amenities",
    section: "amenities",
  },
  {
    label: "Floor Plan",
    section: "floorPlan",
  },
  {
    label: "Price List",
    section: "floorPlan",
  },
  {
    label: "EMI Calculator",
    section: "emiCalculator",
  },
  {
    label: "Locality Map",
    section: "localityMap",
  },
  {
    label: "Brochure",
    section: "brochure",
  },
  {
    label: "Image Gallery",
    section: "imageGallery",
  },
  {
    label: "About Developer",
    section: "aboutDeveloper",
  },
  {
    label: "Featured Properties",
    section: "featuredProperties",
  },
];
