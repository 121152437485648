import React, {useState} from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import mapPhoto from '../../../assets/mapPhoto.png';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import useMediaQuery from '../../../utils/useMediaQuery';
import Paper from '@mui/material/Paper';
import './contactUsSection.css';
import { useDispatch } from 'react-redux';
import { sendContactEnquiry } from '../../../reduxSlices/enquirySlice';

function ContactUsSection() {
  const isDesktop = useMediaQuery(786);
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the form data, or submit it to an API endpoint
    dispatch(sendContactEnquiry(formData)); // If you're using Redux
    setFormData('');
    // fetch('/api/contact', { method: 'POST', body: JSON.stringify(formData), headers: { 'Content-Type': 'application/json' } })
    //   .then(response => response.json())
    //   .then(data => console.log(data));
  };

  return (
    <div className="contactUsSectionContainer">
      <div className="contactUsSectionWrapper">
        <div className="contactUsLeftDetailSection">
          {isDesktop && (
            <div className="contactUsLeftMapPhotoContainer">
              <img
                className="contactUsLeftMapPhoto"
                src={mapPhoto}
                alt="mapPhoto"
              />
              <div className="mapPhotoBgdTopLeft"></div>
              <div className="mapPhotoBgdBottomRight"></div>
            </div>
          )}
          {isDesktop && (
            <img
              className="contactUsLeftDetailSectionImg"
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantraLogoBlack.png"
              alt="Inframantra-Logo"
            />
          )}
          <div className="contactUsLeftDetailsContainer">
            <div className="contactUsLeftDetailFlex">
              <PlaceIcon
                sx={{
                  color: '#E7B554',
                  fontSize: '25px',
                  marginRight: '10px',
                }}
              />
              <p>95, Institutional Area, Sector 32, Gurugram</p>
            </div>
            <div className="contactUsLeftDetailFlex">
              <LocalPhoneIcon
                sx={{
                  color: '#E7B554',
                  fontSize: '25px',
                  marginRight: '10px',
                }}
              />
              <p>+91 86 9800 9900 </p>
            </div>
            <div className="contactUsLeftDetailFlex">
              <MailIcon
                sx={{
                  color: '#E7B554',
                  fontSize: '25px',
                  marginRight: '10px',
                }}
              />
              <p>marketing@inframantra.com </p>
            </div>
          </div>
        </div>
        <div className="contactUsRightMapSection">
          <img
            className="contactUsRightMapSectionGuruImg"
            src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/guruImageHomeContact.png"
            alt="Guru Randhawa"
          />
          <div className="contactUsSectionForm">
            {/* <div className="contactUsSectionFormFlex"> */}
            <form onSubmit={handleSubmit} className="contactUsSectionFormFlex">
              <h4>Get In Touch With Us</h4>
              <TextField
                id="name"
                name="name"
                label="Name"
                variant="standard"
                sx={{ width: '70%', marginBottom: '6%' }}
                value={formData.name}
                onChange={handleChange}
              />
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                variant="standard"
                sx={{ width: '70%', marginBottom: '6%' }}
                value={formData.phoneNumber}
                onChange={handleChange}
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="standard"
                sx={{ width: '70%', marginBottom: '6%' }}
                value={formData.email}
                onChange={handleChange}
              />
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#fff',
                  color: '#E7B554',
                  width: '40%',
                }}
              >
                Submit
              </Button>
            </form>
            <p>By submitting, I accept Inframantra <a href="/page/disclaimer">Terms &amp; Conditions</a> and <a href="/page/privacy-policy">Privacy Policy</a></p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsSection;
