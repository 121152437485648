import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropertyListingPageMobileFilterTabs from "../../../components/propertyListingPageMobileFilterTabs/propertyListingPageMobileFilterTabs";
import "./propertyListingPageMobileFilter.css";
import PropertyListingPriceFilter from "./propertyListingPriceFilter/propertyListingPriceFilter";
import {
  unitTypeFilterConstants,
  configuration,
  status,
} from "../propertyListingConstant";
import Button from "../../../common/button/button";
import { priceRangeData } from "../../../components/searchOptions/dropDownMenuConstants";

function PropertyListingPageMobileFilter({ toggleDrawer }) {
  const [priceRange, setPriceRange] = useState({
    range: [10000000, 40000000],
    label: "Price",
  });

  const handleChangePriceRange = (newRange) => {
    const startLabel =
      priceRangeData.find((p) => p.value === newRange[0])?.label || "";
    const endLabel =
      priceRangeData.find((p) => p.value === newRange[1])?.label || "";
    setPriceRange({
      range: newRange,
      label: `${startLabel} - ${endLabel}`,
    });
  };

  return (
    <div className='propertyListingPageMobileFilterWrapper'>
      <div className='propertyListingPageMobileFilterHeader'>
        <CloseIcon sx={{ width: "20%" }} onClick={toggleDrawer} />
        <p className='propertyListingPageMobileFilterHeaderValue'>Filters</p>
        <p className='propertyListingPageMobileFilterHeaderReset'>Reset</p>
        <hr className='propertyListingPageMobileFilterHeaderSwipeBar' />
      </div>
      <div className='propertyListingPageMobileFilterValueWrapper'>
        <div className='propertyListingPageMobileFilterUnitType'>
          <p className='propertyListingPageMobileFilterUnitTypeHeader'>
            Unit Type
          </p>
          <div className='propertyListingMobileFilterFlex'>
            {unitTypeFilterConstants.map((unitType) => {
              return <PropertyListingPageMobileFilterTabs title={unitType} />;
            })}
          </div>
        </div>
        <div className='propertyListingPageMobileFilterUnitType'>
          <p className='propertyListingPageMobileFilterUnitTypeHeader'>
            Configuration
          </p>
          <div className='propertyListingMobileFilterFlex'>
            {configuration.map((config) => {
              return <PropertyListingPageMobileFilterTabs title={config} />;
            })}
          </div>
        </div>
        <div className='propertyListingPageMobileFilterUnitType'>
          <p className='propertyListingPageMobileFilterUnitTypeHeader'>
            Price Range
          </p>
          <div className='propertyListingMobileFilterFlex priceFliterFlex'>
            <PropertyListingPriceFilter
              priceRange={priceRange.range}
              onChangePriceRange={handleChangePriceRange}
            />
          </div>
        </div>
        <div className='propertyListingPageMobileFilterUnitType'>
          <p className='propertyListingPageMobileFilterUnitTypeHeader'>
            Project Status
          </p>
          <div className='propertyListingMobileFilterFlex'>
            {status.map((status) => {
              return <PropertyListingPageMobileFilterTabs title={status} />;
            })}
          </div>
        </div>
        <div className='propertyListingMobileFilterFlex priceFliterFlex mobileFilterBtn'>
          <Button
            padding='5px 50px'
            clickHandler={toggleDrawer}
            btnText='Apply Filters'
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyListingPageMobileFilter;
