import Section from "./Section.js"
import NoImage from './noImage/NoImage.jsx'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import { useState, useEffect } from "react"
import './featuredBlog.css'
import { blogType } from "./blogType.js";

function FeaturedBlogs({ blogs }) {
    // console.log("Blogs data from the Featured Blog Page", blogs)
    const [blogtype, setBlogType] = useState(null);
    useEffect(() => {
        setBlogType(blogType.result)
    })

    return (
        <Section classes="sec-p featured-blog" pageWidth="container">
            <div className="section-head">
                <h2>Featured Blogs</h2>
            </div>
            <div className="featured-blogs-container desk-show">
                <div className="blog-container">
                    {blogs !== null && blogs.length > 0 ? (
                        <Swiper
                            slidesPerView={1}
                            centeredSlides={true}
                            spaceBetween={0}
                            loop={true}
                            speed={1000}
                            autoplay={{
                                delay: 5000,
                                // disableOnInteraction: true,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Navigation, Pagination]}
                            className="ft-media-sld"
                        >
                            {blogs.map((item) => (
                                <SwiperSlide key={item.id}>
                                    {item.link ? (
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="ft-media-item">
                                                <img
                                                    src={item.file.path}
                                                    layout="fill"
                                                    objectFit="cover"
                                                    alt="Blog image"
                                                />
                                                <div className="info">
                                                    <h4>{item.name}</h4>
                                                    <div
                                                        href={item.link}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="lrn-btn"
                                                    >
                                                        Read More
                                                        <svg
                                                            width="15"
                                                            height="14"
                                                            viewBox="0 0 15 14"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M1.281 7h12.032M7.297 1l6.016 6-6.016 6"
                                                                stroke="#E7B554"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    ) : (
                                        <a
                                            href={`/blog/${item.slug}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="ft-media-item">
                                                <span>
                                                    <img
                                                        src={item.file.path}
                                                        layout="fill"
                                                        objectFit="cover"
                                                        alt="ft image"
                                                    />
                                                </span>
                                                <div className="info">
                                                    <h4>{item.title}</h4>
                                                    <a href={`/blog/${item.slug}`}>
                                                        <a className="lrn-btn" target="_blank">
                                                            Read More
                                                            <svg
                                                                width="15"
                                                                height="14"
                                                                viewBox="0 0 15 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M1.281 7h12.032M7.297 1l6.016 6-6.016 6"
                                                                    stroke="#E7B554"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </a>
                                                </div>
                                            </div>
                                        </a>
                                    )}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : (
                        <h3 className="text-center">
                            No Featured Blog For The selected Type
                        </h3>
                    )}
                    <div className="blog-content-wrap blog-wrap">
                        <div className="sidebar">
                            <div className="sd-card">
                                <div className="sd-card-head">
                                    <h3>Popular Types</h3>
                                </div>
                                <div className="sd-categories">
                                    <ul className="sd-cat-list">
                                        {blogtype !== null &&
                                            blogtype.map((data) => (
                                                <li key={data.id}>
                                                    <a href={`/blog/category/${data.slug}`}>
                                                        <p>{data.name}</p>
                                                    </a>
                                                </li>
                                            ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="featured-media-wrap mob-show">
                {blogs !== null && blogs.length > 0 ? (
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        spaceBetween={0}
                        loop={true}
                        speed={1000}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: true,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={false}
                        className="ft-media-sld"
                        modules={[Autoplay, Navigation, Pagination]}
                    >
                        {blogs.map((item) => (
                            <SwiperSlide key={item.id}>
                                <div className="ft-media-item">
                                    <span>
                                        <img
                                            src={item.file.path}
                                            layout="fill"
                                            objectFit="cover"
                                            alt="ft image"
                                        />
                                    </span>
                                    <div className="info">
                                        <h4>{item.title}</h4>
                                        {item.link ? (
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={item.link}
                                                className="lrn-btn"
                                            >
                                                Read More{" "}
                                                <svg
                                                    width="15"
                                                    height="14"
                                                    viewBox="0 0 15 14"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M1.281 7h12.032M7.297 1l6.016 6-6.016 6"
                                                        stroke="#E7B554"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </a>
                                        ) : (
                                            <a href={`/blog/${item.slug}`}>
                                                <div className="lrn-btn">
                                                    Read More{" "}
                                                    <svg
                                                        width="15"
                                                        height="14"
                                                        viewBox="0 0 15 14"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M1.281 7h12.032M7.297 1l6.016 6-6.016 6"
                                                            stroke="#E7B554"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        />
                                                    </svg>
                                                </div>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <h3 className="text-center">
                        No Featured Blog For The selected Type
                    </h3>
                )}
                <div className="blog-content-wrap blog-wrap">
                    <div className="sidebar">
                        <div className="sd-card">
                            <div className="sd-card-head">
                                <h3>Popular Types</h3>
                            </div>
                            <div className="sd-categories">
                                <ul className="sd-cat-list">
                                    {blogtype !== null &&
                                        blogtype.map((data) => (
                                            <li key={data.id}>
                                                <a href={`/blog/category/${data.slug}`}>
                                                    <p>{data.name}</p>
                                                </a>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default FeaturedBlogs;