import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import ApartmentSharpIcon from '@mui/icons-material/ApartmentSharp';
import MiscellaneousServicesSharpIcon from '@mui/icons-material/MiscellaneousServicesSharp';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp';
import Diversity3SharpIcon from '@mui/icons-material/Diversity3Sharp';
import SpeakerNotesSharpIcon from '@mui/icons-material/SpeakerNotesSharp';
import PermMediaSharpIcon from '@mui/icons-material/PermMediaSharp';
import GavelSharpIcon from '@mui/icons-material/GavelSharp';
import PolicySharpIcon from '@mui/icons-material/PolicySharp';
import ReportProblemSharpIcon from '@mui/icons-material/ReportProblemSharp';
import HandshakeSharpIcon from '@mui/icons-material/HandshakeSharp';

export const primaryMobileNavValues = [{
    title:'Home',
    icon:<HomeSharpIcon />,
    nav:''
},
{
    title:'Properties',
    icon:<ApartmentSharpIcon />,
     nav:'property-listing/city/Gurgaon'
},
{
    title:'Services',
    icon:<MiscellaneousServicesSharpIcon />,
     nav:'our-services'
},
{
    title:'Contact Us',
    icon:<ContactPhoneSharpIcon />,
     nav:'contact-us'
}
]
export const secondaryMobileNavValues = [{
    title:'About Us',
    icon:<Diversity3SharpIcon />,
     nav:'about-us'
},
{
    title:'Testimonials',
    icon:<SpeakerNotesSharpIcon />,
     nav:'testimonials'
},
{
    title:'Media And Blogs',
    icon:<PermMediaSharpIcon />,
     nav:'blog'
},
]
export const tertiaryMobileNavValues = [{
    title:'Terms And Conditions',
    icon:<GavelSharpIcon  />,
    nav:'page/terms-conditions'
},
{
    title:'Privacy Policy',
    icon:<PolicySharpIcon />,
    nav:'page/privacy-policy'
},
{
    title:'Disclaimer',
    icon:<ReportProblemSharpIcon />,
    nav:'page/disclaimer'
},
{
    title:'User Agreement',
    icon:<HandshakeSharpIcon />,
    nav:'page/user-agreement'
}]