import axios from 'axios';
import { POST_SEARCH_NAMES } from '../reduxSlices/contants';


export const searchNames = async (names) => {
  try {
    const response = await axios.post(`${POST_SEARCH_NAMES}`, {names});
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};


// const searchNames = async (names) => {
//   const response = await axios.post('/api/search-names', { names });
//   return response.data;
// };

// export default { searchNames };
