import React, { useState, useEffect, useRef } from "react";
import PropertyListingSearchData from "./propertyListingSearchData/propertyListingSearchData";
import CloseIcon from "@mui/icons-material/Close";
import "./propertyListingSearch.css";

function PropertyListingSearch({ location }) {
  const [searchSelectionBox, setSearchSelectionBox] = useState(false);
  const [searchOptionValue, setSearchOptionValue] = useState("");
  const [selectedValueText, setSelectedValueText] = useState("");
  const wrapperRef = useRef(null);

  const handleSearchDropdownOpen = () => {
    if (!selectedValueText) {
      setSearchSelectionBox(true);
    }
  };

  const handlePropertyListSearchChange = (e) => {
    if (!selectedValueText) {
      setSearchOptionValue(e.target.value);
    }
  };

  const handleSelectedValue = (val) => {
    setSelectedValueText(val);
    setSearchSelectionBox(false);
    setSearchOptionValue("");
  };

  const handleClearSelectedValue = () => {
    setSelectedValueText("");
    setSearchOptionValue("");
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setSearchSelectionBox(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='propertyListingSearcContainer' ref={wrapperRef}>
      <input
        className={`propertyListingSearchWrapper ${
          selectedValueText ? "disabled" : ""
        }`}
        placeholder='Search by property or locality'
        onClick={handleSearchDropdownOpen}
        onChange={handlePropertyListSearchChange}
        value={searchOptionValue}
        readOnly={!!selectedValueText}
      />
      {selectedValueText && (
        <SelectedTextBox
          selectedOption={selectedValueText}
          onClear={handleClearSelectedValue}
        />
      )}
      {searchSelectionBox && (
        <PropertyListingSearchData
          searchOptionValue={searchOptionValue}
          selectedValue={(val) => handleSelectedValue(val)}
          location={location}
        />
      )}
    </div>
  );
}

export default PropertyListingSearch;

const SelectedTextBox = ({ selectedOption = "", onClear }) => {
  return (
    <div className='selectedTextBoxWrapper'>
      <p>{selectedOption}</p>
      <CloseIcon onClick={onClear} />
    </div>
  );
};
