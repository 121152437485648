import React from "react";
import "./testimonialsBanner.css";
// import Testimonials1 from '../../../assets/testimonials/121.png';
// import TestiMonials2 from "../../../assets/testimonials/122.jpeg";
// import TestiMonials3  from "../../../assets/testimonials/123.jpg";


const TestimonialsBanner = () => {
  return (
    <div className="testimonialsContainer">
      <div className="testimonialsHeader">
        <div className="quoteIcon">“</div>
        <div className="testimonialsBannerContainerHeader">
          <h1>INFRAMANTRA CLIENT STORIES:</h1>
          <h2>REAL EXPERIENCES, REAL RESULTS!</h2>
        </div>
        <p className="testimonialsDescription">
          Hear from our satisfied clients and discover how we've helped them
          achieve their real estate dreams with our exceptional service and
          expertise.
        </p>
      </div>
      <div className="testimonialsImages">
                <div className="imagePlaceholder">
                  <img src={`https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/banner/123.jpg`} alt="Testimonials 3"/>
                </div>
                <div className="imageRow">
                    <div className="imagePlaceholder">
                       <img src={`https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/banner/121.png`} alt="Testimonials 1"/>
                    </div>
                    <div className="imagePlaceholder">
                      <img src={`https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/banner/122.jpeg`} alt="Tesimonils 2"/>
                    </div>
                </div>
            </div>
    </div>
  );
};

export default TestimonialsBanner;
