import React, { useRef, useState, useEffect } from "react";
import StatisticalInsight from "../../../components/statisticalInsight/statisticalInsight";
import Typography from "@mui/material/Typography";
import { homePageInsightsSection } from "../../../constants/statisticalInsights.constants.js";
import "./statisticalInsightsSection.css";

const statisticalInsightStyles = {
  iconStyles: { fontSize: "40px", color: "#E7B554" },
  textStyles: {
    color: "#fff",
    "@media (max-width:450px)": {
      fontSize: "12px",
      marginTop: "15px",
    },
  },
};

function StatisticalInsightsSection() {
  const [isVisible, setIsVisible] = useState(
    new Array(homePageInsightsSection.length).fill(false)
  );
  const refEntries = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = refEntries.current.findIndex(
              (ref) => ref === entry.target
            );
            setIsVisible((prev) =>
              prev.map((visible, i) => (i === index ? true : visible))
            );
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    refEntries.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      refEntries.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <section className='statisticalInsightsSectionWrapper'>
      <div className='statisticalInsightsSectionGridContainer'>
        {homePageInsightsSection.map((val, index) => (
          <div
            ref={(el) => (refEntries.current[index] = el)}
            className='statisticalInsightsContainer'
            key={index}
          >
            <val.icon sx={statisticalInsightStyles.iconStyles} />
            <StatisticalInsight
              suffix={val.suffix}
              end={val.end}
              duration={val.duration}
              isVisible={isVisible[index]}
            />
            <Typography
              variant='h6'
              gutterBottom
              sx={statisticalInsightStyles.textStyles}
            >
              {val.title}
            </Typography>
          </div>
        ))}
      </div>
    </section>
  );
}

export default StatisticalInsightsSection;
