import axios from 'axios';
import { POST_PROPERTY_LISTING , POST_SEARCH_LOCATIONS} from '../reduxSlices/contants.js';

export const fetchPropertyAll = async (propertyData) => {
  try {
    const response = await axios.post(`${POST_PROPERTY_LISTING}`, propertyData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
}


export const fetchListingSearch = async (propertyData) => {
  try {
    const response = await axios.post(`${POST_SEARCH_LOCATIONS}`, propertyData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};