import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import PropertyListingPageMobileFilterTabs from '../../../components/propertyListingPageMobileFilterTabs/propertyListingPageMobileFilterTabs';
import './propertyListingPageMobileFilter.css';
import PropertyListingPriceFilter from './propertyListingPriceFilter/propertyListingPriceFilter';
import {
  unitTypeFilterConstants,
  configuration,
  status,
} from '../propertyListingConstant';
import Button from '../../../common/button/button';
import { priceRangeData } from '../../../components/searchOptions/dropDownMenuConstants';

function PropertyListingPageMobileFilter({
  toggleDrawer,
  applyFilters,
  currentFilters,
}) {
  const [tempFilters, setTempFilters] = useState(currentFilters);

  const handleChangePriceRange = (newRange) => {
    const startLabel =
      priceRangeData.find((p) => p.value === newRange[0])?.label || '';
    const endLabel =
      priceRangeData.find((p) => p.value === newRange[1])?.label || '';
    const newPriceRange = {
      range: newRange,
      label: `${startLabel} - ${endLabel}`,
    };
    setTempFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: newPriceRange,
    }));
  };

  const handleTabClick = (category, title) => {
    setTempFilters((prevFilters) => {
      const isSelected = prevFilters[category].includes(title);
      return {
        ...prevFilters,
        [category]: isSelected
          ? prevFilters[category].filter((tab) => tab !== title)
          : [...prevFilters[category], title],
      };
    });
  };

  const handleApplyFilters = () => {
    applyFilters(tempFilters); // Apply the filters only when the button is clicked
    toggleDrawer();
  };

  return (
    <div className="propertyListingPageMobileFilterWrapper">
      <div className="propertyListingPageMobileFilterHeader">
        <CloseIcon sx={{ width: '20%' }} onClick={toggleDrawer} />
        <p className="propertyListingPageMobileFilterHeaderValue">Filters</p>
        <p className="propertyListingPageMobileFilterHeaderReset">Reset</p>
        <hr className="propertyListingPageMobileFilterHeaderSwipeBar" />
      </div>
      <div className="propertyListingPageMobileFilterValueWrapper">
        <div className="propertyListingPageMobileFilterUnitType">
          <p className="propertyListingPageMobileFilterUnitTypeHeader">
            Unit Type
          </p>
          <div className="propertyListingMobileFilterFlex">
            {unitTypeFilterConstants.map((unitType) => (
              <PropertyListingPageMobileFilterTabs
                key={unitType}
                title={unitType}
                category="unitType"
                onTabClick={handleTabClick}
              />
            ))}
          </div>
        </div>
        <div className="propertyListingPageMobileFilterUnitType">
          <p className="propertyListingPageMobileFilterUnitTypeHeader">
            Configuration
          </p>
          <div className="propertyListingMobileFilterFlex">
            {configuration.map((config) => (
              <PropertyListingPageMobileFilterTabs
                key={config}
                title={config}
                category="configuration"
                onTabClick={handleTabClick}
              />
            ))}
          </div>
        </div>
        <div className="propertyListingPageMobileFilterUnitType">
          <p className="propertyListingPageMobileFilterUnitTypeHeader">
            Price Range
          </p>
          <div className="propertyListingMobileFilterFlex priceFliterFlex">
            <PropertyListingPriceFilter
              priceRange={tempFilters.priceRange.range}
              onChangePriceRange={handleChangePriceRange}
            />
          </div>
        </div>
        <div className="propertyListingPageMobileFilterUnitType">
          <p className="propertyListingPageMobileFilterUnitTypeHeader">
            Project Status
          </p>
          <div className="propertyListingMobileFilterFlex">
            {status.map((status) => (
              <PropertyListingPageMobileFilterTabs
                key={status}
                title={status}
                category="status"
                onTabClick={handleTabClick}
              />
            ))}
          </div>
        </div>
        <div className="propertyListingMobileFilterFlex priceFliterFlex mobileFilterBtn">
          <Button
            padding="5px 50px"
            onClick={handleApplyFilters}
            btnText="Apply Filters"
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyListingPageMobileFilter;
