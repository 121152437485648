const INFRAMANTRA_BASE_URL = 'https://apitest.inframantra.com/api/v1';

const INFRAMANTRA_LOCAL_URL = 'http://localhost:5001/api/v1'

// Property API
export const GET_INDIVIDUAL_PROPERTY = `${INFRAMANTRA_BASE_URL}/property/get`;

export const POST_PROPERTY_LISTING = `${INFRAMANTRA_BASE_URL}/property/location`;

export const POST_PROPERTY_LISTING_LOCATION = `${INFRAMANTRA_BASE_URL}/property/location`;

export const GET_PROPERTY_BY_NAME = `${INFRAMANTRA_BASE_URL}/property/name`;

export const GET_PROPERTIES_BY_IDS = `${INFRAMANTRA_BASE_URL}/property/wishlist/ids`;

export const GET_FEATURED_PROPERTIES = `${INFRAMANTRA_BASE_URL}/property/featured-properties`;

export const POST_SEARCH_NAMES =  `${INFRAMANTRA_BASE_URL}/property/searchName`;

export const POST_SEARCH_LOCATIONS =  `${INFRAMANTRA_BASE_URL}/property/search`;

export const GET_SEARCH_DATA_CITY = `${INFRAMANTRA_BASE_URL}/property/getSearch`;

export const GET_PROPERTY_BY_SLUG = `${INFRAMANTRA_BASE_URL}/property/slug`;

export const GET_ALL_PROPERTY_DATA = `${INFRAMANTRA_LOCAL_URL}/property/getProjects`;


// Contact API

export const POST_PROJECT_ENQUIRY =  `${INFRAMANTRA_BASE_URL}/enquiry/project`;
export const POST_CONTACT_ENQUIRY =    `${INFRAMANTRA_BASE_URL}/enquiry/contact`
