import React, { useState } from "react";
import PropertyWrapper from "./propertyWrapper";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import SchoolIcon from "@mui/icons-material/School";
import BusinessIcon from "@mui/icons-material/Business";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import FlightIcon from "@mui/icons-material/Flight";
import "./propertySectionStyles.css";

const localityGuideMuiStyles = {
  tabList: {
    width: "60vw",
    "& .MuiTabs-flexContainer": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      overflow: "scroll",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .css-indfgi-MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#DCAA4C",
    },
    "@media (max-width: 768px)": {
      width: "85vw",
    },
  },
  tab: {
    "& .MuiButtonBase": { color: "#C5C5C5" },
    "& .MuiTab-root": { color: "#C5C5C5" },
    "& .Mui-selected": { color: "#DCAA4C" },
    "& .MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#DCAA4C",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.5rem",
    },
  },
};

const getIcon = (title, selected) => {
  const color = selected ? "#DCAA4C" : "#C5C5C5";
  switch (title) {
    case "Schools":
      return <SchoolIcon sx={{ color }} />;
    case "Office Spaces":
      return <BusinessIcon sx={{ color }} />;
    case "Commercial Hubs":
      return <BusinessIcon sx={{ color }} />;
    case "Malls":
      return <StoreMallDirectoryIcon sx={{ color }} />;
    case "Shopping Centers":
      return <StoreMallDirectoryIcon sx={{ color }} />;
    case "Malls and Shopping Centers":
      return <StoreMallDirectoryIcon sx={{ color }} />;
    case "Malls and Markets":
      return <StoreMallDirectoryIcon sx={{ color }} />;
    case "Hospitals":
      return <LocalHospitalIcon sx={{ color }} />;
    case "Entertainment":
      return <SportsEsportsIcon sx={{ color }} />;
    case "Entertainment Zone":
      return <SportsEsportsIcon sx={{ color }} />;
    case "Connectivity":
      return <FlightIcon sx={{ color }} />;
    default:
      return null;
  }
};

function LocalityGuide({ localityGuide = [] }) {
  const [value, setValue] = useState("0");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PropertyWrapper
      id='localityMap'
      children={
        <div className='aboutProjectWrapper'>
          <h4 className='aboutProjectHeader'>Locality Map And Guide</h4>
          <TabContext value={value}>
            <div className='floorPlanTabsContainer'>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
                sx={localityGuideMuiStyles.tabList}
              >
                {localityGuide.map((guide, index) => (
                  <Tab
                    key={index}
                    sx={localityGuideMuiStyles.tab}
                    icon={getIcon(guide.title, value === String(index))}
                    label={guide.title}
                    value={String(index)}
                  />
                ))}
              </TabList>
            </div>
            {localityGuide.map((guide, index) => (
              <TabPanel key={index} value={String(index)}>
                <div className='localityGuideDesignContainer'>
                  {guide.guideList.map((list, idx) => (
                    <div key={idx} className='localityGuideValueFlex'>
                      <p>{list.name}</p>
                      <p>{list.distance}</p>
                    </div>
                  ))}
                </div>
              </TabPanel>
            ))}
          </TabContext>
        </div>
      }
    />
  );
}

export default LocalityGuide;
