export const mapsData = [
  {
    key: 1,
    name: "Vatika Seven Elements",
    lat: 28.428129,
    lng: 76.941117,
  },
  {
    key: 2,
    name: "Tulip Monsella",
    lat: 28.435057,
    lng: 77.103555,
  },
  // {
  //   key: 3,
  //   name: "Sector 89",
  //   lat: 28.41828,
  //   lng: 76.9457,
  // },
  // {
  //   key: 4,
  //   name: "Sector 57",
  //   lat: 28.422,
  //   lng: 77.0795,
  // },
  // {
  //   key: 5,
  //   name: "Sector 66",
  //   lat: 28.3925,
  //   lng: 77.0541,
  // },
];
