import React, { useEffect, useState } from "react";
import PropertyWrapper from "./propertyWrapper";
import useMediaQuery from "../../../utils/useMediaQuery";
import "./propertySectionStyles.css";

function Amenities({ amenities = [], exclusiveAmenities = [] }) {
  const isDesktop = useMediaQuery(768);
  const [premiumAmenities, setPremiumAmenities] = useState(exclusiveAmenities);

  useEffect(() => {
    if (isDesktop) {
      setPremiumAmenities(exclusiveAmenities.slice(0, 5));
    } else {
      setPremiumAmenities(exclusiveAmenities);
    }
  }, [isDesktop, exclusiveAmenities, amenities]);

  return (
    <PropertyWrapper
      id='amenities'
      children={
        <div className='aboutProjectWrapper'>
          <h4 className='aboutProjectHeader'>Amenities</h4>
          <div className='exclusiveAmenitiesIconsWrapper'>
            {premiumAmenities.map((amenity) => {
              return (
                <div className='amenitiesIconFlex' key={amenity.title}>
                  <img
                    style={{ width: isDesktop ? "70px" : "45px" }}
                    src={amenity.iconUrl}
                    alt={amenity.title}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: isDesktop ? "12px" : "10px",
                    }}
                  >
                    {amenity.title}
                  </p>
                </div>
              );
            })}
          </div>
          <div className='amenitiesIconsWrapper'>
            {amenities.map((amenity) => {
              return (
                <div className='amenitiesIconFlex' key={amenity.title}>
                  <img
                    style={{ width: isDesktop ? "70px" : "45px" }}
                    src={amenity.iconUrl}
                    alt={amenity.title}
                  />
                  <p
                    style={{
                      textAlign: "center",
                      margin: 0,
                      fontSize: isDesktop ? "12px" : "10px",
                    }}
                  >
                    {amenity.title}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      }
    />
  );
}

export default Amenities;
