import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import Slider from "@mui/material/Slider";
import useMediaQuery from "../../utils/useMediaQuery";
import { calculateEMI } from "../../utils/emiCalculation";
import "./emiCalculator.css";

const formatAmount = (amount) => {
  if (amount >= 10000000) {
    return (amount / 10000000).toFixed(2) + " Crore";
  } else if (amount >= 100000) {
    return (amount / 100000).toFixed(2) + " Lakh";
  } else {
    return amount.toString();
  }
};

function EmiCalculator({
  initialPrincipal,
  initialTenureYears = 20,
  initialInterestRate = 8.5,
}) {
  const [principal, setPrincipal] = useState(initialPrincipal);
  const [annualInterestRate, setAnnualInterestRate] =
    useState(initialInterestRate);
  const [tenureYears, setTenureYears] = useState(initialTenureYears);
  const [emi, setEmi] = useState(null);
  const [data, setData] = useState([
    { id: 0, value: 1, label: "Interest Amount", color: "#F2A541" },
    { id: 1, value: 15, label: "Principal Amount", color: "#F2E5B3" },
  ]);

  const isDesktop = useMediaQuery(768);

  useEffect(() => {
    const timer = setTimeout(() => {
      const emiValue = calculateEMI(principal, annualInterestRate, tenureYears);
      setEmi(emiValue);

      const totalAmountPaid = emiValue * tenureYears * 12;
      const totalInterestPaid = totalAmountPaid - principal;

      setData([
        {
          id: 0,
          value: totalInterestPaid,
          label: "Interest Amount",
          color: "#FC2E20",
        },
        {
          id: 1,
          value: principal,
          label: "Principal Amount",
          color: "#FFBF00",
        },
      ]);
    }, 300);

    return () => clearTimeout(timer);
  }, [principal, annualInterestRate, tenureYears]);

  useEffect(() => {
    const updateLeftPosition = () => {
      const width = window.innerWidth;
      let leftPercent = 52;

      if (width >= 1024) {
        leftPercent = 55 - Math.floor((width - 1024) / 100);
        leftPercent = Math.max(leftPercent, 52);
      }

      document.documentElement.style.setProperty(
        "--emi--text--left",
        `${leftPercent}%`
      );
    };

    updateLeftPosition();

    window.addEventListener("resize", updateLeftPosition);

    return () => window.removeEventListener("resize", updateLeftPosition);
  }, []);

  return (
    <div className='emiCalculatorWrapper'>
      <div className='emiChartContainer'>
        <PieChart
          series={[
            {
              data,
              highlightScope: { faded: "global", highlighted: "item" },
              innerRadius: 70,
              outerRadius: 140,
            },
          ]}
          height={300}
          width={400}
          slotProps={{
            legend: { hidden: true },
          }}
          sx={{ marginLeft: "100px" }}
        />
        <div className='centered-text'>
          &#8377; {emi ? `${emi}` : ""}
          <span style={{ margin: "5px", fontWeight: "200" }}> / month</span>
        </div>
        <div className='label-container-interest'>
          <div className='line-part horizontal'></div>
          <div className='line-part angled'></div>
          <div className='label-text'>Interest</div>
        </div>
        <div className='label-container-principal'>
          <div className='line-part horizontal'></div>
          <div className='line-part angled'></div>
          <div className='label-text-principal'>Principal</div>
        </div>
      </div>

      <div className='emiChartCalculatorValuesFlex'>
        <div>
          <Slider
            size='small'
            value={principal}
            min={100000}
            max={200000000}
            step={10000}
            aria-label='Loan Amount'
            valueLabelDisplay='auto'
            valueLabelFormat={formatAmount}
            sx={{
              color: "#e7b554",
              width: isDesktop ? "25vw" : "80vw",
            }}
            onChange={(e, value) => setPrincipal(value)}
          />
          <p className='emiLoanValueHnadlerValues'>Loan Amount</p>
        </div>
        <div>
          <Slider
            size='small'
            value={tenureYears}
            min={1}
            max={30}
            step={1}
            aria-label='Tenure'
            valueLabelDisplay='auto'
            sx={{
              color: "#e7b554",
              width: isDesktop ? "25vw" : "80vw",
            }}
            onChange={(e, value) => setTenureYears(value)}
          />
          <p className='emiLoanValueHnadlerValues'>Tenure</p>
        </div>
        <div>
          <Slider
            size='small'
            value={annualInterestRate}
            min={1}
            max={15}
            step={0.1}
            aria-label='Interest Rate'
            valueLabelDisplay='auto'
            sx={{
              color: "#e7b554",
              width: isDesktop ? "25vw" : "80vw",
            }}
            onChange={(e, value) => setAnnualInterestRate(value)}
          />
          <p className='emiLoanValueHnadlerValues'>Interest Rate</p>
        </div>
      </div>
    </div>
  );
}

export default React.memo(EmiCalculator);
