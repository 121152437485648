import axios from 'axios';
import { GET_FEATURED_PROPERTIES } from '../reduxSlices/contants.js';

export const fetchFeaturedProperties = async () => {
  try {
    const response = await axios.get(`${GET_FEATURED_PROPERTIES}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};
