import axios from 'axios';
import { POST_PROJECT_ENQUIRY } from '../reduxSlices/contants';
import { POST_CONTACT_ENQUIRY } from '../reduxSlices/contants';


export const postProjectEnquiry = async (enquiryData) => {
    try {
      const response = await axios.post(POST_PROJECT_ENQUIRY, enquiryData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error fetching property data');
    }
};

export const postContactEnquiry = async (enquiryData) => {
  try {
    const response = await axios.post(POST_CONTACT_ENQUIRY, enquiryData);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error Posting Contact Enquiry');
  }
};