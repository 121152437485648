import React, { useState, useRef, useEffect } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Paper } from '@mui/material';
import '../propertyPage.css';

const featuredPropertiesMobileStyles = {
  paper: {
    height: '56%',
    width: '100%',
    background: '#FFF',
    position: 'relative',
  },
  crossIcon: {
    color: '#fff',
    position: 'absolute',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
};

function PropertyMobileHeaderImg({ imageGallery = [] }) {
  return <CardBackComponent imageGallery={imageGallery} />;
}

export default PropertyMobileHeaderImg;

const CardBackComponent = ({ imageGallery = [] }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const carouselTrackRef = useRef(null);
  const autoplayIntervalRef = useRef(null);

  useEffect(() => {
    const track = carouselTrackRef.current;
    if (track) {
      track.style.transform = `translateX(-${
        currentImageIndex * track.offsetWidth
      }px)`;
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const autoplay = () => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % imageGallery.slice(0, 5).length
      );
    };

    autoplayIntervalRef.current = setInterval(autoplay, 2000);

    return () => clearInterval(autoplayIntervalRef.current);
  }, [imageGallery]);

  return (
    <Paper elevation={1} sx={featuredPropertiesMobileStyles.paper}>
      <div className="propertiesPageMobileCrousel">
        <div className="carouselFeaturedPropertyPage">
          <div className="carousel-track" ref={carouselTrackRef}>
            {imageGallery.slice(0, 5).map((img, index) => (
              <img
                key={index}
                className="carousel-slidePropertyPage"
                src={img.url}
                alt={`Featured Property ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="propertyPageMobileWishlistWrapper">
        <FavoriteBorderIcon sx={featuredPropertiesMobileStyles.crossIcon} />
      </div>
    </Paper>
  );
};
