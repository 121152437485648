import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  fetchListingSearch } from '../services/propertyAllServices';


export const fetchSearchAll = createAsyncThunk(
    'property/getPropertyAll',
    async (propertyData, { rejectWithValue }) => {
        // console.log(propertyData);
      try {
        const property = await fetchListingSearch(propertyData);
        return property;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );


  const searchPropertyAllSlice = createSlice({
    name: 'searchPropertyAll',
    initialState: {
      searchProperty: null,
      loading: false,
      error: null,
    },
    reducers: {
        clearPropertyAll: (state) => {
        state.property = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchSearchAll.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchSearchAll.fulfilled, (state, action) => {
          state.loading = false;
          state.searchProperty = action.payload;
        })
        .addCase(fetchSearchAll.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { clearPropertyAll } = searchPropertyAllSlice.actions;
  
  export default searchPropertyAllSlice.reducer;