export const journeyData = [{
    year:'2017',
    firstImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2017-1.jpg',
    secondImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2017-2.jpeg',
    content:`INFRAMANTRA was established in Gurgaon with a team of 9 dedicated professionals collaborating with 9 prominent developers. Starting with a bang, we interacted with around 500 customers within our first year, resulting in business worth over 45 Cr. Our commitment to excellence and customer satisfaction quickly set us apart in the competitive real estate market. By focusing on personalized service and innovative solutions, we have built a strong foundation of trust and reliability. Our success story is a testament to our team's hard work and the unwavering support of our clients.`,
    header:'Journey Of Inframantra'
},
{
    year:'2019',
    firstImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2019-1.jpg',
    secondImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2019-2.jpg',
    content:`Developing a scalable marketing strategy,in 2019 we grew our team to 35 professionals working with 21 renowned developers of Gurgaon resulting in sales worth over 150 CR. In the process we touched base with 5000 customers across Delhi/NCR.`,
    header:'Journey Of Inframantra'
},
{
    year:'2022',
    header:'Journey Of Inframantra',
    firstImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2022-1.jpg',
    secondImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2022-2.jpeg',
    content:`We expanded to Noida,Delhi & Pune partnering with 50+ developers. Our team of 140+ professionals reached out to over 25000 customers generating a sales volume of 450 Cr.`
},
{
    year:'2023',
    firstImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2023-1.jpeg',
    header:'Croosed 750cr. Milestone',
    secondImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2023-2.jpeg',
    content:`InfraMantra has achieved many milestones along the way. Our organization has increased the volume and partnership of 70+ developers with the help of our team of 170+ professionals. we have also reached out to 35,000+ customers and have completed transactions worth Rs.650+ Cr.`
},
{
    year:'2024',
    firstImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2024-1.jpeg',
    header:'Journey Of Inframantra',
    secondImg:'https://inframantra.blr1.cdn.digitaloceanspaces.com/aboutUsPage/Milestones/2024-2.jpg',
    content:`InfraMantra signed the musical sensation, Mr. Guru Randhawa, as the new face. Guru’s excellence and forward thinking aligns with InfraMantra which makes home buying simple and transparent.`
},
]

export const OwnersData = {
    shiwnag:'With a career of over 10 years across industries, Shiwang Suraj has served many reputed Companies by applying his constructive approach in sales, operations and marketing field. He started his entrepreneurial journey as the Co-founder of Zapplon in the year 2015, with the aim of bringing a new dimension to the transport industry, thereby providing value to companies through this function. His entrepreneurial spirit shone brightly once more in 2017 when he founded yet another successful venture, InfraMantra India Pvt Ltd, specializing in the real estate market.',
    garvit:'With over 14 years of experience across banking & finance industries and a Gold medal in IT, Garvit has significantly contributed to several renowned companies through his expertise in finance, marketing, and IT. He has worked with major banks, including HDFC Bank, IndusInd Bank, Yes Bank, and Kotak Bank, enhancing their corporate banking, wealth, and division management. In 2017, Garvit embarked on an entrepreneurial journey, co-founding InfraMantra India Pvt Ltd, a successful company specializing in the real estate market.'
}

export const awardsData = [
    {
        title:'Certificate of Appreciation',
        img:'https://suncityproject.com/vatsalvalley/img/logo-dark.png'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://ambiencegroup.in/wp-content/uploads/2016/08/ambienceLogo.png'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/whitelandcorporation/whitelandcorporation.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/dlfhomedeveloperslimited/dlfhomedeveloperslimited.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/bptplimited/bptplimited.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/godrejproperties/godrejproperties.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/tulipinfratech/tulipinfratech.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/krisumicorporation/krisumicorporation.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/eldecogroup/eldecogroup.avif'
    },
     {
        title:'Certificate of Appreciation',
        img:'https://inframantra.blr1.cdn.digitaloceanspaces.com/developer/birlaestates/birlaestates.avif'
    },
]