import React, { useEffect, useState, useRef } from 'react';
import './navDropdown.css';

function NavDropdown({
  bgd = false,
  currentlySelectedCity,
  title,
  children,
  width,
  marginLeft,
}) {
  const [openDropDown, setOpenDropDown] = useState(false);
  const [currentVal, setCurrentVal] = useState(title || 'Select');
  const wrapperRef = useRef(null);

  const handleNavDropdownClick = () => {
    setOpenDropDown((prev) => !prev);
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleCityChange = (newCity) => {
    setCurrentVal(newCity);
    if (currentlySelectedCity) {
      currentlySelectedCity(newCity);
    }
    setOpenDropDown(false);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className="navDropdownWrapper"
      onClick={handleNavDropdownClick}
      style={{
        backgroundColor: bgd ? '#E7B554' : '#FFF',
        color: bgd ? '#FFF' : '#000',
        width: width,
        marginLeft: marginLeft,
      }}
      ref={wrapperRef}
    >
      {currentVal}
      {openDropDown && (
        <div
          className="listingNavDropdownContentWrapper"
          onClick={stopPropagation}
        >
          {React.cloneElement(children, { handleCityChange })}
        </div>
      )}
    </div>
  );
}

export default NavDropdown;
