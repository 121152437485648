import React from 'react';
import Slider from '@mui/material/Slider';
import { priceRangeData } from '../../../../components/searchOptions/dropDownMenuConstants';
import '../propertyListingPageMobileFilter.css';

export const valuetext = (value) => {
  if (value >= 10000000) {
    return `${value / 10000000} Cr.`;
  } else if (value >= 100000) {
    return `${value / 100000} Lacs`;
  } else {
    return `${value}`;
  }
};

function PropertyListingPriceFilter({ priceRange, onChangePriceRange }) {
  const handleChange = (event, newValue) => {
    if (newValue[0] > newValue[1]) {
      newValue[0] = newValue[1];
    }
    onChangePriceRange(newValue);
  };

  return (
    <div className="filtersPriceRangeWrapper">
      <Slider
        getAriaLabel={() => 'Indian Rupees Range'}
        value={priceRange}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={10000000}
        marks={priceRangeData}
        min={10000000}
        max={70000000}
        size="small"
        sx={{
          color: '#0b6e21;',

          '& .MuiSlider-thumb': {
            backgroundColor: '#0b6e21;',
          },
          '& .MuiSlider-track': {
            backgroundColor: '#0b6e21;',
          },
          '& .MuiSlider-rail': {
            backgroundColor: '#337ab7 !important',
          },
        }}
      />
    </div>
  );
}

export default PropertyListingPriceFilter;
