import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleWishlist } from "./wishlistSlice";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";

const wishlistIconStyle = {
  notClicked: {
    opacity: 0.5,
    cursor: "pointer",
  },
  clicked: {
    opacity: 1,
    cursor: "pointer",
    color: "red",
  },
};

function Wishlist({ property }) {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist);
  const isWishlisted = wishlist.includes(property);

  const handleWishlistClick = () => {
    dispatch(toggleWishlist(property));
  };

  return (
    <>
      {isWishlisted ? (
        <FavoriteIcon
          sx={wishlistIconStyle.clicked}
          onClick={handleWishlistClick}
        />
      ) : (
        <FavoriteBorderIcon
          sx={wishlistIconStyle.notClicked}
          onClick={handleWishlistClick}
        />
      )}
    </>
  );
}

export default Wishlist;
