import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import FormatQuoteSharpIcon from '@mui/icons-material/FormatQuoteSharp';
import useMediaQuery from '../../../utils/useMediaQuery';
import './meetTheTeam.css';

const quoteIcon = {
  transform: 'rotate(180deg)',
  fontSize: '80px',
  color: `rgba(231, 181, 84, 0.56)`,
  '@media (max-width:768px)': {
    fontSize: '40px',
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
            className="meetTheTeamDescription"
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const CustomTabs = styled(Tabs)(({ theme }) => ({
  borderRight: `none`,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    color: '#E7B554',
  },
}));

export default function MeetTheTeam() {
  const [value, setValue] = React.useState(0);
  const isDesktop = useMediaQuery(768);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="meetTheTeamHeaderflex">
        <h4>Meet The Team</h4>
      </div>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          height: isDesktop ? '60vh' : 'max-content',
          width: isDesktop ? '90vw' : '100vw',
          flexDirection: isDesktop ? 'row' : 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isDesktop ? 'column' : 'row',
            width: '35vw',
            marginTop: '2%',
          }}
        >
          <CustomTabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
          >
            <CustomTab label="Sales" {...a11yProps(4)} />
            <CustomTab label="Marketing" {...a11yProps(1)} />
            <CustomTab label="Operations"  {...a11yProps(0)} />
            <CustomTab label="Finance" {...a11yProps(6)} />
            <CustomTab label="HR" {...a11yProps(5)} />
            <CustomTab label="IT Software" {...a11yProps(2)} />
            {/* <CustomTab label="Logistics" {...a11yProps(3)} /> */}
            <CustomTab label="MRC" {...a11yProps(3)} />
          </CustomTabs>
        </Box>
        <div className="meetTeamDescriptionQuotesWrapper">
          <FormatQuoteSharpIcon sx={quoteIcon} />
        </div>
        <Box
          sx={{
            flexGrow: 1,
          }}
        > 
        {/* Sales */}
        <TabPanel value={value} index={0}>
            <p className='teamDescription'>
              Our Sales Team are experienced professionals with deep knowledge
              of our properties and the market, committed to understanding your
              unique needs and preferences. By providing personalized
              consultations and expert guidance, they ensure you find the
              perfect property.
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/3.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
          {/* Marketing */}
          <TabPanel value={value} index={1}>
            <p className='teamDescription'>
              Our Marketing Team is the creative force that connects us with our
              clients. Their innovative approach ensures our brand stands out in
              a competitive market, reaching the right audience with the right
              message. From digital marketing to traditional advertising, our
              team employs a mix of tactics to generate buzz and drive
              engagement.
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/marketing%20team.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
        {/* Operations */}
          <TabPanel value={value} index={2}>
            <p className='teamDescription'>
              Our Operations team ensures a seamless transition from purchase to
              possession, providing continuous support every step of the way.
              Our goal is to build lasting relationships with our clients,
              ensuring they feel valued and supported long after they made the
              purchase.
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/operations%20team.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
          {/* Finance */}
          <TabPanel value={value} index={3}>
            <p className='teamDescription'>
              Our Finance Department is a group of skilled professionals who
              manage our company's financial health with precision and
              integrity. They are focused on ensuring fiscal responsibility,
              optimizing resources, and supporting sustainable growth, while
              aligning with our overall business goals.
            </p>
            {/* https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/finance%20team.avif */}
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/finance%20team.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
          {/* IT Software */}
          <TabPanel value={value} index={5}>
            <p className='teamDescription'>
              Our IT Software Team is the technological backbone that drives
              innovation and efficiency. They develop and maintain user-friendly
              digital infrastructure, ensuring seamless experiences for our
              clients and team. By integrating the latest technologies they keep
              us ahead in the competitive real estate market.
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/marketing%20team2.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
          {/* MRC */}
          <TabPanel value={value} index={6}>
            <p className='teamDescription'>
              Our MRC Team provide detailed information, answer queries, and
              offer personalized consultations to help clients make informed
              decisions. With in-depth knowledge of our properties and the
              market, they highlight the unique features and benefits of each
              project, ensuring that clients find the perfect fit for their
              needs.
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/mrc%20team.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
          {/* HR */}
          <TabPanel value={value} index={4}>
            <p className='teamDescription'>
              Our HR Team consists of dedicated professionals with a deep
              understanding of our company culture and employee needs. They are
              committed to fostering a supportive and inclusive environment,
              ensuring that each team member is valued and empowered.{' '}
            </p>
            <div className="teamPhotoAboutUsWrapper">
              <img
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/teamPhotos/4.avif"
                alt="team-photo"
                className="teamPhotoAboutUs"
              />
            </div>
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}
