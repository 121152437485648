import React from "react";
import { Paper, Rating, Box, Typography } from "@mui/material";
import './testimonialsCard.css'

const testimonialCardStyles = {
  paper: {
    height: "274px",
    width: "85%",
    borderRadius: "20px",
    background: "rgba(237, 192, 107, 0.26)",
    marginBottom: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "20px",
    "@media (max-width:768px)": {
      width: "74%",
      height: "auto",
      margin: "10px auto",
      boxShadow: "0px 1.411px 3.175px 0px rgba(0, 0, 0, 0.25)",
    },
  },
  rating: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#E4A951",
    fontFamily: "Lexend Deca",
    "@media (max-width:768px)": {
      fontSize: "150%",
    },
  },
  quoteIcon: {
    color: "#E4A951",
    fontSize: "2rem",
    marginBottom: "10px",
  },
  text: {
    marginTop: "10px",
    textAlign: "center",
  },
};

function TestimonialCard({ name, rating, text, key }) {
  return (
    <Paper key={key} elevation={8} sx={testimonialCardStyles.paper}>
      <div className="testimonialCardNameContainer">
        <Typography variant="h6" textAlign="left" className="testimonialCardName">
          {name}
        </Typography>
      </div>
      <div className="testimonialCardRatingContainer">
        <Rating value={5} readOnly sx={testimonialCardStyles.rating} />
      </div>
      <div className="testimonialCardTextContainer">
         <span className="testimonialCardQuoteIcon" sx={testimonialCardStyles.quoteIcon}>“</span>
        <Typography variant="body1" className="testimonialCardText" sx={testimonialCardStyles.text}>
          {text}
        </Typography>
      </div>
    </Paper>
  );
}

export default TestimonialCard;