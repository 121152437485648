import React from "react";
import ReactPlayer from "react-player";
import PropertyWrapper from "./propertyWrapper";
import useMediaQuery from "../../../utils/useMediaQuery";

function PropertyVideo() {
  const isDesktop = useMediaQuery(768);

  return (
    <>
      {isDesktop && (
        <div className='propertyVideoWrapper'>
          <ReactPlayer
            url={`https://www.youtube.com/watch?v=qkVm2pUXGQM&ab_channel=INFRAMANTRA`}
            width='100%'
            height='100%'
            controls={true}
          />
        </div>
      )}
      {!isDesktop && (
        <PropertyWrapper
          children={
            <div className='aboutProjectWrapper'>
              <h4 className='aboutProjectHeader'>Property Video</h4>
              <div className='propertyVideoWrapper'>
                <ReactPlayer
                  url={`https://www.youtube.com/watch?v=qkVm2pUXGQM&ab_channel=INFRAMANTRA`}
                  width='100%'
                  height='100%'
                  controls={true}
                />
              </div>
            </div>
          }
        />
      )}
    </>
  );
}

export default PropertyVideo;
