import { useState, useEffect } from "react";

function useMediaQuery(breakpoint) {
  const [matches, setMatches] = useState(window.innerWidth > breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setMatches(window.innerWidth > breakpoint);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint]);

  return matches;
}

export default useMediaQuery;
