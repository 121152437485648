import React, { useState, useEffect } from 'react';
import FeaturedProperties from '../../../components/featuredProperties/featuredProperties';
import PaginationSlider from '../../../components/paginationSlider/paginationSlider';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CSSTransition } from 'react-transition-group';
import useMediaQuery from '../../../utils/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import FeaturedPropertiesMobileSection from './featuredPropertiesMobile/featuredPropertiesMobile';
import './featuredPropertiesSection.css';
import { getFeaturedProperties } from '../../../reduxSlices/propertySlice';
import { useSelector, useDispatch } from 'react-redux';

const featuredPropertiesStyles = {
  headerIcon: {
    '@media (max-width:550px)': {
      fontSize: '0.6rem',
    },
  },
};

function FeaturedPropertiesSection() {
  const isDesktop = useMediaQuery(800);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [inProp, setInProp] = useState(true);
  const [propertyIn, setPropertyIn] = useState(true);

  const featuredProperties = useSelector((state) => state.property.featuredProperties);

  useEffect(() => {
    dispatch(getFeaturedProperties());
  }, [dispatch]);

  useEffect(() => {
    if (featuredProperties && featuredProperties.length > 0) {
      setCurrentCityIndex(0); // Set initial city index
      setCurrentIndex(0); // Set initial property index
    }
  }, [featuredProperties]);

  useEffect(() => {
    setPropertyIn(false);
    const timeout = setTimeout(() => {
      setPropertyIn(true);
    }, 0);
    return () => clearTimeout(timeout);
  }, [currentIndex]);

  const handleNextCity = () => {
    setInProp(false);
  };

  const handleExited = () => {
    const nextIndex = (currentCityIndex + 1) % featuredProperties.length;
    setCurrentCityIndex(nextIndex);
  };

  useEffect(() => {
    setCurrentIndex(0);
    const timeoutId = setTimeout(() => {
      setInProp(true);
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [currentCityIndex]);

  if (!featuredProperties || featuredProperties.length === 0) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  const currentCityData = featuredProperties[currentCityIndex];
  const currentProperty = currentCityData.properties[currentIndex];

  // console.log("Current Property", currentProperty);

  return (
    <div className="featuredPropertiesSectionWrapper">
      {isDesktop && (
        <div className="featuredPropertiesSectionHeader">
          <p className="featuredPropertiesSectionHeaderTitle">
            Premium Listings
          </p>
          <p className="featuredPropertiesSectionHeaderCityName">
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/property-listing')}
            >
              {currentCityData.city}
            </span>
            <span
              className="featuredPropertiesSectionHeaderCityNameIcon"
              onClick={handleNextCity}
            >
              <ArrowForwardIosIcon sx={featuredPropertiesStyles.headerIcon} />
            </span>
          </p>
        </div>
      )}
      {isDesktop && (
        <CSSTransition
          in={inProp}
          timeout={500}
          classNames="slide"
          key={currentCityIndex}
          onExited={handleExited}
          unmountOnExit
        >
          <CSSTransition
            in={propertyIn}
            timeout={300}
            classNames="fade"
            key={currentIndex}
            unmountOnExit
          >
            <FeaturedProperties
              title={currentProperty.title}
              location={currentProperty.location}
              description={currentProperty.description}
              secondDescription={currentProperty.description}
              price={
                currentProperty.numericInsights.find(
                  (ni) => ni.title === 'Starting Price'
                ).value
              }
              area={
                currentProperty.numericInsights.find(
                  (ni) => ni.title === 'Sq feet'
                ).value
              }
              configurations={
                currentProperty.numericInsights.find(
                  (ni) => ni.title === 'Configurations'
                ).value
              }
              imageGallery={currentProperty.images}
              exclusive={currentProperty.exclusive}
              id={currentProperty.slug}
            />
          </CSSTransition>
        </CSSTransition>
      )}
      {!isDesktop && <FeaturedPropertiesMobileSection />}
      {isDesktop && (
        <div className="featuredPropertiesSectionSlider">
          <PaginationSlider
            totalItems={currentCityData.properties.length}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
          />
        </div>
      )}
    </div>
  );
}

export default FeaturedPropertiesSection;
