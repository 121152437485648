import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { searchNames } from '../services/search.service';


export const fetchSearchResults = createAsyncThunk(
    'property/getPropertyById',
    async (searchTerms, { rejectWithValue }) => {
      // console.log(searchTerms);
      try {
        const property1 = await searchNames({ names: searchTerms });
        const propertyData = property1.data;
        return propertyData;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    results: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchResults.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.results = action.payload;
      })
      .addCase(fetchSearchResults.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default searchSlice.reducer;
