import axios from 'axios';
import { GET_INDIVIDUAL_PROPERTY } from '../reduxSlices/contants.js';


export const fetchPropertyById = async (propertyId) => {
  try {
    const response = await axios.get(`${GET_INDIVIDUAL_PROPERTY}/${propertyId}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};

// export const fetchPropertyByIdPdf = async (propertyId) => {
//   try {
//     var url = encodeURI('https://inframantra.blr1.cdn.digitaloceanspaces.com/brochure/vatikasevenelements/vatikasevenelements.pdf')
//     const response = await axios.get(`${url}`,{headers: {"Access-Control-Allow-Credentials": true, "Access-Control-Allow-Origin": '*',}});
//     return response.data;
//   } catch (error) {
//     throw new Error(error.response?.data?.message || 'Error fetching property data');
//   }
// };
