import React, { useState, useEffect } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DropDownMenu from '../../components/dropdownMenu/dropDownMenu';
import CustomizedHook from '../../common/searchAutocomplete/searchAutocomplete';
import useMediaQuery from '../../utils/useMediaQuery';
import Button from '../../common/button/button';
import {
  LocationDropDownContent,
  PropertyTypeDropDownContent,
  PriceRangeDropDownContent,
} from './dropDownMenuComponents';
import { priceRangeData } from './dropDownMenuConstants';
import './searchOptions.css';
import { useNavigate } from 'react-router-dom';
import useSearchData from './userSearchData';
import { getPropertyByName } from '../../reduxSlices/propertySlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchAll } from '../../reduxSlices/searchListingSlice';
import { fetchListingSearchNames } from '../../reduxSlices/listingSearchSlice';


function SearchOptions() {
  const { searchData, updateSelections } = useSearchData();
  const { selections } = searchData;
  const dispatch = useDispatch();
  const [location, setLocation] = useState('Location');
  const [selectedPropertyType, setSelectedPropertyType] = useState('Property');
  const [checkedUnits, setCheckedUnits] = useState({});
  const [priceRange, setPriceRange] = useState({
    range: [10000000, 40000000],
    label: 'Price',
  });
  const [isPropertyDropdownOpen, setPropertyDropdownOpen] = useState(false);
  const [autocompleteSelections, setAutocompleteSelections] = useState([]);
  const isDesktop = useMediaQuery(768);
  const navigate = useNavigate();

  const togglePropertyDropdown = () => {
    const newState = !isPropertyDropdownOpen;
    setPropertyDropdownOpen(newState);
  };

  const handlePropertyTypeValue = (propertyType) => {
    setSelectedPropertyType(propertyType);
  };

  const handleChangePriceRange = (newRange) => {
    const startLabel =
      priceRangeData.find((p) => p.value === newRange[0])?.label || '';
    const endLabel =
      priceRangeData.find((p) => p.value === newRange[1])?.label || '';
    setPriceRange({
      range: newRange,
      label: `${startLabel} - ${endLabel}`,
    });
  };

  useEffect(() => {
    if (autocompleteSelections.length === 1 && autocompleteSelections[0].title) {
      dispatch(getPropertyByName(autocompleteSelections[0].title));
    }
  }, [autocompleteSelections, dispatch]);

  const fetchedProperty = useSelector((state) => state.property.property);

  const buildSearchParams = () => {
    const searchParams = {};

    if (location !== 'Location') {
      searchParams.city = location;
    }

    if (selectedPropertyType !== 'Property') {
      if(selectedPropertyType){
        searchParams.propertyType = { title: selectedPropertyType };
      }else{
        setSelectedPropertyType('Property')
      }
    }
  
    const subTypes = Object.keys(checkedUnits).flatMap(type =>
      Object.keys(checkedUnits[type]).filter(subType => checkedUnits[type][subType])
    );
  
    if (subTypes.length > 0) {
      searchParams.subType = subTypes;
    } else {
      // Optionally, reset or clear subType-related states here if needed
    }

    if (priceRange.range) {
      searchParams.priceRange = priceRange.range;
    }

    searchParams.limit = 10; // or any logic to handle limit
    searchParams.page = 1; // or any logic to handle page

    // console.log("Auto Complete",autocompleteSelections);
    return searchParams;
  };

  useEffect(() => {
  const searchParams = buildSearchParams();
  // Check if both location and selectedPropertyType are defined and not empty
  if (location && selectedPropertyType  && Object.keys(searchParams).length >= 1) {
    dispatch(fetchListingSearchNames(searchParams));
  }
}, [location, selectedPropertyType, checkedUnits, priceRange]);

// console.log("Auto Complete Options",autocompleteSelections.length, fetchedProperty);

const handleSearch = () => {
  // Check if both location and property type are selected
  if (location !== 'Location' && selectedPropertyType !== 'Property') {
    // Construct the URL or use navigate state
    navigate('/listing', {
      state: { 
        location, 
        propertyType: selectedPropertyType 
      }
    });
  } else if (autocompleteSelections.length > 0) {
    if (
      autocompleteSelections[0].type === 'property' && autocompleteSelections.length === 1 
    ) {
      if (fetchedProperty && fetchedProperty._id) {
        navigate(`/property/${fetchedProperty.slug}`);
      }else if(fetchedProperty && fetchedProperty.projectList && fetchedProperty.projectList[0]._id){
        navigate(`/property/${fetchedProperty.projectList[0].slug}`);
      }
    } else {
      navigate('/listing', { state: { selections } });
    }
  } else {
    if (location === 'Location') return;
    else navigate(`/property-listing/city/${location}`);
  }
};

  return (
    <>
      {isDesktop && (
        <div className="searchOptionsWrapper">
          <div className="searchOptionsFlexContainer">
            <DropDownMenu
              Icon={LocationOnIcon}
              selectTitle={location}
              dropDownContent={
                <LocationDropDownContent
                  selectedLocation={(val) => setLocation(val)}
                />
              }
            />
            <CustomizedHook
              onSearch={setAutocompleteSelections}
              location={location}
              handleSearch={handleSearch}
            />
            <DropDownMenu
              Icon={HomeIcon}
              selectTitle={selectedPropertyType !== 'Property' ? selectedPropertyType : 'Property'}
              toggleDropdown={togglePropertyDropdown}
              dropDownContent={
                <PropertyTypeDropDownContent
                  propertyTypeValue={handlePropertyTypeValue}
                  selectedPropertyType={selectedPropertyType}
                  setSelectedPropertyType={setSelectedPropertyType}
                  checkedUnits={checkedUnits}
                  setCheckedUnits={setCheckedUnits}
                  isDropdownOpen={isPropertyDropdownOpen}
                />
              }
            />
            <DropDownMenu
              Icon={LocalOfferIcon}
              selectTitle={priceRange.label}
              dropDownContent={
                <PriceRangeDropDownContent
                  priceRange={priceRange.range}
                  onChangePriceRange={handleChangePriceRange}
                />
              }
            />
            <Button
              width="14%"
              otherStyles={{ height: '50px', fontSize: '22px' }}
              onClick={handleSearch}
            />
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className="searchOptionsWrapper">
          <CustomizedHook
            onSearch={setAutocompleteSelections}
            location={location}
            handleSearch={handleSearch}
          />
          <div className="searchOptionsMobileFlexContainer">
            <DropDownMenu
              Icon={LocationOnIcon}
              selectTitle={location}
              dropDownContent={
                <LocationDropDownContent
                  selectedLocation={(val) => setLocation(val)}
                />
              }
            />
            <DropDownMenu
              Icon={HomeIcon}
              selectTitle={selectedPropertyType !== 'Property' ? selectedPropertyType : 'Property'}
              toggleDropdown={togglePropertyDropdown}
              dropDownContent={
                <PropertyTypeDropDownContent
                  propertyTypeValue={handlePropertyTypeValue}
                  selectedPropertyType={selectedPropertyType}
                  setSelectedPropertyType={setSelectedPropertyType}
                  checkedUnits={checkedUnits}
                  setCheckedUnits={setCheckedUnits}
                  isDropdownOpen={isPropertyDropdownOpen}
                />
              }
            />
            <DropDownMenu
              Icon={LocalOfferIcon}
              selectTitle={priceRange.label}
              dropDownContent={
                <PriceRangeDropDownContent
                  priceRange={priceRange.range}
                  onChangePriceRange={handleChangePriceRange}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default SearchOptions;
