import React, {useState, useEffect} from "react"
// import Wrapper from "../components/UI/Wrapper"
import PageHeader from "./blogComponents/pageHeader/PageHeader.jsx"
import FeaturedBlogs from "./blogComponents/featuredBlogs/FeaturedBlogs.jsx"
import BlogsGrid from "./blogComponents/blogsGrid/BlogsGrid.jsx"
import { allData } from "./blogData.js"
import './blogPage.css'
import Wrapper from "../../components/UI/Wrapper.jsx"

function blogPage({}) {
    // const [pagination, setPagination] = useState(1)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const[posts, setPosts] = useState(allData.result.latestBlogList)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const[initPost, setInitPost] = useState(allData.result.latestBlogList)
    const data = {
        image : allData.result.meta[0].file.path,
        title : allData.result.meta[0].title,
    }

    // console.log(allData.result.meta[0].file.path)
    
    // const loadMoreBlogs = async() => {
    //     const newBlogs = await Ajax({
    //         url: `/blog/pageDetail?type=latest&limit=9&pagination=${pagination}`,
    //         loader: true
    //     })
    //     if(newBlogs.data.status === 'SUCCESS!'){
    //         setPagination(pagination + 1)
    //         setInitPost(newBlogs.data.result.latestBlogList)
    //         setPosts([...posts, ...newBlogs.data.result.latestBlogList])
    //     }
    // }
     
  return (
    <Wrapper>
        <PageHeader data={data} />
        <FeaturedBlogs blogs={allData.result.featureBlogList} />
        <BlogsGrid blogs={posts} section_title="Latest Blogs"  initial={initPost} />
    </Wrapper>
  )
}

// export async function getStaticProps() {
//   const res = await fetch(`${process.env.apiUrl}/blog/pageDetail?limit=all`)
//   const data = await res.json()

//   const featuredData = data.result.featureBlogList
//   const featuredDataArray = []
//   featuredData.forEach(function(f){
//     featuredDataArray.push({
//       "id": f._id,
//       "title": f.name,
//       "date": moment(f.createdAt).format('DD MMM, YYYY'),
//       ...(f.file && {"image": f.file.path}),
//       "slug": f.slug
//     })
//   })

//   const latestData = data.result.latestBlogList
//   const latestDataArray = []
//   latestData.forEach(function(l){
//     latestDataArray.push({
//       "_id": l._id,
//       "name": l.name,
//       "createdAt": l.createdAt,
//       ...(l.file && {"file": l.file}),
//       "slug": l.slug
//     })
//   })

//   const meta = {
//     "bannerTitle": data.result.meta[0].title,
//     "meta_title": data.result.meta[0].meta_title,
//     "meta_description": data.result.meta[0].meta_description,
//     "meta_keyword":data.result.meta[0].meta_keyword,
//     "bannerImage": data.result.meta[0].file.path
// }

//   const allData = {
//       "meta": meta,
//       "featured": featuredDataArray,
//       "latest": latestDataArray
//   }
//   return {
//     props: {
//       allData,
//     },
//     revalidate: 10,
//   }
// }

export default blogPage
