import React, {useEffect, useRef} from 'react';
import PropertyWrapper from './propertyWrapper';
import 'react-image-gallery/styles/css/image-gallery.css';
import ImageGallery from 'react-image-gallery';
import useMediaQuery from '../../../utils/useMediaQuery';
import './propertySectionStyles.css';

function PropertyImageGallery({ imageGallery = [] , leftSection}) {
  const isDesktop = useMediaQuery(768);

  const images = imageGallery.map((img) => ({
    original: img.url,
    thumbnail: img.url,
  }));

  const handleImageFullScreen = (full) => {
    const galleryContent = document.querySelector('.image-gallery-content');
    if (full) {
      galleryContent.classList.add('fullscreen-gallery-content');
    } else {
      galleryContent.classList.remove('fullscreen-gallery-content');
    }
  };
  const developerRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.5) {
          leftSection(false);
          // console.log('Developer section is within 50% of the viewport');
        }
      },
      {
        root: null,
        threshold: 0.5,
      }
    );

    if (developerRef.current) {
      observer.observe(developerRef.current);
    }

    return () => {
      if (developerRef.current) {
        observer.unobserve(developerRef.current);
      }
    };
  }, [leftSection]);
  return (
    <PropertyWrapper
      id="imageGallery"
      ref={developerRef}
      children={
        <div className="aboutProjectWrapper">
          <h4 className="aboutProjectHeader">Image Gallery</h4>
          <div className="propertyGalleryContainer">
            <ImageGallery
              items={images}
              showThumbnails={true}
              thumbnailPosition={isDesktop ? 'left' : 'bottom'}
              showFullscreenButton={true}
              showPlayButton={false}
              autoPlay={true}
              showBullets={true}
              showNav={false}
              onScreenChange={(full) => handleImageFullScreen(full)}
            />
          </div>
        </div>
      }
    />
  );
}

export default PropertyImageGallery;
