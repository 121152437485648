import React, { useState, useEffect } from 'react';
import {
  cityNames,
  propertyTypeData,
  priceRangeData,
  valuetext,
} from './dropDownMenuConstants';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import './dropDownMenuComponent.css';

export const LocationDropDownContent = ({ selectedLocation }) => {
  const [selectedCity, setSelectedCity] = useState(null);

  const handledropDownMenuLocationClick = (e, city) => {
    setSelectedCity(city);
    selectedLocation(city.name);
  };

  return (
    <>
      {cityNames && Array.isArray(cityNames) && (
        <div className="dropDownMenuItems">
          <div className="dropDownMenuItemsContainer">
            {cityNames.map((city) => (
              <p
                key={city.name}
                onClick={(e) => handledropDownMenuLocationClick(e, city)}
                className={`dropDownMenuItemsText ${
                  selectedCity && selectedCity.name === city.name
                    ? 'menuItemSelected'
                    : ''
                }`}
              >
                {city.name}
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export const PropertyTypeDropDownContent = ({
  propertyTypeValue,
  selectedPropertyType,
  setSelectedPropertyType,
  checkedUnits,
  setCheckedUnits,
  isDropdownOpen,
}) => {
  const handlePropertyTypeCheckboxChange = (propertyType) => {
    setSelectedPropertyType(
      selectedPropertyType === propertyType ? null : propertyType
    );
    propertyTypeValue(
      selectedPropertyType === propertyType ? null : propertyType
    );
  };

  const handleUnitCheckboxChange = (propertyTypeName, unitName) => {
    setCheckedUnits((prevChecked) => ({
      ...prevChecked,
      [propertyTypeName]: {
        ...prevChecked[propertyTypeName],
        [unitName]: !prevChecked[propertyTypeName]?.[unitName],
      },
    }));
  };

  return (
    <div className="propertyTypeDropDownContentWrapper">
      <div className="scrollable-content">
        {propertyTypeData.map((prop) => (
          <div
            key={prop.name}
            className="propertyTypeDropDownContentFlexContainer"
          >
            <Checkbox
              checked={selectedPropertyType === prop.name}
              onChange={() => handlePropertyTypeCheckboxChange(prop.name)}
              sx={{
                color: '#ffc107',
                width: '20%',
                '& .MuiSvgIcon-root': { fontSize: '20px' },
                '&.Mui-checked': {
                  color: '#ffc107',
                },
              }}
            />
            <p
              className="propertyTypeDropDownContent"
              onClick={() => handlePropertyTypeCheckboxChange(prop.name)}
            >
              {prop.name}
            </p>
          </div>
        ))}
        <hr
          style={{
            borderTop: '1px dashed #ffc107',
            width: '100%',
            marginTop: '-5px',
          }}
        />
        {selectedPropertyType &&
          propertyTypeData
            .find((p) => p.name === selectedPropertyType)
            ?.[`${selectedPropertyType.toLowerCase()}UnitTypes`]?.map(
              (unit) => (
                <div
                  key={unit.name}
                  className="propertyTypeDropDownContentFlexContainer"
                >
                  <Checkbox
                    checked={
                      checkedUnits[selectedPropertyType]?.[unit.name] || false
                    }
                    onChange={() =>
                      handleUnitCheckboxChange(selectedPropertyType, unit.name)
                    }
                    sx={{
                      color: '#ffc107',
                      width: '20%',
                      '& .MuiSvgIcon-root': { fontSize: '20px' },
                      '&.Mui-checked': {
                        color: '#ffc107',
                      },
                    }}
                  />
                  <p
                    className="propertyTypeDropDownContent"
                    onClick={() =>
                      handleUnitCheckboxChange(selectedPropertyType, unit.name)
                    }
                  >
                    {unit.name}
                  </p>
                </div>
              )
            )}
      </div>
    </div>
  );
};

export const PriceRangeDropDownContent = ({
  priceRange,
  onChangePriceRange,
}) => {
  const handleChange = (event, newValue) => {
    if (newValue[0] > newValue[1]) {
      newValue[0] = newValue[1];
    }
    onChangePriceRange(newValue);
  };

  return (
    <div className="priceRangeDropDownContentWrapper">
      <Slider
        getAriaLabel={() => 'Indian Rupees Range'}
        value={priceRange}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        step={10000000}
        marks={priceRangeData}
        min={10000000}
        max={70000000}
      />
    </div>
  );
};
