import React, { useEffect, useState } from 'react';
import './propertyListingPageMobileFilterTabs.css';

function PropertyListingPageMobileFilterTabs({ title, category, onTabClick }) {
  const [tabClicked, setTabClicked] = useState(false);

  const handleTabClick = () => {
    setTabClicked((prev) => !prev);
    onTabClick(category, title);
  };

  useEffect(() => {
    const selectedTab = document.getElementById(`${title}`);
    if (tabClicked) {
      selectedTab.className =
        'propertyListingPageMobileFilterTabWrapper filter-tab-clicked';
    } else {
      selectedTab.className = 'propertyListingPageMobileFilterTabWrapper';
    }
  }, [tabClicked, title]);

  return (
    <div
      className="propertyListingPageMobileFilterTabWrapper"
      onClick={handleTabClick}
      id={title}
    >
      {title}
    </div>
  );
}

export default PropertyListingPageMobileFilterTabs;
