import React, { useState, useRef, useEffect } from "react";
import PropertyVideo from "../propertyPageSections/propertyVideo";
import PropertyEMICalculator from "../propertyPageSections/propertyEMICalculator";
import SuggestedLocationProperties from "../propertyPageSections/suggestedLocationProperties";
import PropertyPageFloatingContact from "../propertyPageSections/propertyPageFloatingContact";
import expressway from "../../../assets/expressway.jpeg";
import gurgaon from "../../../assets/gurgaon.jpeg";
import { useNavigate } from "react-router-dom";
import "../propertyPage.css";

function PropertyPageRightDesktop({ name, priceInFigure, leftSection }) {
  const [isContactSticky, setIsContactSticky] = useState(false);
  const contactRef = useRef(null);
  const navigate = useNavigate();

  const handleSuggestedLocationNavigation = () => {
    navigate('/property-listing/locality/Dwarka%20Expressway');
  };

  const handleSuggestedLocationNavigationCity = () => {
    navigate('/property-listing/city/Gurgaon');
  };

  useEffect(() => {
    const contactInitialTop = contactRef.current.offsetTop;

    const handleScroll = () => {
      if (window.scrollY >= contactInitialTop) {
        setIsContactSticky(true);
      } else {
        setIsContactSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if(leftSection === true){

  }

  return (
    <div className='propertyPageRightSectionContainer'>
      {/* <PropertyVideo />  nneds to be added to db */}
      <SuggestedLocationProperties
        bgdImg={expressway}
        bannerTxt='Explore other Properties on Dwarka Expressway'
        onClick={handleSuggestedLocationNavigation}
      />
      <PropertyEMICalculator initialPrincipal={priceInFigure} />
      <SuggestedLocationProperties
        bgdImg={gurgaon}
        bannerTxt='Find Out What else Gurgaon Is Offering'
        onClick={handleSuggestedLocationNavigationCity}
      />
      <div
        ref={contactRef}
        className={`floatingContactWrapper ${
          isContactSticky ? "contactSticky" : ""
        }`}
        style={{ display: leftSection ? "none" : "block" }}
      >
        <PropertyPageFloatingContact name={name} />
      </div>
    </div>
  );
}

export default PropertyPageRightDesktop;
