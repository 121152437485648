import React from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import HomeIcon from "@mui/icons-material/Home";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import DateRangeIcon from "@mui/icons-material/DateRange";
import KeyIcon from "@mui/icons-material/Key";
import "../propertyPage.css";

function PropertyHeaderOverview({
  configuration,
  area,
  squarePrice,
  status,
  posession,
}) {
  const propertyOverviewData = [
    {
      title: "Configuration",
      icon: HomeIcon,
      val: configuration,
    },
    {
      title: "Sq. Feet area",
      icon: SquareFootIcon,
      val: area,
    },
    {
      title: "Sq. Feet price",
      icon: CurrencyRupeeIcon,
      val: squarePrice,
    },
    {
      title: "Status",
      icon: DateRangeIcon,
      val: status,
    },
    {
      title: "Possession",
      icon: KeyIcon,
      val: posession,
    },
  ];

  return (
    <div className='propertyPageHeaderOverviewSection'>
      {propertyOverviewData.map((data, index) => {
        return (
          <div className='propertyPageHeaderOverviewSectionFlex' key={index}>
            <data.icon sx={{ color: "#E7B554", fontSize: "45px" }} />
            <p className='propertyPageHeaderOverviewSectionValues'>
              {data.val}
            </p>
            <p className='propertyPageHeaderOverviewSectionKeys'>
              {data.title}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default PropertyHeaderOverview;
