import { fetchListingSearch } from "../services/propertyAllServices";
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchListingSearchNames = createAsyncThunk(
    'property/getPropertyAll',
    async (propertyData, { rejectWithValue }) => {
      try {
        const property = await  fetchListingSearch(propertyData);
        // console.log("property from Slide", property);
        return property;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);


const propertyAllListing = createSlice({
    name: 'propertyListing',
    initialState: {
      property: null,
      loading: false,
      error: null,
    },
    reducers: {
        clearPropertyAll: (state) => {
        state.property = null;
        state.loading = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchListingSearchNames.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchListingSearchNames.fulfilled, (state, action) => {
          state.loading = false;
          state.property = action.payload;
        })
        .addCase(fetchListingSearchNames.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
        });
    },
  });
  
  export const { clearPropertyAll } = propertyAllListing.actions;
  
  export default propertyAllListing.reducer;
