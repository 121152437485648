import React, {useState} from "react"
import Section from "../featuredBlogs/Section"
// import Image from "next/image"
// import Link from "next/link"
import NoImage from "../featuredBlogs/noImage/NoImage"
// import Ajax from "../helper/Ajax"
import './blogsGrid.css'

function BlogsGrid({section_title, title_desc, blogs, button, initial, loadMore, blogBtn}) {

    return (
        <Section classes="featured-blogs sec-p pt-0" pageWidth="container">
            <div className="section-head text-center">
                <h2>{section_title}</h2>
                {title_desc && <p>{title_desc}</p>}
            </div>
            <div className="f-blogs-wrap">
                {blogs.map((item) => 
                    (
                    <div key={item._id} className="f-blog-item">
                        <div className="img-wrap">
                            {item.file ?
                              <picture>
                                 <img src={item.file.path} alt={item.name} layout="fill" objectFit="cover" />
                              </picture>
                            : <NoImage/> }
                        </div> 
                        <div className="info">
                            <h4>{item.name}</h4>
                            {item.link  ?  
                            <a href={item.link} target="_blank" rel="noreferrer" className="lrn-btn">
                                Read More
                                <svg
                                    width="13"
                                    height="12"
                                    viewBox="0 0 13 12"
                                    fill="none"
                                >
                                    <path
                                    d="M1.93896 6H11.9654"
                                    stroke="#E7B554"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                    <path
                                    d="M6.95215 1L11.9653 6L6.95215 11"
                                    stroke="#E7B554"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    />
                                </svg>
                            </a>
                            :
                            <a href={`/blog/${item.slug}`}>
                            <div className="lrn-btn">
                            Read More
                            <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                            >
                                <path
                                d="M1.93896 6H11.9654"
                                stroke="#E7B554"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                                <path
                                d="M6.95215 1L11.9653 6L6.95215 11"
                                stroke="#E7B554"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                />
                            </svg>
                            </div>
                            </a>
                            }
                        </div>
                    </div>
                    )
                )}
            </div>
            {button !== 'hide' && initial && initial.length === 9 && <div className="btn-wrap text-center mt-5">
                <button type="button" className="theme-btn" onClick={loadMore}>
                Load More
                <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                >
                <path
                    d="M1 6H11"
                    stroke="#E7B554"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 1L11 6L6 11"
                    stroke="#E7B554"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                </svg>
                </button>
            </div> }

            {blogBtn && 
                <div className="text-center mt-5">
                <a href='/blog'>
                <div className="theme-btn">
                View More
                <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                >
                <path
                    d="M1 6H11"
                    stroke="#E7B554"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 1L11 6L6 11"
                    stroke="#E7B554"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                </svg>
                </div>
                </a>
                </div>
            }
        </Section>
    )
}

export default BlogsGrid