export const clientData = [{
  "_id": {
    "$oid": "6689380228d2addd9f49df4f"
  },
  "name": "Manish Himthani",
  "description": "The team at INFRAMANTRA really listened to my needs and preferences. They showed me properties that matched exactly what I was looking for. They understood my vision of a perfect family home and worked tirelessly to find it. Thank you INFRAMANTRA!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/manishhimthani/manishhimthani.png",
  "createdAt": {
    "$date": "2024-07-06T12:26:42.349Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:30:31.959Z"
  }
},
{
  "_id": {
    "$oid": "6689390228d2addd9f49df56"
  },
  "name": "Amit Verma",
  "description": "Working with INFRAMANTRA was an absolute pleasure. Their expertise and dedication made the process of buying my first home a breeze. They provided valuable insights into the market and guided me through every step, ensuring I felt comfortable and informed. Thanks to their hard work, I found a home that exceeded my expectations. I can't thank INFRAMANTRA enough!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/amitverma/amitverma.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
},
{
  "_id": {
    "$oid": "669e53e4752d9a4dbd36a754"
  },
  "name": "Deepika Grover",
  "description": "The process was quite clear from the start, with every step explained in detail, making home buying hassle-free. INFRAMANTRA ensured complete transparency throughout the journey, making sure I was informed and comfortable with every decision. I couldn't have asked for a better guide in finding my dream home. Thank you, INFRAMANTRA, for making realty a reality for me!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/deepikagrover/client1.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
},
{
  "_id": {
    "$oid": "669e54be752d9a4dbd36a755"
  },
  "name": "Alka Sharma",
  "description": "Their professionalism, deep market knowledge, and exceptional service made the entire home-buying process smooth and stress-free. They listened to my needs, provided invaluable advice, and found the perfect property that exceeded my expectations. Their dedication and attention to detail were truly commendable. It was an awesome experience, and I highly recommend INFRAMANTRA to anyone looking for a trustworthy and efficient real estate consultant.",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/alkasharma/client2.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
}
]