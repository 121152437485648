import React from 'react';
import PropertyWrapper from './propertyWrapper';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReactImageMagnify from 'react-image-magnify';
import useMediaQuery from '../../../utils/useMediaQuery';
import './propertySectionStyles.css';

const floorPlanMuiStyles = {
  tabList: {
    width: '60vw',
    '& .MuiTabs-flexContainer': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .css-indfgi-MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: '#DCAA4C',
    },
    '@media (max-width: 768px)': {
      width: '85vw',
    },
  },
  tab: {
    '& .MuiButtonBase': { color: '#DCAA4C' },
    '& .MuiTab-root': { color: '#DCAA4C' },
    '& .Mui-selected': { color: '#DCAA4C' },
    '& .MuiButtonBase-root-MuiTab-root.Mui-selected': {
      color: '#DCAA4C',
    },
  },
};

function FloorPlan({ floorPlan = [] }) {
  const defaultTabValue =
    floorPlan.length > 0 ? String(floorPlan[0].configuration) : '1';
  const [value, setValue] = React.useState(defaultTabValue);
  const isDesktop = useMediaQuery(768);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <PropertyWrapper
      id="floorPlan"
      children={
        <div className="aboutProjectWrapper">
          <h4 className="aboutProjectHeader">Floor Plans And Price List</h4>
          <TabContext value={value}>
            <div className="floorPlanTabsContainer">
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                sx={floorPlanMuiStyles.tabList}
              >
                {floorPlan.map((floor) => {
                  return (
                    <Tab
                      key={floor.configuration} // Add a unique key for each Tab
                      sx={floorPlanMuiStyles.tab}
                      label={floor.configuration.replace(/\s+/g, '')}
                      value={String(floor.configuration)}
                    />
                  );
                })}
              </TabList>
            </div>
            {floorPlan.map((floor) => {
              return (
                <TabPanel
                  key={floor.configuration}
                  value={String(floor.configuration)}
                >
                  <div className="floorPlanDesignContainer">
                    {isDesktop ? (
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: 'Wristwatch by Ted Baker London',
                            isFluidWidth: true,
                            src: floor.floorImg,
                          },
                          largeImage: {
                            src: floor.floorImg,
                            width: 800,
                            height: 1000,
                          },
                        }}
                        isHintEnabled={true}
                        imageClassName="floorPlanDesignImg"
                        style={{ zIndex: '100' }}
                      />
                    ) : (
                      <img
                        className="floorPlanDesignImg"
                        src={floor.floorImg}
                        alt="bhk"
                      />
                    )}
                    <div className="floorPlanDesignValuesContainer">
                      <div className="floorPlanDesignValuesFlex">
                        <p>Price</p>
                        <p>{floor.price}</p>
                      </div>
                      {floor.superArea && (
                        <div className="floorPlanDesignValuesFlex">
                          <p>Super Area</p>
                          <p>{floor.superArea}</p>
                        </div>
                      )}
                      {floor.carpetArea && (
                        <div className="floorPlanDesignValuesFlex">
                          <p>Carpet Area</p>
                          <p>{floor.carpetArea}</p>
                        </div>
                      )}
                      <div className="floorPlanDesignValuesFlex">
                        <p>Configuration</p>
                        <p>{floor.configuration.replace(/\s+/g, '')}</p>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </TabContext>
        </div>
      }
    />
  );
}

export default FloorPlan;
