export const CityChangeDropdown = ({ handleCityChange }) => {
  const cityDataSchema = [
    { name: "Gurgaon" },
    { name: "Pune" },
    { name: "Noida" },
    { name: "Delhi" },
  ];

  return (
    <>
      {cityDataSchema.map((city) => (
        <p
          style={{ color: "#000" }}
          key={city.name}
          onClick={() => handleCityChange(city.name)}
        >
          {city.name}
        </p>
      ))}
    </>
  );
};

export const PropertyTypeDropdown = ({ handleCityChange }) => {
  const propertyTypes = [
    { name: "Residential" },
    { name: "Commercial" },
    { name: "Industrial" },
    { name: "Agricultural" },
  ];

  return (
    <>
      {propertyTypes.map((type) => (
        <p key={type.name} onClick={() => handleCityChange(type.name)}>
          {type.name}
        </p>
      ))}
    </>
  );
};

export const PriceRangeDropdown = ({ handleCityChange }) => {
  const priceRanges = [
    { name: "Less than 1 Cr." },
    { name: "1 Cr. to 2 Cr." },
    { name: "2 Cr. to 3 Cr." },
    { name: "3 Cr. to 4 Cr." },
    { name: "4 Cr. to 5 Cr." },
    { name: "5 Cr. to 6 Cr." },
    { name: "6 Cr. to 7 Cr." },
    { name: "More than 7 Cr." },
  ];

  return (
    <>
      {priceRanges.map((range) => (
        <p key={range.name} onClick={() => handleCityChange(range.name)}>
          {range.name}
        </p>
      ))}
    </>
  );
};

export const ProjectStatusDropdown = ({ handleCityChange }) => {
  const projectStatuses = [
    { name: "Planned" },
    { name: "Under Construction" },
    { name: "Completed" },
    { name: "On Hold" },
  ];

  return (
    <>
      {projectStatuses.map((status) => (
        <p key={status.name} onClick={() => handleCityChange(status.name)}>
          {status.name}
        </p>
      ))}
    </>
  );
};
