import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { SocialIcon } from 'react-social-icons';
import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneSharpIcon from '@mui/icons-material/LocalPhoneSharp';
import MailSharpIcon from '@mui/icons-material/MailSharp';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
import {
  primaryMobileNavValues,
  secondaryMobileNavValues,
  tertiaryMobileNavValues,
} from './mobileNavValues';
import { useLocation, useNavigate } from 'react-router-dom';
import './mobileNavigation.css';

const mobileMenuStyles = {
  outerBox: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    overFlowX: 'hodden',
  },
  innerBox: {
    width: '80%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'scroll',
    overFlowX: 'hodden',
  },
  horizontalDivider: {
    width: '90%',
    height: '5px',
  },
  crossIcon: {
    marginRight: '5%',
  },
};

export const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const isActive = (path) => {
    return location.pathname === `/${path}` ? '#E4A01D' : 'inherit';
  };

  const DrawerList = (
    <Box
      sx={mobileMenuStyles.outerBox}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div className="mobileMenuLogoHeaderWrapper">
        <img
          src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantraLogoBlack.png"
          alt="inframantraLogoBlack"
        />
        <CloseIcon
          onClick={toggleDrawer(false)}
          sx={mobileMenuStyles.crossIcon}
        />
      </div>
      <Box sx={mobileMenuStyles.innerBox}>
        <List sx={{ marginTop: '20%' }}>
          {primaryMobileNavValues.map((text, index) => (
            <ListItem
              onClick={() => navigate(`/${text.nav}`)}
              key={text}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{text.icon}</ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ color: isActive(text.nav) }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={mobileMenuStyles.horizontalDivider} />
        <List>
          {secondaryMobileNavValues.map((text, index) => (
            <ListItem
              onClick={() => navigate(`/${text.nav}`)}
              key={text}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{text.icon}</ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ color: isActive(text.nav) }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={mobileMenuStyles.horizontalDivider} />
        <List>
          {tertiaryMobileNavValues.map((text, index) => (
            <ListItem
              onClick={() => navigate(`/${text.nav}`)}
              key={text}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>{text.icon}</ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ color: isActive(text.nav) }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={mobileMenuStyles.horizontalDivider} />
        <p className="mobileMenuSocialMediaLinksHeader">Social Media Links</p>
        <div className="mobileMenuSocialMediaLinksWrapper">
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://x.com/INFRAMANTRA_"
          />
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://instagram.com/inframantraofficial"
          />
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://youtube.com/@inframantraofficial"
          />
        </div>
        <div className="mobileMenuSocialMediaLinksWrapper">
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://facebook.com/inframantraofficial"
          />
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://pinterest.com/inframantraofficial"
          />
          <SocialIcon
            className="mobileMenuSocialMediaLinks"
            url="https://linkedin.com/company/inframantra"
          />
        </div>
        <Divider sx={mobileMenuStyles.horizontalDivider} />
        <div className="mobileMenuCompanyDetailSection">
          <div className="mobileMenuCompanyDetailIconFlex">
            <LocalPhoneSharpIcon
              sx={{ marginRight: '20px', color: '#E4A951' }}
            />
            <p>+ 91 86 9800 9900</p>
          </div>
          <div className="mobileMenuCompanyDetailIconFlex">
            <MailSharpIcon sx={{ marginRight: '20px', color: '#E4A951' }} />
            <p>info@inframantra.com</p>
          </div>
          <div className="mobileMenuCompanyDetailIconFlex">
            <LocationOnSharpIcon
              sx={{ marginRight: '20px', color: '#E4A951' }}
            />
            <p>95, Institutional Area, Sector 32, Gurugram, Haryana 122002</p>
          </div>
        </div>
      </Box>
    </Box>
  );

  return (
    <div>
      <MenuIcon onClick={toggleDrawer(true)} />
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
