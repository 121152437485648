import axios from 'axios';
import { GET_PROPERTY_BY_NAME } from '../reduxSlices/contants.js';

export const fetchPropertyByName = async (propertyName) => {
  try {
    const response = await axios.get(`${GET_PROPERTY_BY_NAME}/${propertyName}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};
