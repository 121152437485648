import React from 'react';
import LocalityCard from '../../../components/localityCardHomePage/localityCard';
import { popularLocalities } from './popularLocalitiesData';
import './popularLocalities.css';

function PopularLocalities() {
  return (
    <div className="popularLocalitiesSectionWrapper">
      <div className="popularLocalitiesSectionFlex">
        <h2>Explore Our Popular Localities</h2>
        <div className="popularLocalitiesFlex">
          {popularLocalities.map((locality) => {
            const { localityName, localityList, localityImg } = locality;
            return (
              <LocalityCard
                localityName={localityName}
                localityList={localityList}
                localityImg={localityImg}
                key={localityName}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PopularLocalities;
