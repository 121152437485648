import axios from 'axios';
import { GET_PROPERTIES_BY_IDS } from '../reduxSlices/contants.js';
import { GET_PROPERTY_BY_SLUG } from '../reduxSlices/contants.js';

export const fetchPropertiesByIds = async (ids) => {
  try {
    const response = await axios.post(`${GET_PROPERTIES_BY_IDS}`,{ids});
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};

export const fetchedPropertiesBySlug = async(slug) => {
  try {
    const response = await axios.get(`${GET_PROPERTY_BY_SLUG}/${slug}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
}
