import React, { useEffect, useState } from 'react';
import PropertyListingCard from '../propertyListing/propertyListingCard/propertyListingCard.jsx';
import PropertyListingDesktopNav from '../propertyListing/propertyListingDesktopNav/propertyListingDesktopNav';
import PropertyListingCardMobile from '../propertyListing/propertyListingCardMobile/propertyListingCardMobile';
import useMediaQuery from '../../utils/useMediaQuery';
import { propertyData } from '../../dummyData';
import {
  getPropertyAll,
  clearPropertyAll,
} from '../../reduxSlices/propertyListingSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomBackdrop from '../../components/backdrop/backdrop';
import Wrapper from '../../components/UI/Wrapper.jsx';
import PropertyPageFloatingContact from '../propertyPage/propertyPageSections/propertyPageFloatingContact';


function PropertyListingPage() {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const { type, name } = useParams();

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedPropertyName, setSelectedPropertyName] = useState('');

  const handleClose = () => {
    setBackdropOpen(false);
    setSelectedPropertyName('');
  };

  const handleOpen = (name) => {
    setSelectedPropertyName(name);
    setBackdropOpen(true);
  };

  useEffect(() => {
    const decodedName = decodeURIComponent(name);
    if (type && decodedName) {
      dispatch(clearPropertyAll());
      dispatch(getPropertyAll({ type, name: decodedName, page: 1 }));
    }
  }, [dispatch, type, name]);

  return (
    <Wrapper>
      {isDesktop ? (
        <>
          <PropertyListingDesktopNav />
          <PropertyListingCard
            name={decodeURIComponent(name)}
            type={type}
            onOpenBackdrop={handleOpen}
          />
        </>
      ) : (
        <PropertyListingCardMobile onOpenBackdrop={handleOpen}/>
      )}
      <CustomBackdrop open={backdropOpen} onClose={handleClose}>
        <PropertyPageFloatingContact name={selectedPropertyName} />
      </CustomBackdrop>
    </Wrapper>
  );
}

export default PropertyListingPage;
