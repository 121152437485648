import React, { useEffect, useState } from 'react';
import PropertyListingCard from '../propertyListingPage/propertyListingCard/propertyListingCard';
import PropertyListingDesktopNav from '../propertyListingPage/propertyListingDesktopNav/propertyListingDesktopNav';
import PropertyListingCardMobile from '../propertyListingPage/propertyListingCardMobile/propertyListingCardMobile';
import useMediaQuery from '../../utils/useMediaQuery';
import {
  getPropertyAll,
  clearPropertyAll,
} from '../../reduxSlices/propertyListingSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomBackdrop from '../../components/backdrop/backdrop';
import PropertyPageFloatingContact from '../propertyPage/propertyPageSections/propertyPageFloatingContact';

import Wrapper from '../../components/UI/Wrapper';

function PropertyListingPage() {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  const { type, name } = useParams();

  const [backdropOpen, setBackdropOpen] = useState(false);
  const [selectedPropertyName, setSelectedPropertyName] = useState('');
  const [propertyTypeFilter, setPropertyTypeFilter] = useState(null);
  const [priceRangeFilter, setPriceRangeFilter] = useState([
    1000000, 800000000,
  ]);
  const [projectStatusFilter, setProjectStatusFilter] = useState(null);

  const handleClose = () => {
    setBackdropOpen(false);
    setSelectedPropertyName('');
  };

  const handleOpen = (name) => {
    setSelectedPropertyName(name);
    setBackdropOpen(true);
  };

  const handleFilterChange = (type, value) => {
    if (type === 'propertyType') {
      setPropertyTypeFilter(value);
    } else if (type === 'priceRange') {
      setPriceRangeFilter(value);
    } else if (type === 'projectStatus') {
      setProjectStatusFilter(value);
    }
  };

  useEffect(() => {
    const decodedName = decodeURIComponent(name);
    if (type && decodedName) {
      dispatch(clearPropertyAll());
      dispatch(getPropertyAll({ type, name: decodedName, page: 1 }));
    }
  }, [dispatch, type, name]);

  return (
    <Wrapper>
      {isDesktop ? (
        <>
          <PropertyListingDesktopNav
            currentCitySearch={name}
            onFilterChange={handleFilterChange}
            propertyTypeFilter={propertyTypeFilter}
            priceRangeFilter={priceRangeFilter}
            projectStatusFilter={projectStatusFilter}
          />
          <PropertyListingCard
            name={decodeURIComponent(name)}
            type={type}
            onOpenBackdrop={handleOpen}
            propertyTypeFilter={propertyTypeFilter}
            priceRangeFilter={priceRangeFilter}
            projectStatusFilter={projectStatusFilter}
          />
        </>
      ) : (
        <PropertyListingCardMobile />
      )}
      <CustomBackdrop open={backdropOpen} onClose={handleClose}>
        <PropertyPageFloatingContact name={selectedPropertyName} />
      </CustomBackdrop>
    </Wrapper>
  );
}

export default PropertyListingPage;
