import React from "react";
import EmiCalculator from "../../../components/emiCalculator/emiCalculator";
import PropertyWrapper from "./propertyWrapper";

function PropertyEMICalculator({ initialPrincipal }) {
  return (
    <PropertyWrapper
      id='emiCalculator'
      children={
        <div className='aboutProjectWrapper'>
          <h4 className='aboutProjectHeader'>EMI Calculator</h4>
          <EmiCalculator initialPrincipal={initialPrincipal} />
        </div>
      }
    />
  );
}

export default PropertyEMICalculator;
