import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const StyledMenu = styled(Select)({
  "& .MuiSelect-select": {
    borderRadius: "10px",
  },
  "& .MuiPaper-root": {
    borderRadius: "10px",
  },
});

export default function ListingNavSortBy() {
  const [car, setCar] = React.useState("");

  const handleChange = (event) => {
    setCar(event.target.value);
  };

  return (
    <Box sx={{ width: "8vw", borderRadius: "10px" }}>
      <FormControl fullWidth>
        <InputLabel id='car-select-label' sx={{ borderRadius: "10px" }}>
          Sort By:
        </InputLabel>
        <StyledMenu
          labelId='car-select-label'
          id='car-select'
          value={car}
          label='Select car:'
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: "10px",
                marginTop: "5px",
              },
            },
          }}
        >
          <MenuItem value={0}>Price: High To Low</MenuItem>
          <MenuItem value={1}>Price: Low To High</MenuItem>
          <MenuItem value={2}>Newest</MenuItem>
        </StyledMenu>
      </FormControl>
    </Box>
  );
}
