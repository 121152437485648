import BannerVideo from "../../../components/bannerVideo/bannerVideo";
import SearchOptions from "../../../components/searchOptions/searchOptions";
import React from "react";
import "../homePage.css";

function HeaderSection() {
  return (
    <section className='homePageheaderContainer'>
      <section className='homePageheader'>
        <BannerVideo />
        <SearchOptions />
      </section>
    </section>
  );
}

export default HeaderSection;
