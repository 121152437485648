import React from "react";
import { Paper } from "@mui/material";
import "./propertySectionStyles.css";

const propertyWrapperStyles = {
  height: "max-content",
  width: "100%",
  borderRadius: "10px",
  padding: "0px 20px",
  marginTop: "30px",
  marginBottom: "30px",
  "@media (max-width: 768px)": {
    width: "85%",
    marginLeft: "2%",
  },
};

// Use React.forwardRef to correctly handle the ref
const PropertyWrapper = React.forwardRef(({ children, id }, ref) => {
  return (
    <Paper sx={propertyWrapperStyles} elevation={8} id={id} ref={ref}>
      {children}
    </Paper>
  );
});

export default PropertyWrapper;
