import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPropertyAll, fetchListingSearch } from '../services/propertyAllServices';

export const getPropertyAll = createAsyncThunk(
  'property/getPropertyAll',
  async (propertyData, { rejectWithValue }) => {
    try {
      const property = await fetchPropertyAll(propertyData);
      return property;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const propertyAllSlice = createSlice({
  name: 'propertyAll',
  initialState: {
    property: null,
    loading: false,
    error: null,
  },
  reducers: {
      clearPropertyAll: (state) => {
      state.property = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertyAll.fulfilled, (state, action) => {
        state.loading = false;
        state.property = action.payload;
      })
      .addCase(getPropertyAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPropertyAll } = propertyAllSlice.actions;

export default propertyAllSlice.reducer;
