import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TestimonialCard from './testimonial,sCard/testimonialsCard';
import { fetchTestimonials } from '../../common/testimonials/testimonialsSlice';
import { testimoniesData } from '../../pages/testimonials/inframantra.testimonials';
import './testimonialsCardContainer.css';

const TestimonialsCardContainer = () => {
  const dispatch = useDispatch();
  const testimonials = useSelector((state) => state.testimonial.data);
  const status = useSelector((state) => state.testimonial.status);
  const error = useSelector((state) => state.testimonial.error);

  useEffect(() => {
    dispatch(fetchTestimonials());
  }, [dispatch]);

  // Check if testimonials data is null or empty
  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'failed') {
    return <div>Error: No testimonials found {error}</div>;
  }

  if (!testimonials || testimonials.length === 0) {
    return <div>No testimonials found</div>;
  }

  return (
    <div className="testimonialCardContainer">
      {testimoniesData.map((testimonial, index) => (
        <TestimonialCard
          className="testimonialCardData"
          key={index}
          name={testimonial.name}
          text={testimonial.description}
        />
      ))}
    </div>
  );
};

export default TestimonialsCardContainer;
