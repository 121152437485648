import React, {useState} from 'react';
import { SocialIcon } from 'react-social-icons';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '../../common/button/button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import useMediaQuery from '../../utils/useMediaQuery';
import mapPhoto from '../../assets/mapPhoto.png';
import './contactUsPage.css';
import { useDispatch } from 'react-redux';
import { sendContactEnquiry } from '../../reduxSlices/enquirySlice';

import Wrapper from '../../components/UI/Wrapper';

const formPaperStyles = {
  width: '40vw',
  height: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const formPaperMobileStyles = {
  width: '95vw',
  height: '100%',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

const mobileLocationPaperStyles = {
  width: '46vw',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '20px',
  borderRadius: '10px',
};

const mobileIconPaper = {
  width: '95vw',
  height: '17vh',
  marginTop: '35px',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
};

export default function ContactUsPage() {
  const isDesktop = useMediaQuery(768);
  const dispatch = useDispatch();
  // State management for form fields
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
  });
  // Handler for input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch the form data, or submit it to an API endpoint
    dispatch(sendContactEnquiry(formData)); // If you're using Redux
    setFormData('');
    // fetch('/api/contact', { method: 'POST', body: JSON.stringify(formData), headers: { 'Content-Type': 'application/json' } })
    //   .then(response => response.json())
    //   .then(data => console.log(data));
  };
  return (
    <Wrapper>
      <div className="contactUsPageWrapper">
      {isDesktop && (
        <>
          <div className="contactUsPageBannerContainer">
            <img
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/contact-us-banner%20f%20copy.avif"
              alt="infra-banner"
            />
          </div>

          <div className="contactUsPageFormSectionContainer">
            <div className="contactUsPageFormSectionGuruImgContainer">
              <img
                className="contactPageGuruImg"
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/guruContactPage.png"
                alt="Guru Randhawa Inframantra"
              />
            </div>
            <Paper elevation={8} sx={formPaperStyles}>
              <h3 className="contactUsPageSecondSectionHeader">
                " Let's Find Your{' '}
                <span style={{ color: '#E7B554' }}>Dream Home </span>Together! "
              </h3>
              <form
                className="contactUsPageSecondSectionFormWrapper"
                onSubmit={handleSubmit}
              >
                <TextField
                  name="name"
                  label="Full Name"
                  variant="standard"
                  value={formData.name}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  variant="standard"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <TextField
                  name="email"
                  label="E-mail"
                  variant="standard"
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <Button
                  btnText="Contact Now"
                  width="20vw"
                  otherStyles={{ fontSize: '20px' }}
                  type="submit"
                />
              </form>
              <p className='contactUsPageConditions'>By submitting, I accept Inframantra <a href="/page/disclaimer">Terms &amp; Conditions</a> and <a href="/page/privacy-policy">Privacy Policy</a></p>
            </Paper>
          </div>
          <div className="contactUsPageLocationSectionContainer">
            <div className="contactUsPageLocationSectionMapContainer">
              <img src={mapPhoto} alt="mapPhoto" />
            </div>
            <div className="contactUsPageLocationSectionAddressContainer">
              <div className="contactUsPageLocationSectionAddressFlex">
                <LocationOnIcon
                  sx={{
                    color: '#E4A951',
                    height: '100%',
                    fontSize: '2rem',
                  }}
                />
                <div className="contactUsPageLocationSectionAddressDataFlex">
                  <p className="contactUsPageThirdSectionLocationCity">
                    Gurgaon
                  </p>
                  <p className="contactUsPageThirdSectionLocationAddress">
                    95, Institutional Area, Sector 32, Gurugram, Haryana 122002
                  </p>
                </div>
              </div>
              <div className="contactUsPageLocationSectionAddressFlex">
                <LocationOnIcon
                  sx={{
                    color: '#E4A951',
                    height: '100%',
                    fontSize: '2rem',
                  }}
                />
                <div className="contactUsPageLocationSectionAddressDataFlex">
                  <p className="contactUsPageThirdSectionLocationCity">Noida</p>
                  <p className="contactUsPageThirdSectionLocationAddress">
                    Assotech Business Cresterra, Unit No: 416, Tower 1,
                    Sector-135, Noida, U.P., 201301
                  </p>
                </div>
              </div>
              <div className="contactUsPageLocationSectionAddressFlex">
                <LocationOnIcon
                  sx={{
                    color: '#E4A951',
                    height: '100%',
                    fontSize: '2rem',
                  }}
                />
                <div className="contactUsPageLocationSectionAddressDataFlex">
                  <p className="contactUsPageThirdSectionLocationCity">Pune</p>
                  <p className="contactUsPageThirdSectionLocationAddress">
                    Shivaji Nagar, Pune Maharastra, 411055
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="contactUsPageLastSectionDivider" />
          <div className="contactUsPageLastSectionContainer">
            <p className="contactUsPageLastSectionContainerHeader">
              Contact Us Now!
            </p>
            <p className="contactUsPageLastSectionContainerSubHeader">
              <span>
                <PhoneIcon
                  sx={{
                    color: '#E4A951',
                    marginRight: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </span>
              + 91 86 9800 9900
            </p>
            <p className="contactUsPageLastSectionContainerSubHeader">
              <span>
                <EmailIcon
                  sx={{
                    color: '#E4A951',
                    marginRight: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </span>
              info@inframantra.com
            </p>
            <p className="contactUsPageLastSectionContainerSubHeader">
              <span>
                <EmailIcon
                  sx={{
                    color: '#E4A951',
                    marginRight: '15px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </span>
              marketing@inframantra.com
            </p>
          </div>
        </>
      )}
      {!isDesktop && (
        <>
          <h3 className="contactUsPageSecondSectionHeader">
            Let's Find Your{' '}
            <span style={{ color: '#E7B554' }}>Dream Home </span>Together!
          </h3>
          <div className="contactUsPageSecondSectionContainer">
            <Paper elevation={8} sx={formPaperMobileStyles}>
            <form
                className="contactUsPageSecondSectionFormWrapper"
                onSubmit={handleSubmit}
              >
                <TextField
                  name="name"
                  label="Full Name"
                  variant="standard"
                  value={formData.name}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  variant="standard"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <TextField
                  name="email"
                  label="E-mail"
                  variant="standard"
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{ width: '80%' }}
                />
                <Button
                  btnText="Contact Now"
                  width="80vw"
                  otherStyles={{ fontSize: '20px' }}
                  type="submit"
                />
              </form>
              <p className='contactUsPageConditions'>By submitting, I accept Inframantra <a href="/page/disclaimer">Terms &amp; Conditions</a> and <a href="/page/privacy-policy">Privacy Policy</a></p>
              <img
                className="contactPageGuruImg"
                src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/guruContactPage.png"
                alt="Guru Randhawa Brand Ambassador Inframantra"
              />
            </Paper>
          </div>
          <div className="contactUsPageThirdSectionContainer">
            <div className="contactUsPageThirdSectionLocationUpper">
              <Paper elevation={8} sx={mobileLocationPaperStyles}>
                <p className="contactUsPageThirdSectionLocationCity">
                  <span>
                    <LocationOnIcon
                      sx={{
                        color: '#E4A951',
                        fontSize: isDesktop ? '2rem' : '1.4rem',
                      }}
                    />
                  </span>
                  Gurgaon
                </p>
                <p className="contactUsPageThirdSectionLocationAddress">
                  95, Institutional Area, Sector 32, Gurugram, Haryana 122002
                </p>
              </Paper>
              <Paper elevation={8} sx={mobileLocationPaperStyles}>
                <p className="contactUsPageThirdSectionLocationCity">
                  <span>
                    <LocationOnIcon
                      sx={{
                        color: '#E4A951',
                        fontSize: isDesktop ? '2rem' : '1.4rem',
                      }}
                    />
                  </span>
                  Noida
                </p>
                <p className="contactUsPageThirdSectionLocationAddress">
                  Assotech Business Cresterra, Unit No: 416, Tower 1,
                  Sector-135, Noida, U.P., 201301
                </p>
              </Paper>
            </div>
            <div
              className="contactUsPageThirdSectionLocationUpper"
              style={{ height: '15vh' }}
            >
              <Paper elevation={8} sx={mobileLocationPaperStyles}>
                <p className="contactUsPageThirdSectionLocationCity">
                  <span>
                    <LocationOnIcon
                      sx={{
                        color: '#E4A951',
                        fontSize: isDesktop ? '2rem' : '1.4rem',
                      }}
                    />
                  </span>
                  Pune
                </p>
                <p className="contactUsPageThirdSectionLocationAddress">
                  Shivaji Nagar, Pune Maharastra, 411055
                </p>
              </Paper>
              <Paper elevation={8} sx={mobileLocationPaperStyles}>
                <div className="contactUsPageThirdSectionIconFlex">
                  <PhoneIcon sx={{ color: '#E4A951' }} />
                  <p>+ 91 86 9800 9900</p>
                </div>
                <hr className="contactUsPageThirdSectionIconDivider" />
                <div className="contactUsPageThirdSectionIconFlex">
                  <EmailIcon sx={{ color: '#E4A951' }} />
                  <p>info@inframantra.com</p>
                </div>
              </Paper>
            </div>
            <Paper elevation={8} sx={mobileIconPaper}>
              <h4 style={{ fontWeight: '500', color: '#E4A951' }}>
                Follow Us For Latest Updates
              </h4>
              <div className="contactUsPageThirdSectionSocialMediaFlex">
                <SocialIcon url="https://www.facebook.com/inframantraofficial" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://x.com/INFRAMANTRA_" style={{ height: '40px', width: '40px' , backdropFilter: "#FFF"}} />
                <SocialIcon url="https://www.instagram.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://www.youtube.com/@inframantraofficial" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://in.linkedin.com/company/inframantra" style={{ height: '40px', width: '40px' }} />
                <SocialIcon url="https://in.pinterest.com/inframantraofficial/" style={{ height: '40px', width: '40px' }} />
              </div>
            </Paper>
          </div>
        </>
      )}
    </div>
    </Wrapper>
  );
}
