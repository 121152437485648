import React, { useState, useEffect, useRef } from 'react';
import { Paper } from '@mui/material';
import Button from '../../../common/button/button';
import HomeIcon from '@mui/icons-material/Home';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropertyListingPageMobileFilter from '../propertyListingPageMobileFilter/propertyListingPageMobileFilter';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import filterIcon from '../../../assets/filterIcon.svg';
import PropertyListingSearch from '../propertyListingDesktopNav/propertyListingSearch/propertyListingSearch';
import Chip from '@mui/material/Chip';
import Wishlist from '../../../common/wishlist/wishlist';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearProperty } from '../../../reduxSlices/propertySlice';
import { getPropertyAll } from '../../../reduxSlices/propertyListingSlice';
import '../propertyListingCard/propertyListingCard.css';

const propertyListCardStyles = {
  paper: {
    width: '100%',
    height: '32vh',
    marginBottom: '20px',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2% 2%',
    overflow: 'hidden',
    '@media (max-width:768px)': {
      height: '40vh',
      width: '85%',
      flexDirection: 'column',
      padding: '0px',
      position: 'relative',
    },
  },
  locationSelectPaper: {
    height: 'max-content',
    width: 'max-content',
    position: 'absolute',
    top: '15%',
    left: '30%',
    borderRadius: '12px',
    padding: '0px 20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'felx-start',
    alignItems: 'center',
    zIndex: '100',
    '@media (max-width:768px)': {
      top: '44px',
    },
  },
  selectDropdown: {
    height: '50px',
  },
  imageChip: {
    position: 'absolute',
    background: '#DCAA4C',
    width: '30%',
    borderRadius: '5px',
    left: '0%',
    top: '0%',
    color: '#fff',
    fontSize: '15px',
    fontWeight: '700',
  },
  featuredChip: {
    position: 'absolute',
    background: '#0CA92E;',
    width: '30%',
    borderRadius: '5px',
    left: '0%',
    top: '0%',
    color: '#fff',
    fontSize: '15px',
    fontWeight: '700',
  },
};

const cities = ['Gurgaon', 'Pune', 'Delhi', 'Noida'];

function PropertyListingCardMobile({onOpenBackdrop}) {
  const [currentCityDropDown, setCurrentCityDropDown] = useState(false);
  const [selectedCity, setSelectedCity] = useState('Gurgaon');
  const [fetchedProperties, setFetchedProperties] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const dropdownRef = useRef(null);
  // const projects = useSelector((state) => state.propertyAll);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleCityChange = (e) => {
    setCurrentCityDropDown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setCurrentCityDropDown(false);
    }
  };

  const SearchNames = JSON.parse(localStorage.getItem('SearchNames') || '[]');
  // console.log(SearchNames);

  // useEffect(() => {
  //   if (SearchNames) {
  //     dispatch(SearchNames);
  //   }
  // }, [dispatch]);

  const projects = useSelector((state) => state.searchAll);
  const projects1 = useSelector((state)=> state.searchData)

  // console.log("Projects1 DaTA",projects1);


  useEffect(()=>{
      const SearchLength = SearchNames.length >= 1;

      if( SearchLength){
        window.scrollTo(0, 0);
        setFetchedProperties(projects1.results.projectList);
        setTotalPages(Math.ceil(projects1.results.productCount / 10));
      }else{
        if (projects.property && projects.property.data && projects.property.data.projectList) {
          window.scrollTo(0, 0);
          setFetchedProperties(projects.property.data.projectList);
          setTotalPages(Math.ceil(projects.property.data.productCount / 10));
        }
      }
  },[])

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setFetchedProperties([]);
    dispatch(clearProperty());
    dispatch(getPropertyAll({ type: 'city', name: 'Gurgaon', page: page }));
  };

  const options = ['Low to High', 'High to Low', 'Newest'];
  const onOptionChangeHandler = (event) => {
    // console.log('User Selected Value - ', event.target.value);
  };

  const handleCityNameChange = (city) => {
    setSelectedCity(city);
    setCurrentCityDropDown((prev) => !prev);
  };

  const handleViewMorePropertyClick = (id) => {
    // console.log('entered', id);
    navigate(`/property/${id}`);
  };

  useEffect(() => {
    if (currentCityDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentCityDropDown]);

  return (
    <>
      <div className="propertyListingCardWrapper">
        <div className="propertyPageListNavWrapper">
          <PropertyListingSearch location={selectedCity} />
          <div>
            <button
              className="propertyListingFilterBtn"
              onClick={toggleDrawer('bottom', true)}
            >
              Filters <img src={filterIcon} alt="filterIcon" />
            </button>
            <SwipeableDrawer
              anchor="bottom"
              open={state.bottom}
              onClose={toggleDrawer('bottom', false)}
              onOpen={toggleDrawer('bottom', true)}
              transitionDuration={{ enter: 500, exit: 300 }}
            >
              <div
                style={{
                  height: '95vh',
                }}
                role="presentation"
                onKeyDown={toggleDrawer('bottom', false)}
              >
                <PropertyListingPageMobileFilter
                  toggleDrawer={toggleDrawer('bottom', false)}
                />
              </div>
            </SwipeableDrawer>
          </div>
        </div>

        <div className="propertyListPageSectionFlex">
          <div className="propertyListPageSectionHeaderFlex">
            <p className="propertyListPageSectionHeaderLocation">
              Properties In
              <span
                style={{
                  color: '#DCAA4C',
                  position: 'relative',
                  marginLeft: '6px',
                }}
              >
                {selectedCity}
              </span>
              <span>
                <ArrowDropDownIcon
                  onClick={handleCityChange}
                  sx={{ fontSize: '25px', paddingTop: '5px' }}
                />
              </span>
            </p>
            {currentCityDropDown && (
              <Paper
                ref={dropdownRef}
                sx={propertyListCardStyles.locationSelectPaper}
                elevation={8}
              >
                {cities.map((city) => {
                  return (
                    <p
                      style={{
                        cursor: 'pointer',
                        color: selectedCity === city ? '#DCAA4C' : '#000',
                      }}
                      key={city}
                      onClick={() => handleCityNameChange(city)}
                    >
                      {city}
                    </p>
                  );
                })}
              </Paper>
            )}
            <div className="propertyListingSelectWrapper">
              <select
                className="propertyListingSelect"
                onChange={onOptionChangeHandler}
              >
                <option>Sort By</option>
                {options.map((option, index) => {
                  return <option key={index}>{option}</option>;
                })}
              </select>
            </div>
          </div>
          {fetchedProperties.length > 0 &&
            fetchedProperties.map((property) => {
              return (
                <Paper sx={propertyListCardStyles.paper} elevation={8}>
                  <div className="propertyListPageMobileImgSection">
                    <img src={property.imageGallery[0].url} alt="featured" />
                    {property.exclusive && (
                      <Chip
                        sx={propertyListCardStyles.imageChip}
                        label="Exclusive"
                        Filled
                      />
                    )}
                    {property.featured && (
                      <Chip
                        sx={propertyListCardStyles.featuredChip}
                        label="Featured"
                        Filled
                      />
                    )}
                  </div>
                  <div className="propertyListPageMobileDetailSection">
                    <div className="propertyListPageMobileDetailHeader">
                      <div className="propertyListPageMobileDetailLocation">
                        <h3>{property.name}</h3>
                        <p style={{ color: '#0B6E21' }}>
                          {property.subLocality?.name}, {property.city?.name}
                        </p>
                      </div>
                      <div className="propertyListPageMobileDetailLocation">
                        <p>Starting Price</p>
                        <h4 style={{ color: '#DCAA4C', fontSize: '22px' }}>
                          {property.startingPrice}
                        </h4>
                      </div>
                    </div>

                    <div className="propertyListPageMobileDetailOverview">
                      <p className="propertyListPageMobileDetailOverviewValueFlex">
                        <span>
                          <HomeIcon
                            sx={{
                              color: '#0B6E21',
                              width: '20px',
                              marginRight: '6px',
                            }}
                          />
                        </span>
                        {property.configuration}
                      </p>
                      <p className="propertyListPageMobileDetailOverviewValueFlex">
                        <span>
                          <SquareFootIcon
                            sx={{
                              color: '#0B6E21',
                              width: '20px',
                              marginRight: '6px',
                            }}
                          />
                        </span>
                        {property.area.slice(0, 12)}
                      </p>
                      <p className="propertyListPageMobileDetailOverviewValueFlex">
                        <span>
                          <AccessTimeIcon
                            sx={{
                              color: '#0B6E21',
                              width: '20px',
                              marginRight: '6px',
                            }}
                          />
                        </span>
                        {property.possesion}
                      </p>
                    </div>
                    <div className="propertyListPageMobileDetailBtnWrapper">
                      <Button
                        padding="3px 25px"
                        btnText="View More"
                        onClick={() =>
                          handleViewMorePropertyClick(property.slug)
                        }
                      />
                      <Button
                        padding="3px 35px"
                        btnText="Enquire"
                        otherStyles={{ backgroundColor: '#0B6E21' }}
                        onClick={() => onOpenBackdrop(property.name)}
                      />
                      {/* <Wishlist property={property._id} /> */}
                    </div>
                  </div>
                </Paper>
              );
            })}
          <div className="propertyPageListingPaginationWrapper">
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PropertyListingCardMobile;
