import React from "react";
import PropertyHeaderImageGallery from "./propertyHeaderImageGallery";
import PropertyHeaderContact from "./propertyHeaderContact";
import PropertyHeaderDetails from "./propertyHeaderDetails";
import PropertyHeaderOverview from "./propertyHeaderOverview";

function PropertyHeader({
  imageGallery,
  rera,
  name,
  locality,
  subLocality,
  displayLocality,
  city,
  startingPrice,
  priceInFigure,
  configuration,
  area,
  squarePrice,
  status,
  posession,
  exclusive,
  featured,
}) {
  return (
    <div className='propertyPageHeaderSection' id='overview'>
      <div className='propertyPageFirstSection'>
        <PropertyHeaderImageGallery imageGallery={imageGallery} />
        <PropertyHeaderContact name={name}/>
      </div>
      <PropertyHeaderDetails
        rera={rera}
        name={name}
        locality={locality.name}
        subLocality={subLocality.name}
        displayLocality={displayLocality}
        city={city.name}
        startingPrice={startingPrice}
        priceInFigure={priceInFigure}
        exclusive={exclusive}
        featured={featured}
      />
      <PropertyHeaderOverview
        configuration={configuration}
        area={area}
        squarePrice={squarePrice}
        status={status}
        posession={posession}
      />
    </div>
  );
}

export default PropertyHeader;
