import React, { useState } from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneIcon from "@mui/icons-material/Phone";
import "../propertyPage.css";
import CustomBackdrop from "../../../components/backdrop/backdrop";
import PropertyPageFloatingContact from "../propertyPageSections/propertyPageFloatingContact";


function PropertyPageContactFooter({ name }) {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const handleClose = () => {
    setBackdropOpen(false);
    // setSelectedPropertyName('');
  };

  const handleOpen = (name) => {
    // setSelectedPropertyName(name);
    setBackdropOpen(true);
  };

  return (
    <div className='propertyPageMobileFixedContactUs'>
      <div className='propertyPageMobileFixedContactUsWhatsappBtn'>
        <WhatsAppIcon sx={{ color: "#0B6E21", marginRight: "10px" }} />
        {/* https://api.whatsapp.com/send/?phone=918698009900&text=Hi, I'm interested in BPTP Terra property. Could you please share more details with me?&type=phone_number&app_absent=0 */}

        <a href={`https://api.whatsapp.com/send/?phone=918698009900&text=Hi, I'm interested in ${name}. Could you please share more details with me?&type=phone_number&app_absent=0`}>
          <p className='propertyPageMobileFixedContactUshatsappBtnTitle'>
            Whatsapp
          </p>
        </a>
      </div>
      <div className='propertyPageMobileFixedContactUsBtn' onClick={handleOpen}>
        <PhoneIcon sx={{ color: "#fff", marginRight: "10px" }} />
        <p className='propertyPageMobileFixedContactUsBtnTitle'>Contact Us</p>
      </div>
      <CustomBackdrop open={backdropOpen} onClose={handleClose}>
        <PropertyPageFloatingContact name={name} />
      </CustomBackdrop>
    </div>
  );
}

export default PropertyPageContactFooter;
