import React from 'react';
import './clientTestimony.css';
import Rating from '@mui/material/Rating';
import FormatQuoteSharpIcon from '@mui/icons-material/FormatQuoteSharp';
import useMediaQuery from '../../utils/useMediaQuery';

const iconStyle = {
  quoteIcon: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s',
    color: '#000',
    marginRight: '10px',
    fontSize: '40px',
  },
  stars: {
    marginTop: '10px',
  },
  starsMobile: {
    color: '#ffff',
  },
  quoteIconMobile: {
    transform: 'rotate(180deg)',
    transition: 'transform 0.3s',
    color: '#000',
    fontSize: '30px',
  },
};

function ClientTestimony({ name, testimony, img }) {
  const isDesktop = useMediaQuery(768);

  return (
    <div className="clientTestimonyCardWrapper">
      {isDesktop && (
        <>
          <div className="clientTestimonyCardHeader">
            <p className="clientTestimonyName">{name}</p>
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={iconStyle.stars}
            />
          </div>
          <div className="clientTestimonyCardContainer">
            <img
              src={img}
              alt="testimonyFace"
              className="clientTestimonyCardFace"
            />
            <div className="clientTestimonyCardTextPseudoFlex"></div>
            <div className="clientTestimonyCardTextFlex">
              <FormatQuoteSharpIcon style={iconStyle.quoteIcon} />
              <p className="clientTestimonyCardText">{testimony}</p>
            </div>
          </div>
        </>
      )}
      {!isDesktop && (
        <div className="clientTestimonyCardContainer">
          <img
            src={img}
            alt="testimonyFace"
            className="clientTestimonyCardFace"
          />
          <div className="clientTestimonyCardMobileFlex">
            <Rating
              name="read-only"
              value={5}
              readOnly
              style={iconStyle.starsMobile}
            />
            <p className="clientTestimonyName">{name}</p>
            <p className="clientTestimonyCardText">
              <span>
                <FormatQuoteSharpIcon style={iconStyle.quoteIconMobile} />
              </span>
              {testimony}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientTestimony;
