import React from "react";
import PropertyWrapper from "./propertyWrapper";
import "./propertySectionStyles.css";

function ProjectHighlights({ keyHighlights = [] }) {
  return (
    <PropertyWrapper
      id='highlights'
      children={
        <div className='aboutProjectWrapper'>
          <h4 className='aboutProjectHeader'>Key Highlights</h4>
          <div className='projectHighlightList'>
            {keyHighlights.map((highlights, index) => {
              return (
                <p className='aboutProjectHighlightPoints' key={index}>
                  <span className='projectHighlightListBullet'>&gt; </span>{" "}
                  {highlights}
                </p>
              );
            })}
          </div>
        </div>
      }
    />
  );
}

export default ProjectHighlights;
