import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import TestimonialsServices from '../../services/testimmonialsService.js';


const initialState = {
    data: null,
    status: 'idle',
    error: null,
  };


  // Thunk to fetch developer data
export const fetchTestimonials = createAsyncThunk('testimonials/fetchData', async () => {
    try {
      // console.log('first call')
      const response = await TestimonialsServices.getTestimonials();
      // console.log(response)
      return response;
    } catch (error) {
      throw error;
    }
  });


  const testimonialSlice = createSlice({
    name: 'testimonials',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // Handle fetch testimonials
      .addCase(fetchTestimonials.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTestimonials.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchTestimonials.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    },
});


export default testimonialSlice.reducer;