import React from "react";
import TestimonialsBanner from "./testimonialsBanner/testimonialsBaner";
import TestimonialsCardContainer from "../../components/testimonials/testimonialCardContainer";

import './testimonials.css'
import Wrapper from "../../components/UI/Wrapper";

const TestiMonials = () => {
       return(
          <Wrapper>
            <div className="testimonialsWrapper">
            <TestimonialsBanner/>
            <TestimonialsCardContainer/>
          </div>
          </Wrapper>
       )
}

export default TestiMonials;