import React from 'react'
// import Wrapper from '../../components/UI/Wrapper'
import Section from '../blogPage/blogComponents/featuredBlogs/Section'
import { termsData } from './pageTermsDisclaimer';
import { useParams } from 'react-router-dom';
import './termsAndCondition.css'
import Wrapper from '../../components/UI/Wrapper';

const DetailPage = ({}) => {
    const { slug } = useParams();
    const TermsData = termsData.result.find(blog => blog.slug === slug);

    console.log(slug);
    console.log(TermsData);
    // console.log("Blog: ", blog);
    if (!TermsData) {
        return <div>Pages Not Found  as per Your Request.</div>;
    }

    // console.log(TermsData);

  return (
    <Wrapper>
        <Section classes="content-page-header" pageWidth="container">
            <h1>{TermsData.title}</h1>
        </Section>
        <Section classes="content-page-body" pageWidth="container">
            <div className="content" dangerouslySetInnerHTML={{ __html: TermsData.description}} >
            </div>
        </Section>
    </Wrapper>
  )
}


export default DetailPage;