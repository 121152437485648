import React, { useEffect, useRef, useState } from 'react';
import useMediaQuery from '../../utils/useMediaQuery';
// import bannerVideo from '../../assets/website_03.mp4';
// import bannerMobileVideo from '../../assets/website_mobile_01.mp4';

const BannerVideo = () => {
  const videoUrl =
    'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantra_website_drone_view_video.mp4';
  const placeholderImageUrl =
    'https://inframantra.blr1.cdn.digitaloceanspaces.com/properties/vatikasevenelements/1.avif'; // Replace with your placeholder image URL
  const guruVideo =
    'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantra_website_drone_view_video_1.2.mp4';
  const bannerVideo = 'https://inframantra.blr1.cdn.digitaloceanspaces.com/bannerVideo/website_03.mp4'
  const bannerMobileVideo =
    'https://inframantra.blr1.cdn.digitaloceanspaces.com/bannerVideo/website_mobile_02.mp4';
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const isDesktop = useMediaQuery(768);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = true;
      videoElement.autoplay = true;
      videoElement.playsInline = true;
      videoElement.oncanplay = () => setVideoLoaded(true);
      videoElement.onerror = () => setVideoError(true);
    }
  }, []);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100%',
        overflow: 'hidden',
        zIndex: -1,
      }}
    >
      {(!videoLoaded || videoError) && (
        <img
          src={placeholderImageUrl}
          alt="Placeholder"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
          }}
        />
      )}
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'fill',
          display: videoLoaded && !videoError ? 'block' : 'none',
        }}
      >
        <source src={isDesktop ? bannerVideo : bannerMobileVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default BannerVideo;
