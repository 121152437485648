import React, { Component } from 'react'
// import '../styles/developer.css'
import { BsFacebook } from "react-icons/bs";
import {BsTwitter} from 'react-icons/bs'
import {TiSocialTumbler} from 'react-icons/ti'
import { FaLinkedin } from "react-icons/fa";
import { BsWhatsapp } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BiLogoGmail } from 'react-icons/bi' 
import { GoShare } from 'react-icons/go'
import './share.css'

const Share = ({content}) => {

    // const router = useRouter();
    const url = (`https://inframantra.com`)     
    // const [awards,setAwards] = useState(allData.newAwards)
  return (
    <>
  <div className="share-buttons">
     <a href={`https://api.whatsapp.com/send?text=${url}`}>
        <div title="share on whatsapp"  className="menu-item whatsapp_share_btn" target="_blank">
           <BsWhatsapp className='facebook_share-btn icon'/>
        </div>
     </a>
     <a href={`https://www.aedin.com/shareArticle?mini=true&url=${url}`}>
        <div  className="menu-item linkedin_share_btn" target="_blank">
          <FaLinkedin className='icon'/> 
         </div>
     </a>
    <a  href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}> 
       <div  className="menu-item facebook_share_btn" target="_blank">
          <BsFacebook className='icon'/> 
       </div>
    </a>
    {/* <a href="https://twitter.com/intent/tweet?url=YOUR_BLOG_URL&text=Check%20out%20this%20awesome%20blog!" class="menu-item pinterest_share_btn" target="_blank">
      <BsInstagram className='icon'/> 
    </a> */}
    <a  href={`https://twitter.com/intent/tweet?url=${url}`}>
        <div className="menu-item twitter_share_btn" target="_blank">
          <BsTwitter className='icon'/> 
        </div>
    </a>
    <a href={`mailto:?subject=Check out this Blog Post&body=Hey! Checkout this amazing blog post thought you will like it: ${url}`}>
       <div  className="menu-item google_plus_share_btn" target="_blank">
         <BiLogoGmail className='icon'/> 
       </div>
    </a>
 </div>
</>
  )
}

export default Share;


