import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import useMediaQuery from '../../utils/useMediaQuery';
import './servicesCard.css';

const serviceCardStyles = {
  paper: {
    height: '45%',
    width: '90%',
    borderRadius: '20px',
    background: '#FFF7E9',
    marginBottom: '10%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    cursor: 'pointer',
    '@media (max-width:768px)': {
      height: '45%',
      width: '100%',
    },
  },
  icon: {
    color: 'white',
    fontSize: '200%',
    '@media (max-width:768px)': {
      fontSize: '150%',
    },
  },
};

function ServicesCard({ title, Icon, description, key, id }) {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery(768);

  function truncateDescription(description) {
    const parts = description.split('.');
    return parts[0] + '.';
  }

  return (
    <Paper
      id={id}
      key={key}
      elevation={12}
      sx={serviceCardStyles.paper}
      onClick={() => navigate(`/our-services#${title.replace(/\s+/g, '-')}`)}
    >
      <div className="serviceCardIconContainer">
        <div className="serviceCardIconWrapper">
          <img src={Icon} alt={Icon} />
        </div>
      </div>
      <div className="serviceCardTitleContainer">
        <p className="serviceCardTitle">{title}</p>
      </div>
      <div className="serviceCardDescriptionContainer">
        <p className="serviceCardDescription">
          {isDesktop ? description : truncateDescription(description)}
        </p>
      </div>
    </Paper>
  );
}

export default ServicesCard;
