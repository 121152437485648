'use server'
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import { SnackbarProvider } from "./components/UI/useMuiSnackbar";
// For Accessing Dynamic Meta SEO Friendly
import * as ReactDOMServer from 'react-dom/server';
import { HelmetProvider } from 'react-helmet-async';
import "./index.css";
import App from "./App";

// import express from "express";
// import {renderToString} from "react-dom/server";

const root = ReactDOM.createRoot(document.getElementById("root"));

persistor.subscribe(() => {
  if (persistor.getState().bootstrapped) {
  }
});
// const rootElement = document.getElementById("root");



// const container = document.getElementById('root');
// const root = hydrateRoot(container, <App />);

const helmetContext = {};


root.render(
  <HelmetProvider context={helmetContext}>
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
      <SnackbarProvider>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
        </SnackbarProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
  </HelmetProvider>
);
