export const unitTypeFilterConstants = [
  "Commercial",
  "Independent House",
  "Apartment",
  "Floors",
  "Villa",
  "Plot",
  "Row House",
  "Studio Apartment",
];

export const configuration = [
  "1BHK",
  "2BHK",
  "2BHK+S",
  "3BHK",
  "3BHK+S",
  "4BHK",
  "4BHK+S",
];

export const status = [
  "Ready To Move In",
  "Near Posession",
  "Under Construction",
  "Early Stage",
  "Well Occupied",
];
