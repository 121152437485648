import React, { useState, useEffect } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Badge from '@mui/material/Badge';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { MobileMenu } from './mobileNavigationComponents';
import WishlistDrawer from '../wishlistDrawer/wishlistDrawer';
import useMediaQuery from '../../utils/useMediaQuery';
import { useSelector } from 'react-redux';

import './navigationBar.css';

const leftSideTabs = [
  { title: 'Services', link: '/our-services' },
  { title: 'Testimonials', link: '/testimonials' },
];

const rightSideTabs = [
  { title: 'About Us', link: '/about-us' },
  { title: 'Contact Us', link: '/contact-us' },
];

function NavigationBar({ pageBgd }) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery(768);
  const navigate = useNavigate();
  const location = useLocation();

  const wishlist = useSelector((state) => state.wishlist);

  useEffect(() => {
    const currentPath = location.pathname;
    const allTabs = [...leftSideTabs, ...rightSideTabs];

    const tabIndex = allTabs.findIndex((tab) => currentPath.includes(tab.link));
    setSelectedTab(tabIndex !== -1 ? tabIndex : null);
  }, [location.pathname]);

  const handleTabClick = (link, index) => {
    setSelectedTab(index);
    navigate(link);
  };

  const logoClickHandler = () => {
    setSelectedTab(null);
    navigate('/');
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handlePropertiesClick = () => {
    navigate('/property-listing/city/Gurgaon');
  };

  const propertyTabStyle = location.pathname.includes('property')
    ? { color: '#e7b554' }
    : {};

  return (
    <div
      className="navBarWrapper"
      style={{
        height: pageBgd ? '8vh' : '10vh',
        position: !pageBgd ? 'absolute' : '',
      }}
    >
      <div className="navBarTabsWrapper">
        {isDesktop && (
          <p
            className={pageBgd ? `navBarTabs + pageBgd` : `navBarTabs`}
            onClick={handlePropertiesClick}
            id="property-listing-tab"
            style={propertyTabStyle}
          >
            Properties
          </p>
        )}
        {isDesktop ? (
          leftSideTabs.map((tab, index) => (
            <Link
              key={index}
              className={`navBarLink ${
                selectedTab === index ? 'selected' : ''
              }`}
              to={tab.link}
              onClick={() => handleTabClick(tab.link, index)}
            >
              <p className={pageBgd ? `navBarTabs + pageBgd` : `navBarTabs`}>
                {tab.title}
              </p>
            </Link>
          ))
        ) : (
          <div>
            <MobileMenu />
          </div>
        )}
      </div>
      <img
        className="navBarLogo"
        src={
          !pageBgd
            ? 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantraLogo.png'
            : 'https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/inframantraLogoBlack.png'
        }
        alt="Inframantra-logo"
        onClick={logoClickHandler}
      />
      <div className="navBarTabsWrapper">
        {isDesktop &&
          rightSideTabs.map((tab, index) => {
            const tabIndex = index + leftSideTabs.length;
            return (
              <Link
                key={tabIndex}
                className={`navBarLink ${
                  selectedTab === tabIndex ? 'selected' : ''
                }`}
                to={tab.link}
                onClick={() => handleTabClick(tab.link, tabIndex)}
              >
                <p className={pageBgd ? `navBarTabs + pageBgd` : `navBarTabs`}>
                  {tab.title}
                </p>
              </Link>
            );
          })}
        <div
          onClick={() => setDrawerOpen(false)}
          className={
            pageBgd ? `navBarWishlistIcon + pageBgd` : `navBarWishlistIcon`
          }
        >
          <Badge badgeContent={wishlist.length} color="primary">
            {wishlist.length === 0 ? (
              <FavoriteBorderIcon />
            ) : (
              <FavoriteIcon sx={{ color: '#DA0707' }} />
            )}
          </Badge>
        </div>
      </div>
      <WishlistDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
    </div>
  );
}

export default NavigationBar;
