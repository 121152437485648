export const blogType = {
    "result": [
    {
      "_id": "64cccc9ad03c2e05adab5c1a",
      "slug": "infra-times",
      "name": "Infra Times",
      "createdBy": "635a6c9ea2dc253f0444d417",
      "updatedBy": "635a6c9ea2dc253f0444d417",
      "createdAt": "2023-08-04T10:02:02.587Z",
      "updatedAt": "2023-08-04T10:02:02.587Z"
    },
    {
      "_id": "637b1beb2e436918909f97d2",
      "slug": "article",
      "name": "Article",
      "createdBy": "635a6c9ea2dc253f0444d417",
      "updatedBy": "635a6c9ea2dc253f0444d417",
      "createdAt": "2022-11-21T06:34:19.854Z",
      "updatedAt": "2022-11-21T06:34:19.854Z"
    },
    {
      "_id": "637b1be32e436918909f97ce",
      "slug": "news",
      "name": "News",
      "createdBy": "635a6c9ea2dc253f0444d417",
      "updatedBy": "635a6c9ea2dc253f0444d417",
      "createdAt": "2022-11-21T06:34:11.682Z",
      "updatedAt": "2022-11-21T06:34:11.682Z"
    },
    {
      "_id": "637b1bc32e436918909f97c6",
      "slug": "blogs",
      "name": "Blogs",
      "createdBy": "635a6c9ea2dc253f0444d417",
      "updatedBy": "635a6c9ea2dc253f0444d417",
      "createdAt": "2022-11-21T06:33:39.997Z",
      "updatedAt": "2022-11-21T06:33:39.997Z"
    }
  ]
}