import React, { useState, useEffect, useRef, useCallback } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { propertyPageNavigtionValues } from "./propertyPageNavigtionValues";
import "./propertyPageNavigation.css";

const propertyPageNavStyles = {
  tab: {
    fontSize: "18px",
    textTransform: "none",
    "&.Mui-selected": {
      color: "#ffc107",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      fontWeight: "700",
    },
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#ffc107",
    },
  },
};

function PropertyPageNavigation() {
  const [isSticky, setIsSticky] = useState(false);
  const [value, setValue] = useState(0);
  const navRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const section = document.getElementById(
      propertyPageNavigtionValues[newValue].section
    );
    const yOffset = -100;
    const yPosition =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: yPosition, behavior: "smooth" });
  };

  const handleScroll = useCallback(() => {
    const navInitialTop = navRef.current ? navRef.current.offsetTop : 0;

    // Check if the overview section is in view
    const overviewSection = document.getElementById("overview");
    const overviewInView = overviewSection
      ? window.scrollY < overviewSection.offsetTop
      : false;

    // Apply sticky class if the scroll position is past the initial position of the nav bar
    setIsSticky(window.scrollY >= navInitialTop && !overviewInView);

    const scrollPos = window.scrollY + 120; // Adjust as needed

    let activeIndex = 0;
    propertyPageNavigtionValues.forEach((val, index) => {
      const section = document.getElementById(val.section);
      if (section) {
        const offsetTop = section.offsetTop;
        if (scrollPos >= offsetTop) {
          activeIndex = index;
        }
      }
    });

    setValue(activeIndex);
  }, []);

  useEffect(() => {
    const navInitialTop = navRef.current ? navRef.current.offsetTop : 0;
    const handleScroll = () => {
      const overviewSection = document.getElementById("overview");
      const overviewInView = overviewSection
        ? window.scrollY < overviewSection.offsetTop
        : false;
      setIsSticky(window.scrollY >= navInitialTop && !overviewInView);

      const scrollPos = window.scrollY + 120; // Adjust as needed

      let activeIndex = 0;
      propertyPageNavigtionValues.forEach((val, index) => {
        const section = document.getElementById(val.section);
        if (section) {
          const offsetTop = section.offsetTop;
          if (scrollPos >= offsetTop) {
            activeIndex = index;
          }
        }
      });

      setValue(activeIndex);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      ref={navRef}
      className={`propertyPageNavigationWrapper ${isSticky ? "sticky" : ""}`}
    >
      <div className='propertyPageNavigationContainer'>
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          sx={propertyPageNavStyles.tabs}
        >
          {propertyPageNavigtionValues.map((val, index) => (
            <Tab
              key={index}
              label={val.label}
              sx={propertyPageNavStyles.tab}
              id={val.label}
            />
          ))}
        </Tabs>
      </div>
    </div>
  );
}

export default PropertyPageNavigation;
