import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import wishlistReducer from "./common/wishlist/wishlistSlice";
import testimonialReducer from "./common/testimonials/testimonialsSlice"
import propertyReducer from './reduxSlices/propertySlice';
import propertyAllReducer from './reduxSlices/propertyListingSlice';
import searchPropertyAll from './reduxSlices/searchListingSlice';
import enquiryReducer from './reduxSlices/enquirySlice';
import searchReducer from './reduxSlices/searchSlice';
import searchAllReducer from './reduxSlices/listingSearchSlice';
import searchDataReducer from './reduxSlices/searchCitySlice'
import projectSearchReducer from './reduxSlices/projectSearchSlice';

const persistConfig = {
  key: "root",
  storage,
  blacklist: ['property', 'propertyAll'],
};

const rootReducer = combineReducers({
  wishlist: wishlistReducer,
  testimonial: testimonialReducer,
  property: propertyReducer,
  propertyAll: propertyAllReducer,
  enquiries: enquiryReducer,
  searchData: searchReducer,
  searchListing: searchPropertyAll,
  searchAll: searchAllReducer,
  searchDataCity: searchDataReducer,
  projectSearchData: projectSearchReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
},);

export const persistor = persistStore(store);
export default store;
