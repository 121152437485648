import React from 'react';
import './404NotFound.css';
import useMediaQuery from '../../utils/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import Button from '../../common/button/button';

function NotFoundPage() {
  const isDesktop = useMediaQuery(768);
  const navigate = useNavigate();
  return (
    <div className="notFoundPageWrapper">
      {isDesktop && (
        <div className="notFoundPageDesktopContainer">
          <div className="notFoundPageDesktopLeftContainer">
            <div className="notFoundPageDesktopLeftFlex">
              <h2>404</h2>
              <h4>
                <span style={{ color: '#E4A951' }}>Oops! </span>Seems You've
                Taken a Wrong Turn.
              </h4>
              <p>
                But don't worry, our expert sales team is here to help you find
                your perfect home. Let us guide you back on track!
              </p>
              <Button
                btnText="Back To Home"
                width="25vw"
                padding="10px"
                otherStyles={{ fontSize: '30px', fontWeight: '800' }}
                onClick={() => navigate('/')}
              />
            </div>
          </div>
          <div className="notFoundPageDesktopRightContainer">
            <img
              src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/home-building.png"
              alt="404 not found"
            />
          </div>
        </div>
      )}
      {!isDesktop && (
        <div className="notFoundPageDesktopContainer">
          <h2>404</h2>
          <h4>
            <span style={{ color: '#E4A951' }}>Oops! </span>Seems You've Taken a
            Wrong Turn.
          </h4>
          <img
            src="https://inframantra.blr1.cdn.digitaloceanspaces.com/miscellaneous/home-building.png"
            alt="404 not found"
          />
          <p>
            But don't worry, our expert sales team is here to help you find your
            perfect home. Let us guide you back on track!
          </p>
          <Button
            btnText="Back To Home"
            width="60vw"
            padding="10px"
            otherStyles={{ fontSize: '20px', fontWeight: '800' }}
            onClick={() => navigate('/')}
          />
        </div>
      )}
    </div>
  );
}

export default NotFoundPage;
