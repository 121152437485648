export const serviceCardDataFirstSection = [
  {
    title: "Consultancy",
    icon: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/servicesPage/consultancyHome.svg',
    description:
      "Explore your dream home with our property experts and understand every details & surroundings of the project.",
  },
  {
    title: "Site Visits",
    icon: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/servicesPage/SitevisitsHome.svg',
    description:
      "Get valuable insights and personalised advice from our real estate experts who will help you out in exploring available options, and finalise your dream home.",
  },
];

export const serviceCardDataSecondSection = [
  {
    title: "Seamless Assistance",
    icon: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/servicesPage/HandholdingHome.svg',
    description:
      "From home assistance to key handovers, we ensure a seamless experience, letting you settle into your new home with ease.",
  },
  {
    title: "Resale Services",
    icon: 'https://inframantra.blr1.cdn.digitaloceanspaces.com/servicesPage/ResaleservicesHome.svg',
    description:
      "Unlock your property's value with InfraMantra's expert resale services offering top-notch marketing, seamless negotiations, and full legal support.",
  },
];
