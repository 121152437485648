import React, { useState, useEffect } from 'react';
import FeaturedPropertiesMobile from '../../../../components/featuredPropertiesMobile/featuredPropertiesMobile';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import './featuredPropertiesMobile.css';
import '../featuredPropertiesSection.css';
import { getFeaturedProperties } from '../../../../reduxSlices/propertySlice';
import { useSelector, useDispatch } from 'react-redux';

const featuredPropertiesMobileStyles = {
  arrowForward: {
    position: 'absolute',
    color: '#e7b554',
    left: '81%',
    fontSize: '70px',
    width: '70px',
  },
  arrowBack: {
    position: 'absolute',
    color: '#e7b554',
    left: '-3%',
    fontSize: '70px',
    width: '70px',
  },
  arrowBackProperty: {
    position: 'absolute',
    color: '#e7b554',
    width: '40px',
    left: '0%',
    top: '53.5%',
    height: '40px',
    zIndex: 100,
  },
  arrowForwardProperty: {
    position: 'absolute',
    color: '#e7b554',
    width: '40px',
    right: '1%',
    top: '53.5%',
    height: '40px',
    zIndex: 100,
  },
};

function FeaturedPropertiesMobileSection() {
  const [currentCityIndex, setCurrentCityIndex] = useState(0);
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [cityTransition, setCityTransition] = useState('');
  const [propertyTransition, setPropertyTransition] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFeaturedProperties());
  }, [dispatch]);

  const featuredProperties = useSelector((state) => state.property.featuredProperties);

  useEffect(() => {
    if (featuredProperties && featuredProperties.length > 0) {
      setCurrentCityIndex(0); // Set initial city index
      setCurrentPropertyIndex(0); // Set initial property index
    }
  }, [featuredProperties]);

  const handleNextCity = () => {
    setCityTransition('city-slide-exit');
    setTimeout(() => {
      setCurrentCityIndex(
        (prevCityIndex) => (prevCityIndex + 1) % featuredProperties.length
      );
      setCurrentPropertyIndex(0);
      setCityTransition('city-slide-enter');
    }, 500);
  };

  const handlePrevCity = () => {
    setCityTransition('city-slide-exit');
    setTimeout(() => {
      setCurrentCityIndex(
        (prevCityIndex) => (prevCityIndex - 1 + featuredProperties.length) % featuredProperties.length
      );
      setCurrentPropertyIndex(0);
      setCityTransition('city-slide-enter');
    }, 500);
  };

  const handleNextProperty = () => {
    setPropertyTransition('property-slide-exit');
    setTimeout(() => {
      setCurrentPropertyIndex((prevPropertyIndex) => {
        const propertiesLength = featuredProperties[currentCityIndex].properties.length;
        return (prevPropertyIndex + 2) % propertiesLength;
      });
      setPropertyTransition('property-slide-enter');
    }, 500);
  };

  const handlePrevProperty = () => {
    setPropertyTransition('property-slide-exit');
    setTimeout(() => {
      setCurrentPropertyIndex((prevPropertyIndex) => {
        const propertiesLength = featuredProperties[currentCityIndex].properties.length;
        return (prevPropertyIndex - 2 + propertiesLength) % propertiesLength;
      });
      setPropertyTransition('property-slide-enter');
    }, 500);
  };

  if (!featuredProperties || featuredProperties.length === 0) {
    return <div>Loading...</div>; // Display a loading indicator while data is being fetched
  }

  const currentCity = featuredProperties[currentCityIndex];

  return (
    <div className="featuredPropertiesMobileSectionContainer">
      <h4 className="featuredPropertiesMobileNewHeader">
        Inframantra Premium Listings
      </h4>
      <div className="featuredPropertiesMobileSectionContainerCityNavigation">
        <ArrowLeftIcon
          sx={featuredPropertiesMobileStyles.arrowBack}
          onClick={handlePrevCity}
        />
        <h2 className={cityTransition}>{currentCity.city}</h2>
        <ArrowRightIcon
          sx={featuredPropertiesMobileStyles.arrowForward}
          onClick={handleNextCity}
        />
      </div>
      <div className="featureedPropertiesMobilePaperContainer">
        <KeyboardArrowLeftIcon
          sx={featuredPropertiesMobileStyles.arrowBackProperty}
          onClick={handlePrevProperty}
        />
        <div
          className={`featureedPropertiesMobilePaperContainerPropertyWrapper ${propertyTransition}`}
        >
          {currentCity.properties
            .slice(currentPropertyIndex, currentPropertyIndex + 2)
            .map((property) => (
              <FeaturedPropertiesMobile key={property.id} property={property} />
            ))}
        </div>
        <KeyboardArrowRightIcon
          sx={featuredPropertiesMobileStyles.arrowForwardProperty}
          onClick={handleNextProperty}
        />
      </div>
    </div>
  );
}

export default FeaturedPropertiesMobileSection;
