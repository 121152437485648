import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '../../../utils/useMediaQuery';

const imageGalleryMuiStyles = {
  boxStyle: {
    width: '80%',
    height: '80vh',
    overflowY: 'scroll',
    '@media (max-width:768px)': {
      width: '94%',
      height: '80vh',
      overflowY: 'auto',
    },
  },
};

export default function ImageGallerySection() {
  const isDesktop = useMediaQuery(786);

  return (
    <>
      <Box sx={imageGalleryMuiStyles.boxStyle}>
        <ImageList variant="masonry" cols={isDesktop ? 3 : 2} gap={8}>
          {itemData.map((item, index) => (
            <ImageListItem key={item.img}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </>
  );
}

const itemData = [
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/1.avif`,
    title: 'Shweta Birthday, 2019',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/2.avif`,
    title: '2',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/3.avif`,
    title: '3',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/4.avif`,
    title: '4',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/5.avif`,
    title: '5',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/6.avif`,
    title: '6',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/7.avif`,
    title: '7',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/8.avif`,
    title: '8',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/9.avif`,
    title: '9',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/10.avif`,
    title: '10',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/11.avif`,
    title: '11',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/12.avif`,
    title: '12',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/13.avif`,
    title: '13',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/14.avif`,
    title: '14',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/15.avif`,
    title: '15',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/16.avif`,
    title: '16',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/17.avif`,
    title: '17',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/g-s%20copy.avif`,
    title: '18',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/GURU.avif`,
    title: '19',
  },
  {
    img: `https://inframantra.blr1.cdn.digitaloceanspaces.com/homePageImageGallery/SOLO-SHIWANG-SIR.avif`,
    title: '20',
  },
];
