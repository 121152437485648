import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { searchData } from '../services/searchData.service';



// export const fetchSearchData = createAsyncThunk(
//     'property/getPropertyById',
//     async ( ) => 
//       // console.log(searchTerms);
//       try {
//         const property = await searchData();
//         const propertyData = property.data;
//         return propertyData;
//       } catch (error) {
//         return error;
//       }
// );

export const fetchSearchData = createAsyncThunk('project/fetchData', async () => {
    try {
      const response = await searchData();
      return response;
    } catch (error) {
      throw error;
    }
});


const searchSliceData = createSlice({
  name: 'searchData',
  initialState: {
    results: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSearchData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSearchData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.results = action.payload;
      })
      .addCase(fetchSearchData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default searchSliceData.reducer;
