import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { searchOptionsData } from "../../../../../searchOptionsData";
import "./propertyListingSearchData.css";
import { useDispatch, useSelector } from 'react-redux';
// import { fetchListingSearchNames } from "../../../../../reduxSlices/listingSearchSlice";
import { fetchprojectData } from "../../../../../reduxSlices/projectSearchSlice";

const propertyListingSearchDataStyles = {
  paper: {
    height: "30vh",
    width: "25vw",
    position: "absolute",
    top: "125%",
    zIndex: "200",
    overflowY: "scroll",
    borderRadius: "10px",
    padding: "10px",
    "@media (max-width:768px)": {
      width: "60vw",
      top: "115%",
    },
  },
};

function PropertyListingSearchData({
  searchOptionValue,
  selectedValue,
}) {
  const dispatch = useDispatch();
  const [searchOptions, setSearchOptions] = useState([]);

  useEffect(() => {
    dispatch(fetchprojectData());
  }, [dispatch]);

  const searchData = useSelector((state) => state.projectSearchData);

  console.log("Search Data", searchOptions);

  useEffect(() => {
    // Concatenate all properties from all locations
    if(searchData && searchData.results && searchData.results){
      const allProperties = searchData.results;
      setSearchOptions(allProperties);
    }
  }, []);

  useEffect(() => {
    if (searchOptionValue) {
      setSearchOptions((prevOptions) =>
        prevOptions.filter((val) =>
          val.title.toLowerCase().includes(searchOptionValue.toLowerCase())
        )
      );
    } else {
      // Reset to all properties when search value is cleared
      const allProperties = searchData.results;
      setSearchOptions(allProperties);
    }
  }, [searchOptionValue]);

  const handleSelectSearchOption = (value) => {
    selectedValue(value);
  };

  return (
    <Paper sx={propertyListingSearchDataStyles.paper} elevation={8}>
      {searchOptions.map((option, index) => {
        return (
          <div
            className='listingSearchDataStyle'
            key={index}
            onClick={() => handleSelectSearchOption(option.title)}
          >
            <p>{option.title}</p>
            <p>{option.type}</p>
          </div>
        );
      })}
    </Paper>
  );
}

export default PropertyListingSearchData;
