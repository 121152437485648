import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '../../utils/useMediaQuery';
import {
  getPropertyByName,
  clearProperty,
} from '../../reduxSlices/propertySlice';
import './localityCard.css';

const localityCardBtnStyles = {
  color: 'white',
  borderColor: 'white',
  fontSize: '15px',
  '&:hover': {
    borderColor: 'white',
    backgroundColor: '#fff',
    color: 'black',
  },
  '@media (max-width:768px)': {
    fontSize: '10px',
  },
};

function LocalityCard({ localityName, localityList, localityImg, key }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(768);
  const [selectedLocalityProperty, setSelectedLocalityProperty] =
    useState(null);

  const fetchedProperty = useSelector((state) => state.property.property);

  const handleNavigate = (name) => {
    if (name === 'Hinjewadi' || name === 'Kharadi') {
      navigate(`/property-listing/subLocality/${name}`);
    } else {
      navigate(`/property-listing/locality/${encodeURIComponent(name)}`);
    }
  };

  const handlePropertyClick = (list) => {
    let formattedName = list.target.innerHTML.replace(/\s+/g, '-');

    setSelectedLocalityProperty(formattedName);
    dispatch(clearProperty());
  };

  useEffect(() => {
    if (selectedLocalityProperty) {
      dispatch(getPropertyByName(selectedLocalityProperty));
    }
  }, [selectedLocalityProperty, dispatch]);

  useEffect(() => {
    if (
      fetchedProperty &&
      selectedLocalityProperty !== null &&
      fetchedProperty.name.replace(/\s+/g, '-') === selectedLocalityProperty
    ) {
      navigate(`/property/${fetchedProperty.slug}`);
    }
  }, [fetchedProperty, navigate, selectedLocalityProperty]);

  return (
    <div
      className="localityCardWrapper"
      style={{ backgroundImage: `url(${localityImg})` }}
      key={localityName}
    >
      <Button
        variant="outlined"
        sx={localityCardBtnStyles}
        onClick={() => handleNavigate(localityName)}
      >
        {localityName}
      </Button>
      <ul className={isDesktop ? 'hoverList' : 'mobileNonHoverList'}>
        {localityList.map((list) => {
          return (
            <li key={list} onClick={(list) => handlePropertyClick(list)}>
              {list}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LocalityCard;
