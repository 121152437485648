import React from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import reraApproved from "../../../assets/reraApproved.png";
import Typography from "@mui/material/Typography";
import exclusivePropertyTag from "../../../assets/exclusivePropertyTag.png";
import featuredPropertyTag from "../../../assets/featuredPropertyTag.png";
import { calculateEMI } from "../../../utils/emiCalculation";
import "../propertyPage.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    width: "max-content",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    zIndex: 1,
  },
}));

const CustomIconButton = styled(IconButton)({
  "&:hover": {
    backgroundColor: "transparent",
  },
});

function PropertyHeaderDetails({
  rera,
  name,
  locality,
  subLocality,
  displayLocality,
  city,
  startingPrice,
  priceInFigure,
  exclusive,
  featured,
}) {
  const emiValue = calculateEMI(priceInFigure, 10, 30);

  return (
    <div className='propertyPageHeaderTitleSection'>
      <div className='propertyPageHeaderTitleFlexContainer'>
        <h1 className='propertyPageHeaderTitle'>{name}</h1>
        <div className='propertyPageTagsContainer'>
          <HtmlTooltip
            title={<Typography color='inherit' >{rera}</Typography>}
            placement='top-end'
          >
            <CustomIconButton>
              <img
                style={{ margin: 0, width: "9vw" }}
                src={reraApproved}
                alt='reraApproved'
              />
            </CustomIconButton>
          </HtmlTooltip>
          {exclusive && (
            <img
              style={{ margin: 0, width: "8vw" }}
              src={exclusivePropertyTag}
              alt='exclusivePropertyTag'
            />
          )}
          {featured && (
            <img
              style={{ margin: 0, width: "7.5vw" }}
              src={featuredPropertyTag}
              alt='featuredPropertyTag'
            />
          )}
        </div>
      </div>
      <h4 className='propertyPageHeaderSubTitle'>
        {subLocality}, {displayLocality && locality + ","} {city}
      </h4>
      <p className='propertyPageHeaderPriceTitle'>
        Starting at
        <span style={{ color: "#DCAA4C" }}>
          {" "}
          {/* &#8377; */}
          <span>&#8377; {startingPrice}</span>{" "}
        </span>{" "}
        | EMI @
        <span style={{ color: "#DCAA4C" }}>
          {" "}
          <span>&#8377;</span> {emiValue}/month*
        </span>
      </p>
    </div>
  );
}

export default PropertyHeaderDetails;
