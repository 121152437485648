import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSearchData } from "../../../../../reduxSlices/searchCitySlice";
import "./propertyListingSearchData.css";

const propertyListingSearchDataStyles = {
  paper: {
    height: "30vh",
    width: "25vw",
    position: "absolute",
    top: "125%",
    zIndex: "200",
    overflowY: "scroll",
    borderRadius: "10px",
    padding: "10px",
    "@media (max-width:768px)": {
      width: "60vw",
      top: "115%",
    },
  },
};

function PropertyListingSearchData({
  searchOptionValue,
  selectedValue,
  location,
}) {
  const [searchOptions, setSearchOptions] = useState([]);
  const dispatch = useDispatch();

  // Fetch the search data when the component mounts
  useEffect(() => {
    dispatch(fetchSearchData());
  }, [dispatch]);

  // Get the search data from the Redux store
  const searchData = useSelector((state) => state.searchDataCity);
  console.log("Search Data", searchData);

  // Update search options whenever searchData or location changes
  useEffect(() => {
    if (searchData && searchData.results && searchData.results.data && location) {
      const locationData = searchData.results.data[location] || [];
      setSearchOptions(locationData);
    }
  }, [searchData, location]);

  // Filter search options based on search input value
  useEffect(() => {
    if (searchOptionValue && searchOptions.length > 0) {
      const filteredOptions = searchOptions.filter((option) =>
        option.title.toLowerCase().includes(searchOptionValue.toLowerCase())
      );
      setSearchOptions(filteredOptions);
    } else if (searchData && searchData.results && searchData.results.data && location) {
      setSearchOptions(searchData.results.data[location] || []);
    }
  }, [searchOptionValue, searchData, location]);

  const handleSelectSearchOption = (value) => {
    selectedValue(value);
  };

  console.log("Search options from the Listing city Page", searchOptions, location, searchData);

  return (
    <Paper sx={propertyListingSearchDataStyles.paper} elevation={8}>
      {searchOptions && searchOptions.map((option, index) => {
        return (
          <div
            className='listingSearchDataStyle'
            key={index}
            onClick={() => handleSelectSearchOption(option.title)}
          >
            <p>{option.title}</p>
            <p>{option.type}</p>
          </div>
        );
      })}
    </Paper>
  );
}

export default PropertyListingSearchData;
