import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPropertyById } from '../services/getIndividualPropertyData.service';
import { fetchPropertyByName } from '../services/getPropertyByName.service';
import { fetchPropertiesByIds } from '../services/getWishlistPropertiesByIds.service';
import {fetchFeaturedProperties} from '../services/getFeaturedProperties.service';
import { fetchPropertyByIdPdf } from '../services/getIndividualPropertyData.service';

import { fetchedPropertiesBySlug } from '../services/getWishlistPropertiesByIds.service';


export const getPropertyById = createAsyncThunk(
  'property/getPropertyById',
  async (propertyId, { rejectWithValue }) => {
    try {
      const property = await fetchPropertyById(propertyId);
      const propertyData = property.data;
      return propertyData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getPropertyByName = createAsyncThunk(
  'property/getPropertyByName',
  async (propertyName, { rejectWithValue }) => {
    try {
      const property = await fetchPropertyByName(propertyName);
      const propertyData = property.data;
      return propertyData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPropertiesByIds = createAsyncThunk(
  'property/getPropertiesByIds',
  async (ids, { rejectWithValue }) => {
    try {
      const property = await fetchPropertiesByIds(ids);
      const propertyData = property.data;
      return propertyData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getFeaturedProperties = createAsyncThunk(
  'property/getFeaturedProperties',
  async (id, { rejectWithValue }) => {
    try {
      const property = await fetchFeaturedProperties();
      const propertyData = property.data;
      return propertyData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPropertyBySlug = createAsyncThunk('property/getPropertyBySlug',
  async(slug) =>{
    try {
      const property = await fetchedPropertiesBySlug(slug);
      return property;
    } catch (error) {
      return error;
    }
  }
);

const propertySlice = createSlice({
  name: 'property',
  initialState: {
    property: null,
    loading: false,
    error: null,
    wishListProperties :null,
    featuredProperties : null,
    slugProperty: null
  },
  reducers: {
    clearProperty: (state) => {
      state.property = null;
      state.loading = false;
      state.error = null;
    },
    clearWishList: (state) => {
      state.wishList = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertyById.fulfilled, (state, action) => {
        state.loading = false;
        state.property = action.payload;
      })
      .addCase(getPropertyById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPropertyByName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertyByName.fulfilled, (state, action) => {
        state.loading = false;
        state.property = action.payload;
      })
      .addCase(getPropertyByName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPropertiesByIds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertiesByIds.fulfilled, (state, action) => {
        state.loading = false;
        state.wishListProperties = action.payload;
      })
      .addCase(getPropertiesByIds.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFeaturedProperties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFeaturedProperties.fulfilled, (state, action) => {
        state.loading = false;
        state.featuredProperties = action.payload;
      })
      .addCase(getFeaturedProperties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPropertyBySlug.pending,(state)=>{
        state.loading = true;
        state.error = null;
      })
      .addCase(getPropertyBySlug.fulfilled,(state,action)=>{
        state.loading = false;
        state.slugProperty = action.payload;
      })
      .addCase(getPropertyBySlug.rejected,(state,action)=>{
        state.loading = false;
        state.error = action.payload
      })
  },
});

export const { clearProperty,clearWishList } = propertySlice.actions;
export default propertySlice.reducer;
