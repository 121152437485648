import axios from 'axios';
import { GET_SEARCH_DATA_CITY } from '../reduxSlices/contants';
import { GET_ALL_PROPERTY_DATA } from '../reduxSlices/contants';


export const searchData = async (names) => {
  try {
    const response = await axios.get(`${GET_SEARCH_DATA_CITY}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
};

export const  projectData = async() => {
  try {
    const response = await axios.get(`${GET_ALL_PROPERTY_DATA}`);
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || 'Error fetching property data');
  }
}
