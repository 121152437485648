import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import CreditScoreIcon from "@mui/icons-material/CreditScore";

export const homePageInsightsSection = [
  {
    title: "Properties Operated",
    icon: ApartmentIcon,
    end: "515",
    duration: "10",
  },
  {
    title: "Amazing Team Members",
    icon: GroupsIcon,
    end: "138",
    duration: "10",
  },
  {
    title: "Happy Customers",
    icon: InsertEmoticonIcon,
    end: "2493",
    duration: "10",
  },
  {
    title: "Total Sq. Ft.",
    icon: SquareFootIcon,
    end: "18153396",
    duration: "10",
  },

  {
    title: "Total Business Generated",
    icon: CurrencyRupeeIcon,
    end: "1140",
    suffix: " Crores",
    duration: "10",
  },
  {
    title: "Total Loans Provided",
    icon: CreditScoreIcon,
    end: "167",
    duration: "10",
  },
];
