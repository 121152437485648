import { useState } from 'react';

function useSearchData() {
  const [searchData, setSearchData] = useState({
    selections: []
  });

//   const updateLocation = (location) => {
//     setSearchData(prevData => ({ ...prevData, location }));
//   };

//   const updatePropertyType = (propertyType) => {
//     setSearchData(prevData => ({ ...prevData, propertyType }));
//   };

//   const updatePriceRange = (priceRange) => {
//     setSearchData(prevData => ({ ...prevData, priceRange }));
//   };

  const updateSelections = (selections) => {
    setSearchData(prevData => ({ ...prevData, selections }));
  };

  return {
    searchData,
    updateSelections
  };
}

export default useSearchData;
