import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper } from '@mui/material';
import { awardsData } from '../aboutUsData';
import './awardSlider.css';

const awardsMuiStyle = {
  paper: {
    height: '40vh',
    width: '80% !important',
    zIndex: '100',
    border: '0.5px solid #E0E0E0',
    borderRadius: '20px',
    display: 'flex !important',
    flexDirection: 'column !important',
    justifyContent: 'flex-start !important',
    alignItems: 'center !important',
    '@media (max-width:768px)': {
      height: '20vh',
      justifyContent: 'center !important',
    },
  },
};

function AwardsSlider() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
  };

  return (
    <div className="awardSectionWrapper">
      <h2>Awards And Recognitions</h2>
      <div className="awardsSliderContainer">
        <Slider {...settings}>
          {awardsData.map((award) => {
            return (
              <Paper elevation={10} sx={awardsMuiStyle.paper}>
                <img
                  src={award.img}
                  alt="Certificate of Appreciation"
                  className="awardsSliderImage"
                />
                <h3 className="awardsSliderImageHeader">{award.title}</h3>
              </Paper>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default AwardsSlider;
