import React, { useEffect, useState, useRef } from 'react';
import PropertyPageNavigation from './propertyPageNavigation/propertyPageNavigation';
import PropertyHeader from './propertyPageHeader';
import PropertyPageLeftDesktop from './porpertyPageLeftDesktop';
import PropertyPageRightDesktop from './porpertyPageRightDesktop';
import PropertyMobileHeaderImg from './propertySectionMobile/propertyMobileHeaderImg';
import PropertyPageMobileHeaderDetails from './propertySectionMobile/propertyPageMobileHeaderDetails';
import PropertyPageMobile from './propertySectionMobile';
import PropertyPageContactFooter from './propertySectionMobile/propertyPageContactFooter';
import useMediaQuery from '../../utils/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPropertyById } from '../../reduxSlices/propertySlice';
import { getPropertyBySlug } from '../../reduxSlices/propertySlice';
import { useLocation } from 'react-router-dom';

import Wrapper from '../../components/UI/Wrapper';
import Loader from '../../components/UI/customLoader';
import { clearWishList } from '../../reduxSlices/propertySlice';

import { Helmet } from 'react-helmet-async';


function PropertyPage() {
  const isDesktop = useMediaQuery(768);
  const [propertyData, setPropertyData] = useState(null);
  const [isLeftSectionBottom, setIsLeftSectionBottom] = useState(false);
  const location = useLocation();
  // const wishList = useSelector((state) => state.wishlist);


  // console.log("leftSection ", isLeftSectionBottom);
  

  const { id } = useParams();
  const { slug } = useParams();
  // console.log("slug Property Page", slug);
  const dispatch = useDispatch();
  const { property, loading, error } = useSelector((state) => state.property);

  useEffect(() => {
    if (id) {
      dispatch(getPropertyById(id))
        .unwrap()
        .then((response) => {
          setPropertyData(response);
        })
        .catch((err) => {
          console.error('Error fetching property:', err);
        });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (slug) {
      dispatch(getPropertyBySlug(slug))
        .unwrap()
        .then((response) => {
          setPropertyData(response.data);
        })
        .catch((err) => {
          console.error('Error fetching property:', err);
        });
    }
  }, [dispatch, slug]);

  if (loading) {
    return <div><Loader/></div>;
  }

  if (!propertyData) {
    return <div><Loader/></div>;
  }

//  console.log("WishList: ",wishList);
  // if(location.pathname !== '/property-listing/:type/:name' && wishList.length > 0){
  //   dispatch(clearWishList());
  // }





  return (
    <Wrapper 
        title={propertyData.metaTitle}
        description={propertyData.metaDescription}
        keyword={propertyData.metaKeywords}
        // image={propertyData.heading.image}
    >
      <div className="propertyPageWrapper">
      {isDesktop && (
        <>
          <PropertyHeader
            imageGallery={propertyData.imageGallery}
            rera={propertyData.rera}
            name={propertyData.name}
            locality={propertyData.locality}
            subLocality={propertyData.subLocality}
            displayLocality={propertyData.displayLocality}
            city={propertyData.city}
            startingPrice={propertyData.startingPrice}
            priceInFigure={propertyData.priceInFigure}
            configuration={propertyData.configuration}
            area={propertyData.area}
            squarePrice={propertyData.squarePrice}
            status={propertyData.status}
            posession={propertyData.possesion}
            exclusive={propertyData.exclusive}
            featured={propertyData.featured}
          />
          <PropertyPageNavigation />
          <div className="propertyPageSectionContainer">
              <PropertyPageLeftDesktop
                description={propertyData.description}
                propertyLogo={propertyData.propertyLogo}
                keyHighlights={propertyData.keyHighlights}
                exclusiveAmenities={propertyData.exclusiveAmenities}
                amenities={propertyData.amenities}
                floorPlan={propertyData.floorPlan}
                localityGuide={propertyData.localityGuide}
                brochure={propertyData.brochure}
                imageGallery={propertyData.imageGallery}
                developer={propertyData.developer}
                leftSection={setIsLeftSectionBottom}
              />
            <PropertyPageRightDesktop
              name={propertyData.name}
              priceInFigure={propertyData.priceInFigure}
              leftSection={isLeftSectionBottom}
            />
          </div>
        </>
      )}
      {!isDesktop && (
        <>
          <div className="propertyPageHeaderMobile">
            <PropertyMobileHeaderImg imageGallery={propertyData.imageGallery} />
            <PropertyPageMobileHeaderDetails
              rera={propertyData.rera}
              name={propertyData.name}
              locality={propertyData.locality}
              subLocality={propertyData.subLocality}
              displayLocality={propertyData.displayLocality}
              city={propertyData.city}
              startingPrice={propertyData.startingPrice}
              priceInFigure={propertyData.priceInFigure}
              configuration={propertyData.configuration}
              area={propertyData.area}
              squarePrice={propertyData.squarePrice}
              status={propertyData.status}
              posession={propertyData.possesion}
              exclusive={propertyData.exclusive}
              featured={propertyData.featured}
              tagLine={propertyData.tagLine}
            />
          </div>
          <PropertyPageContactFooter name={propertyData.name} />
          <PropertyPageNavigation />
          <PropertyPageMobile
            description={propertyData.description}
            propertyLogo={propertyData.propertyLogo}
            keyHighlights={propertyData.keyHighlights}
            exclusiveAmenities={propertyData.exclusiveAmenities}
            amenities={propertyData.amenities}
            floorPlan={propertyData.floorPlan}
            priceInFigure={propertyData.priceInFigure}
            localityGuide={propertyData.localityGuide}
            imageGallery={propertyData.imageGallery}
            developer={propertyData.developer}
            name={propertyData.name}
            brochure={propertyData.brochure}
            leftSection={setIsLeftSectionBottom}
          />
        </>
      )}
    </div>
    </Wrapper>
  );
}

export default PropertyPage;
