export const testimoniesData = [{
  "_id": {
    "$oid": "6689380228d2addd9f49df4f"
  },
  "name": "Manish Himthani",
  "description": "The team at INFRAMANTRA really listened to my needs and preferences. They showed me properties that matched exactly what I was looking for. They understood my vision of a perfect family home and worked tirelessly to find it. Thank you INFRAMANTRA!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/manishhimthani/manishhimthanhi.png",
  "createdAt": {
    "$date": "2024-07-06T12:26:42.349Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:30:31.959Z"
  }
},
{
  "_id": {
    "$oid": "6689390228d2addd9f49df56"
  },
  "name": "Amit Verma",
  "description": "Working with INFRAMANTRA was an absolute pleasure. Their expertise and dedication made the process of buying my first home a breeze. They provided valuable insights into the market and guided me through every step, ensuring I felt comfortable and informed. Thanks to their hard work, I found a home that exceeded my expectations. I can't thank INFRAMANTRA enough!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/amitverma/amitverma.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
},
{
  "_id": {
    "$oid": "668bbdb084853791c8eaff66"
  },
  "name": "Vaibhav Dwivedi",
  "description": "A family member recommended INFRAMANTRA to me and I am so glad they did. They were extremely helpful in guiding me through the process of buying my first home. They made sure that everything was taken care of promptly and correctly. I always felt like my concerns were InfraMantra's first priority. I highly recommend INFRAMANTRA to friends, family and strangers!",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:21:36.461Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:21:36.461Z"
  }
},
{
  "_id": {
    "$oid": "668bbdd384853791c8eaff68"
  },
  "name": "Dheeraj Chawla",
  "description": "I am very happy with INFRAMANTRA. They are very sharp, knowledgeable and very efficient. They have a team of experienced people and I am very happy with them and their work. They did what others couldn't do and within a short span of time. Very genuine and honest people. Keep up the good work and keep bringing a smile on someone's face like you did for me. Lots of best wishes, blessings, love, light, prosperity, and power to team INFRAMANTRA",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:22:11.648Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:22:11.648Z"
  }
},
{
  "_id": {
    "$oid": "668bbdf084853791c8eaff6a"
  },
  "name": "Aakansha Bisht",
  "description": "I recently purchased a property at SAAN Verdante through INFRAMANTRA, and the experience was seamless from start to finish. Their expertise and dedication made the entire process stress-free. I couldn't be happier with my new home and highly recommend INFRAMANTRA to anyone looking to buy real estate.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:22:40.498Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:22:40.498Z"
  }
},
{
  "_id": {
    "$oid": "668bbe0984853791c8eaff6c"
  },
  "name": "Anup Dharmani",
  "description": "At the beginning of our search for a new home, I and my wife both knew that we needed someone who could understand our needs. After having a couple of different conversations with other realtors, we were beginning to lose hope that we would find that match. Luckily we met INFRAMANTRA and things changed almost overnight. They understood what we wanted and really took into account our concerns and limits. Thanks to INFRAMANTRA we found our new home faster than we could ever have imagined.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:23:05.067Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:23:05.067Z"
  }
},
{
  "_id": {
    "$oid": "668bbe3084853791c8eaff6e"
  },
  "name": "Mayank Maheshwari",
  "description": "I was impressed with Inframantra's professionalism and the personal attention I received. They were thorough, detailed, and proactive throughout the process, making sure everything was in place for a successful transaction. It's clear they prioritize their clients' satisfaction. Thank you, Inframantra!",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:23:44.902Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:23:44.902Z"
  }
},
{
  "_id": {
    "$oid": "668bbe4684853791c8eaff70"
  },
  "name": "Sujeet Kumar Singh",
  "description": "From my first meeting, I was impressed with their professionalism and deep understanding of the real estate market. My relationship manager was incredibly patient, walking us through every step, answering all our questions, and always available whenever I needed guidance. I highly recommend INFRAMANTRA to my friends and family.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:24:06.159Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:24:06.159Z"
  }
},
{
  "_id": {
    "$oid": "668bbe7184853791c8eaff72"
  },
  "name": "Monika Verma",
  "description": "If you're a little skeptical about who to trust to help you buy your house or you feel you can't trust anyone. Well, try INFRAMANTRA. You'll be very satisfied with the service they provide. They are not only professional and courteous but also responsible and full of knowledge. I highly recommend INFRAMANTRA to my friends and family.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:24:49.292Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:24:49.292Z"
  }
},
{
  "_id": {
    "$oid": "668bbe8784853791c8eaff74"
  },
  "name": "Susil Kumar Subudhi",
  "description": "I have had several bad real estate experiences in India, people do not mean what they say. Customer service with a real estate agent always takes a back seat. But with INFRAMANTRA, I had a different experience. The most appreciated trait I have seen with INFRAMANTRA is that they are clear in their communication; they mean what they say. They responded promptly to my questions and concerns. If I need another property in Gurgaon or if there are any requirements for my friends, I will put them in contact with INFRAMANTRA for sure.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:25:11.521Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:25:11.521Z"
  }
},
{
  "_id": {
    "$oid": "668bbe9d84853791c8eaff76"
  },
  "name": "Ashok Arora",
  "description": "I recently purchased a home through INFRAMANTRA, and I couldn't be happier with my decision. The entire process was smooth and hassle-free. The team was incredibly professional and attentive to my needs. They understood my preferences and helped me find the perfect property that matched my requirements. The quality of the construction and the amenities offered exceeded my expectations. I feel confident in my investment, knowing that I have a reliable and trustworthy real estate partner. I highly recommend INFRAMANTRA to anyone looking for a seamless and enjoyable home-buying experience.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:25:33.668Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:25:33.668Z"
  }
},
{
  "_id": {
    "$oid": "668bbeb584853791c8eaff78"
  },
  "name": "Ratinjay Mishra",
  "description": "Buying a home through INFRAMANTRA has been an absolute delight. They made the entire process smooth and hassle-free. The team's professionalism and dedication exceeded my expectations. My dream home at Prateek Canary, Noida, is everything I ever wanted. Thank you, INFRAMANTRA, for helping me find my perfect abode. Highly recommended!",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:25:57.899Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:25:57.899Z"
  }
},
{
  "_id": {
    "$oid": "668bbeda84853791c8eaff7a"
  },
  "name": "Kushagra Gautam",
  "description": "INFRAMANTRA truly exceeded my expectations as a real estate company. Their professionalism, expertise, and commitment to customer satisfac- tion were unmatched. From the moment I engaged with them, they guided me through every step of the property buying process, providing valuable insights and personalized recommendations. Their attention to detail and prompt communication made the entire experience seamless and stress-free. I highly recommend Inframantra to anyone in search of a reliable and trustworthy real estate partner.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:26:34.217Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:26:34.217Z"
  }
},
{
  "_id": {
    "$oid": "668bbefa84853791c8eaff7c"
  },
  "name": "Latika Singh",
  "description": "I recently purchased a home through INFRAMANTRA, and I couldn't be happier with my decision. The entire process was smooth and hassle-free. The team was incredibly professional and attentive to my needs. They understood my preferences and helped me find the perfect property that matched my requirements. The quality of the construction and the amenities offered exceeded my expectations. I feel confident in my investment, knowing that I have a reliable and trustworthy real estate partner. I highly recommend INFRAMANTRA to anyone looking for a seamless and enjoyable home-buying experience.",
  "image": "",
  "createdAt": {
    "$date": "2024-07-08T10:27:06.004Z"
  },
  "updatedAt": {
    "$date": "2024-07-08T10:27:06.004Z"
  }
},
{
  "_id": {
    "$oid": "669e53e4752d9a4dbd36a754"
  },
  "name": "Deepika Grover",
  "description": "The process was quite clear from the start, with every step explained in detail, making home buying hassle-free. INFRAMANTRA ensured complete transparency throughout the journey, making sure I was informed and comfortable with every decision. I couldn't have asked for a better guide in finding my dream home. Thank you, INFRAMANTRA, for making realty a reality for me!",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/deepikagrover/client1.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
},
{
  "_id": {
    "$oid": "669e54be752d9a4dbd36a755"
  },
  "name": "Alka Sharma",
  "description": "Their professionalism, deep market knowledge, and exceptional service made the entire home-buying process smooth and stress-free. They listened to my needs, provided invaluable advice, and found the perfect property that exceeded my expectations. Their dedication and attention to detail were truly commendable. It was an awesome experience, and I highly recommend INFRAMANTRA to anyone looking for a trustworthy and efficient real estate consultant.",
  "image": "https://inframantra.blr1.cdn.digitaloceanspaces.com/testimonials/alkasharma/client2.png",
  "createdAt": {
    "$date": "2024-07-06T12:30:58.918Z"
  },
  "updatedAt": {
    "$date": "2024-07-06T12:35:01.761Z"
  }
}]