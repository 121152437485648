import React from 'react';
import Button from '../../../common/button/button';
import useMediaQuery from '../../../utils/useMediaQuery';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './propertySectionStyles.css';

const suggestedLocationPropertiesStyles = {
  paper: {
    width: '32.5vw',
    borderRadius: '10px',
    height: '50vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '500px 500px',
    marginTop: '30px',
    '@media (max-width: 768px)': {
      width: '95%',
      marginLeft: '2%',
    },
  },
};

function SuggestedLocationProperties({ bgdImg, bannerTxt, onClick }) {
  const isDesktop = useMediaQuery(768);

  return (
    <Paper
      sx={suggestedLocationPropertiesStyles.paper}
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),
                       rgba(0, 0, 0, 0.3)),url(${bgdImg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
      elevation={8}
    >
      <div className="suggestedLocationPropertiesTextWrapper">
        <h4>{bannerTxt}</h4>
        <Button
          btnText="Explore"
          width={isDesktop ? '20vw' : '60vw'}
          fontWeight="900"
          padding="5px 2px"
          onClick={onClick}
        />
      </div>
    </Paper>
  );
}

export default SuggestedLocationProperties;
