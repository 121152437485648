import React from 'react';
import './aboutUsPage.css';

function CoreValues() {
  return (
    <div className="coreValuesWrapper">
      <h2>Core Values</h2>
      <div className="coreValuesContentGrid">
        <div class="coreValuesContentGridItem">
          <h2>01</h2>
          <h4>Process Oriented</h4>
          <p>
            At Inframantra, we uphold the highest standards of integrity and
            transparency in all our dealings. We believe that honesty and
            openness are the cornerstones of a trustworthy relationship. Our
            clients can rely on us to provide clear, accurate information and to
            maintain complete transparency throughout the entire real estate
            process.
          </p>
        </div>
        <div class="coreValuesContentGridItem">
          <h2 style={{ color: 'black' }}>02</h2>
          <h4>Client Centric</h4>
          <p>
            Our Clients come first. We prioritize their needs and dreams,
            ensuring personalized real estate experiences that exceed
            expectations. Their satisfaction is our ultimate goal, making realty
            a reality for them.
          </p>
        </div>
        <div class="coreValuesContentGridItem">
          <h2 style={{ color: 'black' }}>03</h2>
          <h4>Respect for People</h4>
          <p>
            Respect for people is our core value. We treat everyone with
            dignity, listen to our clients, value their opinions, and prioritize
            their needs, fostering trust and long lasting relationships.
          </p>
        </div>
        <div class="coreValuesContentGridItem">
          <h2>04</h2>
          <h4>Integrity, Simplicity and Transparency</h4>
          <p>
            At InfraMantra, we value honesty, simplicity, and transparency. We
            build trust by being truthful, make real estate easy to understand,
            and ensure clear communication with no hidden costs.te transparency
            throughout the entire real estate process.
          </p>
        </div>
      </div>
    </div>
  );
}

export default CoreValues;
