import React from 'react';
import ServicesCard from '../../../components/servicesCard/servicesCard';
import {
  serviceCardDataFirstSection,
  serviceCardDataSecondSection,
} from './serviceSectionData';
import { useNavigate } from 'react-router-dom';
import './servicesSection.css';

function ServicesSection() {
  const navigate = useNavigate();
  return (
    <div className="servicesSectionWrapper">
      <div className="servicesSectionCardWrapperFirst">
        {serviceCardDataFirstSection.map((service, index) => {
          return (
            <ServicesCard
              title={service.title}
              Icon={service.icon}
              description={service.description}
              key={index}
              id={service.title.replace(/\s+/g, '-')}
            />
          );
        })}
      </div>
      <div className="servicesSectionCardWrapperSecond">
        {serviceCardDataSecondSection.map((service, index) => {
          return (
            <ServicesCard
              title={service.title}
              Icon={service.icon}
              description={service.description}
              key={index}
              id={service.title.replace(/\s+/g, '-')}
            />
          );
        })}
      </div>
      <div className="servicesSectionHeader">
        <p
          className="servicesSectionTitle"
          onClick={() => navigate('/services')}
        >
          Our Services
        </p>
        <hr className="servicesSectionTitleUnderline" />
      </div>
    </div>
  );
}

export default ServicesSection;
